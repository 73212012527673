export const complianceIdArray = [
  {
    id: 1650,
    affiliate_id: null,
    institution_name: "DEMO ACCOUNT",
    slug: "demo-account-d61dd",
    institution_address: "GURGAON",
    bio: "gfhjj",
    logo: "https://s3.ap-south-1.amazonaws.com/safeinschool.in/institute/profile/61ddbg_2.jpg",
    title: null,
    first_name: "ARNAB",
    middle_name: null,
    last_name: "BANERJEE",
    state: "Haryana",
    pincode: 122001,
    email: "arnab6294928709@gmail.com",
    contact: 7048928788,
    status: "active",
    statuskey: null,
    password: "$2a$10$kKF0yXkH9kthD5Td4U2kWeRemtTgXRKcr.iMHe8ZFj6a37P6AzLoi",
    fb: "",
    insta: "",
    lkd: "",
    twitter: "",
    ytb: "",
    isAssigned: "false",
    isPlanned: "false",
    createdAt: "2024-08-16T05:47:33.000Z",
    updatedAt: "2024-08-16T05:47:33.000Z",
    district: "Gurgaon",
    login_method: "jwt",
    instituteId: 4984,
    question1: "567",
    question2: "56",
    question3: "657",
    question4: "school",
    question5: "45678",
    question6: "STATE (Chandigarh)",
    question7: "Gujrati",
    question8: "",
    question9: "",
  },
];
