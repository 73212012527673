import React, { useState } from "react";

const RainwaterHarvestingQuestion = () => {
  const questionData = {
    id: 44,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    text: "What system does the school use to harvest rainwater?",
    options: [
      {
        text: "Direct storage",
        score: "1",
        correct: false,
      },
      {
        text: "Rain-water harvesting tanks",
        score: "2",
        correct: false,
      },
      {
        text: "Direct storage to raw water storage tanks",
        score: "3",
        correct: true,
      },
      {
        text: "Recharge into the ground aquifer",
        score: "4",
        correct: false,
      },
    ],
  };

  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  // CSS Styles
  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      fontSize: "14px",
      lineHeight: "1.6",
      margin: "20px 40px",
      color: "#333",
    },
    heading: {
      fontSize: "16px",
      marginBottom: "16px",
      fontWeight: "bold",
    },
    inputGroup: {
      display: "flex", // Align options horizontally
      flexWrap: "wrap", // Allow wrapping if there are too many options
      gap: "20px", // Space between options
      marginTop: "10px",
      marginLeft: "60px", // Consistent alignment
    },
    label: {
      display: "flex",
      alignItems: "center",
      fontSize: "14px",
      cursor: "pointer",
    },
    input: {
      marginRight: "10px",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Q14. {questionData.text}</h1>
      <div style={styles.inputGroup}>
        {questionData.options.map((option, index) => (
          <label key={index} style={styles.label}>
            <input
              type="radio"
              name="rainwaterSystem"
              value={option.text}
              checked={selectedOption === option.text}
              onChange={() => handleOptionChange(option.text)}
              style={styles.input}
            />
            {option.text}
          </label>
        ))}
      </div>
    </div>
  );
};

export default RainwaterHarvestingQuestion;
