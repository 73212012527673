import React, { useState } from "react";

const SchoolStrengthForm = () => {
  const [formData, setFormData] = useState({
    students: "",
    teachers: "",
    administrativeStaff: "",
    supportStaff: "",
  });

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  // CSS Styles (adjusted according to TransportModes styles)
  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    lineHeight: "1.4", // Reduced line gap
    margin: "20px 40px",
    color: "#333",
  };

  const sectionStyle = {
    display: "flex", // Flex layout for aligning labels and inputs
    alignItems: "center", // Vertically align items
    marginBottom: "12px",
  };

  const inputStyle = {
    fontSize: "14px",
    padding: "6px", // Reduced padding for tighter inputs
    marginLeft: "10px", // Space between label and input
    width: "200px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  };

  const questionTitleStyle = {
    fontSize: "16px",
    marginBottom: "12px",
    fontWeight: "bold",
  };

  const labelStyle = {
    fontSize: "14px",
    width: "220px", // Set a fixed width for label to align inputs
    textAlign: "left",
    marginLeft: "60px", // Added margin left for alignment
  };

  return (
    <form style={containerStyle}>
      <h1 style={questionTitleStyle}>Q1. What is the total strength of the school?</h1>

      <div style={sectionStyle}>
        <label style={labelStyle}>Number of students:</label>
        <input
          type="number"
          value={formData.students}
          onChange={(e) => handleInputChange("students", e.target.value)}
          style={inputStyle}
        />
      </div>

      <div style={sectionStyle}>
        <label style={labelStyle}>Number of teachers:</label>
        <input
          type="number"
          value={formData.teachers}
          onChange={(e) => handleInputChange("teachers", e.target.value)}
          style={inputStyle}
        />
      </div>

      <div style={sectionStyle}>
        <label style={labelStyle}>Administrative staff:</label>
        <input
          type="number"
          value={formData.administrativeStaff}
          onChange={(e) => handleInputChange("administrativeStaff", e.target.value)}
          style={inputStyle}
        />
      </div>

      <div style={sectionStyle}>
        <label style={labelStyle}>Support staff (in the Labs, Library, Sports, Security, Maintenance, etc.):</label>
        <input
          type="number"
          value={formData.supportStaff}
          onChange={(e) => handleInputChange("supportStaff", e.target.value)}
          style={inputStyle}
        />
      </div>
    </form>
  );
};

export default SchoolStrengthForm;
