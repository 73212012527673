import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import "./EnrolmentForm.css";
import * as Yup from "yup";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  FormGroup,
  Checkbox,
  Typography,
} from "@mui/material";

const EnrolmentForm = ({ FormVisibleDisable }) => {
  const [initialValues, setInitialValues] = useState({
    NameOfTheSchool: "Delhi Public School",
    AddressOfTheSchool: "Block F, East of Kailash, New Delhi, Delhi 110065",
    typeOfSchoolCategories: "",
    typeOfSchool: "",
    nameOfThePrincipal: "",
    Name: "",
    Designation: "",
    Mobile: "",
    Email: "",
    schoolAffiliationCode: "2730303",
    PrimaryStudent: "",
    MiddleStudent: "",
    SeniorStudent: "",
    FoodBusiness: "",
    provided: "",
  });

  const nodalAsPrinciple = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setInitialValues((prevValues) => ({
        ...prevValues,
        Name: initialValues.nameOfThePrincipal,
        Designation: "Principal",
      }));
    } else {
      setInitialValues((prevValues) => ({
        ...prevValues,
        Name: "",
        Designation: "",
      }));
    }
  };

  const formik = useFormik({
    enableReinitialize: true, // Important for dynamic initial values
    initialValues,
    validationSchema: Yup.object({
      nameOfThePrincipal: Yup.string()
        .min(1, "Must be 1 character or more")
        .required("Name of the principle is required"),
      Name: Yup.string()
        .min(1, "Must be 1 character or more")
        .required("Name of the nodal officer is required"),
      typeOfSchoolCategories: Yup.string()
        .min(1, "Must be 1 character or more")
        .required("*Required"),
      Designation: Yup.string()
        .min(1, "Must be 1 character or more")
        .required("*Required"),
      Mobile: Yup.string()
        .min(1, "Must be 1 character or more")
        .required("*Required"),
      Email: Yup.string()
        .min(1, "Must be 1 character or more")
        .required("*Required"),

      FoodBusiness: Yup.string()
        .min(1, "Must be 1 character or more")
        .required("*Required"),

      // provided: Yup.string()
      //   .min(1, "Must be 1 character or more")
      //   .required("*Required"),
    }),
    validateOnChange: false, // Turn off validation on change
    validateOnBlur: true,
    onSubmit: (values) => {
      console.log("CLicked here");
      localStorage.setItem("formDetails", JSON.stringify(values));
      localStorage.setItem("formSubmitted", "true");
      FormVisibleDisable();
    },
  });

  // useEffect(() => {
  //   // Simulate fetching data and updating the initial values
  //   const fetchSchoolDetails = () => {
  //     const savedDetails = JSON.parse(localStorage.getItem("user")) || {};
  //     setInitialValues((prevValues) => ({
  //       ...prevValues,
  //       ...savedDetails, // Merge saved details into the initial values
  //     }));
  //   };

  //   fetchSchoolDetails();
  // }, []);

  const { handleChange, handleSubmit, values, errors, touched } = formik;

  return (
    <Box
      p={4}
      sx={{
        maxWidth: 600,
        margin: "1rem  auto",
        border: {
          xs: "3px solid #007bff",
          sm: "3px solid #007bff",
          md: "3px solid #007bff",
          lg: "5px solid #007bff",
          xl: "5px solid #007bff",
        },
        borderRadius: "1rem",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Enrolment Form
      </Typography>
      <Typography variant="h6" gutterBottom>
        General Information
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mb={1}>
          <div style={{ marginBottom: "1rem" }} className="inputBox">
            <label htmlFor="NameOfTheSchool" className="inputLabel">
              Name of the School :
            </label>
            <input
              type="text"
              id="NameOfTheSchool"
              name="NameOfTheSchool"
              className="inputField"
              onChange={handleChange}
              value={values.NameOfTheSchool}
            />
            {errors.NameOfTheSchool ? (
              <Typography
                variant="body2"
                color="error"
                sx={{ mt: 0.4, fontWeight: "bold" }}
              >
                {errors.NameOfTheSchool}
              </Typography>
            ) : null}
          </div>
          <div style={{ marginBottom: "1rem" }} className="inputBox">
            <label htmlFor="AddressOfTheSchool" className="inputLabel">
              Address of School :
            </label>
            <input
              type="text"
              id="AddressOfTheSchool"
              name="AddressOfTheSchool"
              className="inputField"
              onChange={handleChange}
              value={values.AddressOfTheSchool}
            />
            {errors.AddressOfTheSchool ? (
              <Typography
                variant="body2"
                color="error"
                sx={{ mt: 0.4, fontWeight: "bold" }}
              >
                {errors.AddressOfTheSchool}
              </Typography>
            ) : null}
          </div>
        </Box>

        <FormControl component="fieldset" sx={{ mb: 3 }}>
          <FormLabel component="legend">Type of School -</FormLabel>
          <RadioGroup
            row
            name="typeOfSchoolCategories"
            onChange={(e) => {
              handleChange(e);
              setInitialValues((prevValues) => ({
                ...prevValues,
                typeOfSchoolCategories: e.target.value,
              }));
            }}
            value={values.typeOfSchoolCategories}
          >
            {["Government", "Private", "Govt-Funded"].map((option) => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={option}
              />
            ))}
            {errors.typeOfSchoolCategories ? (
              <Typography
                variant="body2"
                color="error"
                sx={{ mt: 0.4, fontWeight: "bold" }}
              >
                {errors.typeOfSchoolCategories}
              </Typography>
            ) : null}
          </RadioGroup>
        </FormControl>

        <Box mb={3}>
          <div className="inputBox">
            <label htmlFor="nameOfThePrincipal" className="inputLabel">
              Name of the Principal :
            </label>
            <input
              type="text"
              id="nameOfThePrincipal"
              name="nameOfThePrincipal"
              className="inputField"
              onChange={(e) => {
                handleChange(e);
                setInitialValues((prevValues) => ({
                  ...prevValues,
                  nameOfThePrincipal: e.target.value,
                }));
              }}
              value={values.nameOfThePrincipal}
            />
          </div>

          {errors.nameOfThePrincipal ? (
            <Typography
              variant="body2"
              color="error"
              sx={{ mt: 0.4, fontWeight: "bold" }}
            >
              {errors.nameOfThePrincipal}
            </Typography>
          ) : null}
        </Box>

        <Box mb={3}>
          <div>
            <p className="inputLabel">Nodal Contact Details : </p>

            <div>
              {/* nodal upper box */}
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                }}
              >
                {/* function to fill up the space with name of the principal to the nodal contact details */}

                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox onChange={(e) => nodalAsPrinciple(e)} />}
                    label="Same As The Principal"
                  />
                </FormGroup>
                {/* Nodal Contact Details Name */}
                <div className="inputBox nodalInputBox">
                  <label htmlFor="Name" className="inputLabel">
                    Name :
                  </label>
                  <input
                    type="text"
                    id="Name"
                    name="Name"
                    className="inputField"
                    onChange={handleChange}
                    value={values.Name}
                  />
                </div>
                {errors.Name ? (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ mt: 0.4, fontWeight: "bold" }}
                  >
                    {errors.Name}
                  </Typography>
                ) : null}

                {/* Nodal Contact Details Designation */}
                <FormControl
                  component="fieldset"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    width: "90%",
                    alignItems: "center",
                  }}
                >
                  <FormLabel
                    sx={{
                      width: "30%",
                      color: "black",
                    }}
                    component="legend"
                  >
                    Designation :
                  </FormLabel>
                  <RadioGroup
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                    row
                    name="Designation"
                    onChange={handleChange}
                    value={values.Designation}
                  >
                    {["Principal", "Admin", "Teacher"].map((option) => (
                      <FormControlLabel
                        key={option}
                        value={option}
                        control={<Radio />}
                        label={option}
                      />
                    ))}
                    {errors.typeOfSchoolCategories ? (
                      <Typography
                        variant="body2"
                        color="error"
                        sx={{ mt: 0.4, fontWeight: "bold" }}
                      >
                        {errors.typeOfSchoolCategories}
                      </Typography>
                    ) : null}
                  </RadioGroup>
                </FormControl>

                {errors.Designation ? (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ mt: 0.4, fontWeight: "bold" }}
                  >
                    {errors.Designation}
                  </Typography>
                ) : null}
              </div>

              {/* nodal lower box */}
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                }}
                className="NodalContactBox"
              >
                {/* Nodal Contact Details Mobile */}
                <div className="inputBox nodalInputBox">
                  <label htmlFor="Mobile" className="inputLabel">
                    Mobile :
                  </label>
                  <input
                    type="text"
                    id="Mobile"
                    name="Mobile"
                    className="inputField"
                    onChange={handleChange}
                    value={values.Mobile}
                  />
                </div>
                {errors.Mobile ? (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ mt: 0.4, fontWeight: "bold" }}
                  >
                    {errors.Mobile}
                  </Typography>
                ) : null}

                {/* Nodal Contact Details Email */}
                <div className="inputBox nodalInputBox">
                  <label htmlFor="Email" className="inputLabel">
                    Email :
                  </label>
                  <input
                    type="text"
                    id="Email"
                    name="Email"
                    className="inputField"
                    onChange={handleChange}
                    value={values.Email}
                  />
                </div>
                {errors.Email ? (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ mt: 0.4, fontWeight: "bold" }}
                  >
                    {errors.Email}
                  </Typography>
                ) : null}
              </div>
            </div>
          </div>
        </Box>

        <div className="inputBox" style={{ marginBottom: "1rem" }}>
          <label htmlFor="schoolAffiliationCode" className="inputLabel">
            School Affiliation Code :
          </label>
          <input
            type="text"
            id="schoolAffiliationCode"
            name="schoolAffiliationCode"
            className="inputField"
            onChange={handleChange}
            value={values.schoolAffiliationCode}
          />
        </div>

        {/* 
        <Box mb={3}>
          <Typography variant="h6">Student Strength</Typography>
          <TextField
            fullWidth
            label="Primary"
            id="PrimaryStudent"
            name="PrimaryStudent"
            onChange={handleChange}
            value={values.PrimaryStudent}
            variant="outlined"
            margin="normal"
          />
          {errors.PrimaryStudent ? (
            <Typography
              variant="body2"
              color="error"
              sx={{ mt: 0.4, fontWeight: "bold" }}
            >
              {errors.PrimaryStudent}
            </Typography>
          ) : null}
          <TextField
            fullWidth
            label="Middle"
            id="MiddleStudent"
            name="MiddleStudent"
            onChange={handleChange}
            value={values.MiddleStudent}
            variant="outlined"
            margin="normal"
          />
          <TextField
            fullWidth
            label="Senior"
            id="SeniorStudent"
            name="SeniorStudent"
            onChange={handleChange}
            value={values.SeniorStudent}
            variant="outlined"
            margin="normal"
          />
        </Box> */}

        <FormControl component="fieldset" sx={{ mb: 3 }}>
          <FormLabel component="legend">Food Business Operator</FormLabel>
          <RadioGroup
            name="FoodBusiness"
            onChange={handleChange}
            value={values.FoodBusiness}
          >
            {["Canteen/Food Shop/Vendors/Kiosk", "Kitchen/Mess", "None"].map(
              (option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              )
            )}
            {errors.FoodBusiness ? (
              <Typography
                variant="body2"
                color="error"
                sx={{ mt: 0.4, fontWeight: "bold" }}
              >
                {errors.FoodBusiness}
              </Typography>
            ) : null}
          </RadioGroup>
        </FormControl>

        <FormControl
          disabled={values.typeOfSchoolCategories === "Private" ? true : false}
          component="fieldset"
          sx={{ mb: 3, width: "100%" }}
        >
          <FormLabel component="legend">Mid-day Meal</FormLabel>
          <RadioGroup
            sx={{
              cursor:
                values.typeOfSchoolCategories === "Private"
                  ? "not-allowed"
                  : "",
            }}
            row
            name="provided"
            onChange={handleChange}
            value={values.provided}
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        <Button variant="contained" color="primary" type="submit">
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default EnrolmentForm;
