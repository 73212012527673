import React, { useState } from "react";

const AlternativeEnergySources = ({ data }) => {
  const [selectedOptions, setSelectedOptions] = useState({});

  const handleOptionChange = (key, value) => {
    setSelectedOptions((prev) => ({ ...prev, [key]: value }));
  };

  // Styles
  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      fontSize: "14px",
      lineHeight: "1.6",
      margin: "20px 40px",
      color: "#333",
    },
    heading: {
      fontSize: "16px",
      marginBottom: "16px",
      fontWeight: "bold",
    },
    description: {
      fontSize: "14px",
      marginBottom: "16px",
    },
    section: {
      marginBottom: "20px",
      marginLeft: "40px",
    },
    label: {
      fontSize: "14px",
      cursor: "pointer",
    },
    inputGroup: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      marginTop: "8px",
    },
    input: {
      marginRight: "8px",
    },
    textarea: {
      display: "block",
      width: "100%",
      maxWidth: "500px",
      marginTop: "8px",
      marginBottom: "15px",
      fontSize: "14px",
      padding: "8px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      resize: "vertical",
    },
    nestedList: {
      marginLeft: "40px",
      listStyleType: "none",
    },
    subHeading: {
      fontSize: "16px",
      fontWeight: "bold",
      marginBottom: "8px",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>{data.text}</h1>
      <p style={styles.description}>{data.description}</p>
      <ol type="A" style={{ paddingLeft: "20px" }}>
        {data.options.map((option, index) => (
          <li key={index} style={styles.section}>
            <h4 style={styles.subHeading}>{option.text}</h4>
            {option.sub_question?.sub_options ? (
              <ul style={styles.nestedList}>
                {option.sub_question.sub_options.map((sub, subIndex) => (
                  <li key={subIndex}>
                    <label>{sub.text}</label>
                    <div style={styles.inputGroup}>
                      {sub.options &&
                        sub.options.map((choice, choiceIndex) => (
                          <label key={choiceIndex} style={styles.label}>
                            <input
                              type="radio"
                              name={`${option.text}-${sub.text}`}
                              value={choice.value}
                              checked={
                                selectedOptions[
                                  `${option.text}-${sub.text}`
                                ] === choice.value
                              }
                              onChange={() =>
                                handleOptionChange(
                                  `${option.text}-${sub.text}`,
                                  choice.value
                                )
                              }
                              style={styles.input}
                            />
                            {choice.text}
                          </label>
                        ))}
                      {sub.textarea && (
                        <textarea
                          rows="2"
                          placeholder="Specify..."
                          style={styles.textarea}
                          value={
                            selectedOptions[
                              `${option.text}-${sub.text}-textarea`
                            ] || ""
                          }
                          onChange={(e) =>
                            handleOptionChange(
                              `${option.text}-${sub.text}-textarea`,
                              e.target.value
                            )
                          }
                        ></textarea>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div style={styles.inputGroup}>
                {option.options?.map((choice, choiceIndex) => (
                  <label key={choiceIndex} style={styles.label}>
                    <input
                      type="radio"
                      name={option.text}
                      value={choice.value}
                      checked={selectedOptions[option.text] === choice.value}
                      onChange={() =>
                        handleOptionChange(option.text, choice.value)
                      }
                      style={styles.input}
                    />
                    {choice.text}
                  </label>
                ))}
              </div>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default AlternativeEnergySources;
