export const complianceCatagloryArray= [
            {
                "id": 4,
                "complianceId": 3,
                "title": "Energy Audit",
                "description": "",
                "createdAt": "2023-09-04T12:14:01.000Z",
                "updatedAt": "2023-09-04T12:14:01.000Z"
            },
            {
                "id": 5,
                "complianceId": 3,
                "title": "Biodiversity Audit",
                "description": "",
                "createdAt": "2023-09-06T07:58:41.000Z",
                "updatedAt": "2023-09-06T07:58:41.000Z"
            },
            {
                "id": 6,
                "complianceId": 3,
                "title": "Water Audit",
                "description": "",
                "createdAt": "2023-09-06T09:31:03.000Z",
                "updatedAt": "2023-09-06T09:31:03.000Z"
            },
            {
                "id": 7,
                "complianceId": 3,
                "title": "Waste Audit",
                "description": "",
                "createdAt": "2023-09-06T09:47:16.000Z",
                "updatedAt": "2023-09-06T09:47:16.000Z"
            },
            {
                "id": 8,
                "complianceId": 3,
                "title": "Transport",
                "description": "",
                "createdAt": "2023-09-06T10:06:25.000Z",
                "updatedAt": "2023-09-06T10:06:25.000Z"
            },
]