import React, {
	useState,
	useEffect,
	useCallback,
	useMemo,
	useImperativeHandle,
	forwardRef,
	memo,
} from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Pagination,
	TextField,
	CircularProgress,
	Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { useSubAdminCSV } from "contexts/SubAdminCSVContext";
import debounce from "lodash/debounce";
import moment from "moment";
import YuvaLoader from "pages/Forum/components/Loader/YuvaLoader";
import { Popup } from "layout/Popup";

// Helper function for preprocessing Class
const preprocessClass = (classStr) => {
	const romanToNum = {
		I: 1,
		II: 2,
		III: 3,
		IV: 4,
		V: 5,
		VI: 6,
		VII: 7,
		VIII: 8,
		IX: 9,
		X: 10,
		XI: 11,
		XII: 12,
	};

	const wordsToNum = {
		FIRST: 1,
		SECOND: 2,
		THIRD: 3,
		FOURTH: 4,
		FIFTH: 5,
		SIXTH: 6,
		SEVENTH: 7,
		EIGHTH: 8,
		NINTH: 9,
		TENTH: 10,
		ELEVENTH: 11,
		TWELFTH: 12,
	};
	// console.log("ClassStr found during preprocessing: ", classStr);
	// Clean the class string: convert to uppercase, remove special characters
	classStr = String(classStr)
		.toUpperCase()
		.replace(/[^A-Z0-9]/g, ""); // Remove non-alphanumeric characters

	// Check if the class is a direct match with Roman or word numerals
	let classNum =
		romanToNum[classStr] || wordsToNum[classStr] || parseInt(classStr) || 0;

	// Extract section if it's part of the class string (e.g., 10A or XII-B)
	let section = "";

	if (typeof classStr === "string" && !/^\d+$/.test(classStr)) {
		// Look for a trailing section (A-H)
		const lastChar = classStr[classStr.length - 1];
		if (/[A-H]/.test(lastChar)) {
			section = lastChar.toUpperCase();
			classStr = classStr.slice(0, -1); // Remove the section part from classStr
		}

		// Check if the remaining part is a Roman or word numeral
		classNum = romanToNum[classStr] || wordsToNum[classStr] || classNum;
	}
	// console.log("classnum and section are :", classNum, section);
	return { classNum, section };
};

// const romanAndAlphabetsConverter = (value) => {
// 	const romanToNum = {
// 		I: 1,
// 		II: 2,
// 		III: 3,
// 		IV: 4,
// 		V: 5,
// 		VI: 6,
// 		VII: 7,
// 		VIII: 8,
// 		IX: 9,
// 		X: 10,
// 		XI: 11,
// 		XII: 12,
// 	};

// 	const wordsToNum = {
// 		FIRST: 1,
// 		SECOND: 2,
// 		THIRD: 3,
// 		FOURTH: 4,
// 		FIFTH: 5,
// 		SIXTH: 6,
// 		SEVENTH: 7,
// 		EIGHTH: 8,
// 		NINTH: 9,
// 		TENTH: 10,
// 		ELEVENTH: 11,
// 		TWELFTH: 12,
// 	};

// 	value = value.toString().toUpperCase();

// 	const newValue = romanToNum[value] || wordsToNum[value] || parseInt(value);

// 	return newValue;
// };

// Helper function for preprocessing Data
const preprocessData = (data) => {
	return data.map((entry) => {
		const cleanedEntry = {};
		let otherDataExists = false;

		for (const key in entry) {
			let value = entry[key]?.toString().trim() || "";

			// Handle 'otherData' field, ensuring it remains intact
			if (key === "otherData") {
				// Merge 'otherData' from the cleanedEntry (if it has been updated) with the original 'otherData'
				cleanedEntry["otherData"] = {
					...cleanedEntry["otherData"],
					...entry[key],
				};
				otherDataExists = true;
				continue;
			}

			// Convert any "NA" (case insensitive) to an empty string
			if (value.toUpperCase() === "NA") {
				value = "";
			}

			// Preprocess 'dob' field
			if (key === "dob") {
				let formattedDate = null;
				if (!isNaN(value) && parseFloat(value) > 0) {
					const date = new Date((parseFloat(value) - 25569) * 86400 * 1000);
					formattedDate = moment(date).format("YYYY-MM-DD");
				} else if (typeof value === "string") {
					const possibleFormats = [
						"DD/MM/YYYY",
						"DD/MM/YY",
						"DD-MM-YYYY",
						"DD-MM-YY",
						"MM/DD/YYYY",
						"MM/DD/YY",
						"MM-DD-YYYY",
						"MM-DD-YY",
						"YYYY-MM-DD",
						"D/M/YYYY",
						"D-M-YYYY",
						"D.M.YY",
						"DD.MM.YY",
						"DD.MM.YYYY",
						"D.M.YYYY",
						"DD.M.YYY",
						"D.MM.YYYY",
						"MMMM D, YYYY",
						"D MMMM YYYY",
						"D MMM YYYY",
					];

					for (const format of possibleFormats) {
						const parsedDate = moment(value, format, true);
						if (parsedDate.isValid()) {
							formattedDate = parsedDate.format("YYYY-MM-DD");
							break;
						}
					}
				}
				if (formattedDate) {
					value = formattedDate;
				}
			}

			// Preprocess 'class' field
			if (key === "class") {
				const { classNum, section } = preprocessClass(value);
				value = classNum || value;
				// Ensure 'otherData' exists in the cleaned entry
				if (!cleanedEntry["otherData"]) {
					cleanedEntry["otherData"] = {};
				}

				// Merge 'section' into existing 'otherData'
				cleanedEntry["otherData"] = {
					...cleanedEntry["otherData"],
					section,
				};
			}

			// Preprocess 'gender' field
			if (key === "gender") {
				const maleValues = ["male", "m", "boy", "man"];
				const femaleValues = ["female", "f", "girl", "woman"];

				if (maleValues.includes(value.toLowerCase())) {
					value = "M";
				} else if (femaleValues.includes(value.toLowerCase())) {
					value = "F";
				} else {
					value = value.charAt(0);
				}
			}

			cleanedEntry[key] = value;
		}

		// If 'otherData' wasn't present in the original entry, ensure it's initialized
		if (!otherDataExists && !cleanedEntry["otherData"]) {
			cleanedEntry["otherData"] = {};
		}

		return cleanedEntry;
	});
};

// Helper Function for Calculate Age
const calculateAge = (dob) => {
	const today = new Date();
	let age = today.getFullYear() - dob.getFullYear();
	const m = today.getMonth() - dob.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
		age--;
	}
	return age;
};

// Validation for all rows (initial validation)
const validateData = (data, role) => {
	const correctData = [];
	const incorrectData = [];

	data.forEach((entry) => {
		const { validatedRow, isRowValid } = validateRow(entry, role);

		if (isRowValid) {
			correctData.push(entry); // Push original row if valid
		} else {
			incorrectData.push(validatedRow); // Push validated structure if invalid
		}
	});

	return { correctData, incorrectData };
};

// Helper function for validating a single row
const validateRow = (row, role) => {
	let isRowValid = true;
	const validatedRow = {};
	// console.log("Before Validation we had: ", row);
	// First, validate each field individually
	for (const key in row) {
		if (key !== "otherData") {
			const value = row[key]?.value !== undefined ? row[key].value : row[key];
			validatedRow[key] = validateField(key, value, row, role);
			if (!validatedRow[key].isValid) isRowValid = false; // Keep track of row validity
		} else {
			validatedRow[key] = row[key]; // Preserve otherData as is
		}
	}
	// console.log("After Valdation Row is like: ", validatedRow);

	// After individual validation, apply group validation logic
	const finalValidatedRow = applyGroupValidation(validatedRow);
	// Update the isRowValid flag based on group validation result
	isRowValid = Object.values(finalValidatedRow).every((field) =>
		field?.isValid !== undefined ? field.isValid : true
	);
	return { validatedRow: finalValidatedRow, isRowValid };
};

// Function to validate each individual field
const validateField = (key, value, row, role) => {
	const emailRegex =
		/^[a-zA-Z0-9]+([.][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
	const nameRegex = /^[a-zA-Z\s.]+$/;
	let isValid = true; // Default to true
	// console.log("Key, Value, Row, role: ", key, value, row, role);
	switch (key) {
		case "first_name":
			// First name should not be empty and must contain only letters, spaces, and periods
			if (value?.toString().trim() !== "" && nameRegex.test(value)) {
				value = value.toString().trim(); // Store the trimmed value
			} else {
				isValid = false;
			}
			break;

		case "email":
			// Email should match the email regex or be marked as incorrect if empty
			if (value?.toString().trim() !== "" && emailRegex.test(value)) {
				value = value.toString().trim(); // Store the trimmed value
			} else {
				isValid = false;
			}
			break;

		case "contact":
			// Contact should not be empty, must be 10 digits, and contain only numbers
			if (
				value?.toString().trim() !== "" &&
				value.length === 10 &&
				!isNaN(value)
			) {
				value = value.toString().trim(); // Store the value as-is
			} else {
				isValid = false;
			}
			break;

		case "father_name":
		case "mother_name":
		case "guardian1":
		case "guardian2":
			// These fields should follow the same rules as first_name (not empty, only letters, spaces, and periods allowed)
			if (value?.toString().trim() !== "" && nameRegex.test(value)) {
				value = value.toString().trim(); // Store the trimmed value
			} else {
				isValid = false;
			}
			break;

		case "class":
			const { classNum } = preprocessClass(value);
			if (classNum >= 6 && classNum <= 12) {
				value = classNum;
				// section = extractedSection; // Store the section if extracted
			} else {
				isValid = false;
			}
			break;

		case "stream":
			// Stream must not be empty if class is 11 or 12
			const classValue = parseInt(row?.class?.value || row?.class);

			// Check if classValue is NaN, and if it's 11 or 12, check the stream value
			if (
				(!isNaN(classValue) && !(classValue === 11 || classValue === 12)) ||
				value?.toString().trim() !== ""
			) {
				value = value.toString().trim(); // Store the trimmed value
			} else {
				isValid = false;
			}

			break;

		case "dob":
			// Validate the date format
			const dateFormats = [
				"DD/MM/YYYY",
				"DD/MM/YY",
				"DD-MM-YYYY",
				"DD-MM-YY",
				"MM/DD/YYYY",
				"MM/DD/YY",
				"MM-DD-YYYY",
				"MM-DD-YY",
				"YYYY-MM-DD",
				"D/M/YYYY",
				"D-M-YYYY",
				"D.M.YY",
				"DD.MM.YY",
				"DD.MM.YYYY",
				"D.M.YYYY",
				"DD.M.YYY",
				"D.MM.YYYY",
				"MMMM D, YYYY",
				"D MMMM YYYY",
				"D MMM YYYY",
			];

			let parsedDate = null;

			for (const format of dateFormats) {
				parsedDate = moment(value, format, true);
				if (parsedDate.isValid()) {
					value = parsedDate.format("YYYY-MM-DD");
					isValid = true;
					break;
				}
			}

			if (!isValid) {
				break;
			}

			// Calculate age based on the DOB
			const dob = new Date(value);
			const age = calculateAge(dob);

			// Validate age based on role
			if (role === "student" && (age < 8 || age > 25)) {
				isValid = false; // Student age must be between 8 and 25 years
			} else if (role === "teacher" && (age < 18 || age > 75)) {
				isValid = false; // Teacher age must be between 18 and 75 years
			}
			break;

		default:
			// Default case is always valid
			isValid = true;
			break;
	}

	return { value, isValid };
};

// Function to apply group validation logic after individual fields are validated
const applyGroupValidation = (validatedRow) => {
	// Group validation for contact and email
	const { contact, email } = validatedRow;

	// Case 1: Both contact and email are provided, validate both
	if (
		contact?.value &&
		contact?.value.trim() !== "" &&
		email?.value &&
		email?.value.trim() !== ""
	) {
		validatedRow.contact.isValid = contact.isValid; // Validate contact
		validatedRow.email.isValid = email.isValid; // Validate email
	}
	// Case 2: Only contact is provided, email is marked as valid
	else if (contact?.value && contact?.value.trim() !== "") {
		validatedRow.contact.isValid = contact.isValid;
		validatedRow.email.isValid = true; // Mark email as valid (even if empty)
	}
	// Case 3: Only email is provided, contact is marked as valid
	else if (email?.value && email?.value.trim() !== "") {
		validatedRow.email.isValid = email.isValid;
		validatedRow.contact.isValid = true; // Mark contact as valid (even if empty)
	}

	// Group validation for guardian fields
	const guardianFields = [
		"father_name",
		"mother_name",
		"guardian1",
		"guardian2",
	];
	const hasGuardian = guardianFields.some((key) => validatedRow[key]?.isValid);

	guardianFields.forEach((key) => {
		// Mark all guardians invalid if none are valid or missing, otherwise mark them valid
		validatedRow[key] && (validatedRow[key].isValid = hasGuardian);
	});

	return validatedRow;
};

// Memoized Row Component
const MemoizedTableRow = memo(
	({ row, index, handleFieldChange, handleBlur }) => (
		<TableRow>
			{Object.keys(row).map(
				(key) =>
					key !== "otherData" && (
						<TableCell key={key} style={{ padding: "0.5rem" }}>
							{key === "gender" ? (
								<TextField
									select
									value={
										row[key].value === "M" ||
										row[key].value === "F" ||
										row[key].value === ""
											? row[key].value
											: "O"
									} // Handle gender selection logic
									onChange={(e) =>
										handleFieldChange(index, key, e.target.value)
									}
									onBlur={() => handleBlur(index, key)} // Debounced onBlur
									variant="outlined"
									size="small"
									style={{ width: "120px" }}
									SelectProps={{
										native: true,
									}}
								>
									<option value="">None</option>
									<option value="M">Male</option>
									<option value="F">Female</option>
									<option value="O">Other</option>
								</TextField>
							) : (
								<TextField
									value={row[key].value}
									error={!row[key].isValid}
									onChange={(e) =>
										handleFieldChange(index, key, e.target.value)
									}
									onBlur={() => handleBlur(index, key)} // Debounced onBlur
									variant="outlined"
									size="small"
									style={{
										borderColor: row[key].isValid ? "" : "red",
										width: "120px",
									}}
								/>
							)}
						</TableCell>
					)
			)}
		</TableRow>
	)
);

const ExcelView = forwardRef((props, ref) => {
	const [correctData, setCorrectData] = useState([]);
	const [incorrectData, setIncorrectData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const rowsPerPage = 15;
	const { loading, setLoading, fileData, mappedHeaders, role } =
		useSubAdminCSV();

	// Preprocess and validate data on mount
	useEffect(() => {
		const loadData = async () => {
			setLoading(true);
			try {
				const cleanedData = await preprocessData(fileData);
				// console.log("Cleaned Data found is:", cleanedData);
				const { correctData, incorrectData } = await validateData(
					cleanedData,
					role
				);

				// console.log("Cleaned Incorrect Data found is:", incorrectData);
				setCorrectData(correctData);
				setIncorrectData(incorrectData);
			} catch (error) {
				Popup(
					"error",
					"Something Went Wrong",
					"Error occured during Data Processing",
					3000
				);
			} finally {
				setLoading(false);
			}
		};

		loadData();
	}, [fileData, role]);

	// Debounced version of handleFieldBlur to reduce lag
	const debouncedHandleFieldBlur = useCallback(
		debounce((index, key) => {
			setLoading(true);
			const updatedIncorrectData = [...incorrectData];
			const row = updatedIncorrectData[index];

			const { validatedRow, isRowValid } = validateRow(row, role);
			if (isRowValid) {
				const normalizedRow = {};
				for (const key in validatedRow) {
					if (key !== "otherData") {
						normalizedRow[key] = validatedRow[key].value;
					} else {
						normalizedRow[key] = validatedRow[key]; // Keep otherData unchanged
					}
				}
				setCorrectData((prev) => [...prev, normalizedRow]);
				updatedIncorrectData.splice(index, 1); // Remove from incorrectData
				setIncorrectData(updatedIncorrectData);
			} else {
				updatedIncorrectData[index] = validatedRow;
				setIncorrectData(updatedIncorrectData);
			}
			setLoading(false);
		}, 300),
		[incorrectData]
	);

	const handleFieldChange = (index, key, value) => {
		const updatedIncorrectData = [...incorrectData];
		updatedIncorrectData[index][key].value = value; // Use the actual index here
		setIncorrectData(updatedIncorrectData);
	};

	const handleBlur = (index, key) => {
		debouncedHandleFieldBlur(index, key); // Use the actual index here
	};

	const handleSubmit = () => {
		// Calculate the normalized incorrect data
		const normalizedIncorrectData = incorrectData.map((row) => {
			const normalizedRow = {};
			for (const key in row) {
				normalizedRow[key] = key !== "otherData" ? row[key].value : row[key];
			}
			return normalizedRow;
		});

		// Calculate the percentage of incorrect data
		const totalRows = correctData.length + incorrectData.length;
		const incorrectPercentage =
			totalRows > 0 ? (incorrectData.length / totalRows) * 100 : 0;

		// Show the popup with the incorrect data percentage
		Popup(
			"info",
			"Data Submission",
			`Submission includes ${incorrectPercentage.toFixed(2)}% incorrect data.`,
			3000
		);

		// Create the payload and log it
		const payload = {
			correctData,
			incorrectData: normalizedIncorrectData,
		};

		// console.log('Submitting Payload from Excel View:', payload);
		return payload;
	};

	// Expose handleSubmit using ref
	useImperativeHandle(ref, () => ({
		handleSubmit,
	}));

	// Memoizing displayData for pagination
	const displayData = useMemo(
		() =>
			incorrectData.slice(
				(currentPage - 1) * rowsPerPage,
				currentPage * rowsPerPage
			),
		[incorrectData, currentPage, rowsPerPage]
	);

	return (
		<Box style={{ position: "absolute", left: 0, width: "100%" }}>
			{loading && <YuvaLoader setShow={false} show={loading} />}
			{incorrectData?.length === 0 ? (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					height="60vh"
				>
					<Typography variant="h6">All data is correct</Typography>
				</Box>
			) : (
				<>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									{mappedHeaders
										.filter((header) => header.destination)
										.map((header, index) => (
											<TableCell key={index}>
												{header.destination.replace("_", " ").toUpperCase()}
											</TableCell>
										))}
								</TableRow>
							</TableHead>
							<TableBody>
								{displayData.map((row, index) => {
									// Calculate the correct index in the entire dataset
									const actualIndex = (currentPage - 1) * rowsPerPage + index;

									return (
										<MemoizedTableRow
											key={actualIndex}
											row={row}
											index={actualIndex} // Pass the correct index to the handler
											handleFieldChange={handleFieldChange}
											handleBlur={handleBlur}
										/>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>

					<Box mt={2} display="flex" justifyContent="center">
						<Pagination
							count={Math.ceil(incorrectData.length / rowsPerPage)}
							page={currentPage}
							onChange={(e, page) => setCurrentPage(page)}
						/>
					</Box>
				</>
			)}
		</Box>
	);
});

export default ExcelView;
