import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
  FormControl,
  Radio,
  RadioGroup,
} from "@mui/material";
import { usePDF } from "@react-pdf/renderer";

import { Field, useFormik } from "formik";
import * as Yup from "yup";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Accordion from "@mui/material/Accordion";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import UploadIcon from "@mui/icons-material/Upload";
import { apiAuth, apiJson } from "api";
import ProgressBar from "pages/course/ProgressBar";
import DayCard from "pages/course/DayCard";
import credit_icon from "../../../twentyOneDaysChallenge/assests/Group 381.svg";
import ChallengeStarter from "../ChallengeStarter/ChallengeStarter";
import CompleteTaskPage from "pages/course/CompleteTaskPage/CompleteTaskPage";
import EnrolmentForm from "./EnrolmentForm";
import Ch_Logo_21 from "../../../twentyOneDaysChallenge/assests/Group 386.svg";
import fssai from "../../assets/fssai.png";
import eatRightSchhol from "../../assets/eatRightSchool.png";
import { PDFViewer } from "@react-pdf/renderer";
import GovtWithCanteen from "./GovtWithCanteen";
import GovtWithoutCanteen from "./GovtWithoutCanteen";
import PrivateWithoutCanteen from "./PrivateWithoutCanteen";
import PrivateWithCanteen from "./PrivateWithCanteen";
import Notpassed from "./Notpassed";

function RetakeGovtWithCanteen({ answers, headName, date }) {
  const [allRequiredQuestion, setAllRequiredQuestion] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState([]);
  const [populateQuestion, setpopulateQuestion] = useState([]);
  const [description, setDescription] = useState(false);
  const [retakeResult, setRetakeResult] = useState(answers);

  // result updation
  const updateFields = (id, updates) => {
    setRetakeResult((prevData) =>
      prevData.map((item, index) =>
        index === id ? { ...item, ...updates } : item
      )
    );
  };

  // All questions.
  const questions = [
    {
      id: 1,
      question:
        "Supplement ongoing curriculum with Eat Right resource material (Yellow Books 1 & 2, Activity Book/ Adulteration test kit/Food Safety Guidebook for teachers / Pink Book/)",
      categroy: "Curricular & Co-Curricular Inclusion",
      points: 10,
      subQuestion: [
        "In how many classes has the Yellow Book been included?",
        "In how many classes has the Activity Book been included?",
        "In how many classes has the Food Safety Guidebook been included?",
        "In how many classes has the Pink Book been included?",
      ],
      yellowBookLink: "https://fssai.gov.in/book-details.php?bkid=197",
      pinkBooKLink: "https://www.fssai.gov.in/flipbook.php?bookid=348#book2/",
      documentRequired: "Certificate issued by the Principal",
    },
    {
      id: 2,
      question:
        "Engage students in co-curricular activities (school assembly/poster/deb ate /Prabhat Pheris/Workshopsetc.)",
      categroy: "Curricular & Co-Curricular Inclusion",
      subQuestion: [
        "Have you engaged students in co-curricular activities during school assemblies?",
        " Have you engaged students in co-curricular activities through poster-making?",
        "Have you engaged students in co-curricular activities like debates?",
        " Have you engaged students in co-curricular activities such as Prabhat Pheris?",
        " Have you engaged students in co-curricular activities by organizing workshops?",
      ],
      points: 10,
      documentRequired:
        "Activity schedule along with photos/report, certified by the Principal.",
    },
    {
      id: 3,
      question:
        "Health & Wellness Ambassador/s nominated and certified through online certification program (Eat Right School portal)",
      categroy: "Curricular & Co-Curricular Inclusion",
      subQuestion: ["How many Certified H&W Ambassadors have been included?"],
      points: 2,
      documentRequired:
        "Upload the online certificate for all certified Health & Wellness coordinators, with a minimum of two ambassadors.",
      additionalInfo: `
<h3 style="color: #2356fe;">Steps to Nominate and Certify Health & Wellness Ambassadors</h3>

<h4 style="color: #2356fe; padding-top: 1rem;">1. Access the Eat Right School Portal:</h4>
<ul>
<li>Visit the official "Eat Right School" portal using a browser.</li>
<li>Ensure you have the login credentials provided to the school.</li>
</ul>

<h4 style="color: #2356fe; padding-top: 1rem;">2. Identify Potential Health & Wellness Ambassadors:</h4>
<ul>
<li>Nominate at least two candidates from the school staff who can effectively promote health and wellness activities.</li>
<li>These individuals could be teachers, physical education instructors, or other faculty members who are enthusiastic about leading health-related initiatives.</li>
</ul>

<h4 style="color: #2356fe; padding-top: 1rem;">3. Register Ambassadors for Certification:</h4>
<ul>
<li>Log in to the portal and navigate to the section for Health & Wellness Certification Program.</li>
<li>Fill in the required details for each nominated ambassador (e.g., name, designation, contact information).</li>
</ul>

<h4 style="color: #2356fe; padding-top: 1rem;">4. Complete the Online Certification Program:</h4>
<ul>
<li>Ambassadors must complete the online certification training program available on the portal.</li>
<li>The training includes modules on food safety, nutrition, and promoting a healthy school environment.</li>
<li>Ambassadors may need to take an assessment or quiz to qualify for certification.</li>
</ul>

<h4 style="color: #2356fe; padding-top: 1rem;">5. Download Online Certificates:</h4>
<ul>
<li>After successfully completing the program, download the certificates for all certified Health & Wellness Ambassadors from the portal.</li>
</ul>

<h4 style="color: #2356fe; padding-top: 1rem;">6. Upload Certificates:</h4>
<ul>
<li>Return to the portal and upload the certification documents as proof for all certified Health & Wellness Ambassadors.</li>
<li>Ensure the uploaded files are in a supported format (e.g., PDF, JPG) and comply with size restrictions.</li>
</ul>

<h4 style="color: #2356fe; padding-top: 1rem;">7. Verification and Documentation:</h4>
<ul>
<li>Keep a printed and digital record of all certificates for future reference or audits.</li>
<li>Share the certifications with the school community (e.g., in newsletters or meetings) to highlight the initiative.</li>
</ul>

<h4 style="color: #2356fe; padding-top: 1rem;">8. Empower Ambassadors to Take Action:</h4>
<ul>
<li>Once certified, assign responsibilities to the Health & Wellness Ambassadors:</li>
<ul>
<li>Conduct awareness sessions on healthy eating.</li>
<li>Organize activities like quizzes, workshops, and campaigns.</li>
<li>Monitor the school's compliance with health and safety standards.</li>
</ul>
</ul>

<h4 style="color: #2356fe; padding-top: 1rem;">9. Track Progress:</h4>
<ul>
<li>Use the Eat Right School Portal to report on initiatives taken by the ambassadors.</li>
<li>Update the portal with progress and any new certifications achieved.</li>
</ul>
`,
    },
    {
      id: 4,
      question:
        "Encourage eating fruits, healthy local and seasonal snacks (makhana, nuts, puffed rice, millets etc.) and healthy beverages (water, coconut water, lassi, nimbu paani etc) and green leafy vegetables in Tiffin",
      categroy: "Curricular & Co-Curricular Inclusion",
      subQuestion: [
        "Any administrative actions have been taken by the school",
        "Any best practices or innovations implemented by the school?",
      ],
      points: 2,
      documentRequired:
        "Upload the online certificate for all certified Health & Wellness coordinators, with a minimum of two ambassadors",
    },
    {
      id: 5,
      question: "Availability of portable drinking water",
      categroy: "Alignment with WASH Programme/ Swachh Vidyalaya Scheme",
      subQuestion: [
        "Is portable drinking water available?",
        "Are dust bins provided, and is waste disposal done regularly?",
        "Are hand washing facilities with soap and water available?",
        "Are operational toilets available?",
      ],
      points: 2,
      documentRequired: "Photo and certificate from the head of the school.",
    },

    {
      id: 6,
      question:
        "Licensing/Registration of canteens/kitchens/fo od vendors with FSSAI",
      categroy: "Alignment with Nutrition Programmes",
      section: "B For Govt/Govt aided Schools only",
      subQuestion: [
        "Are canteens, kitchens, and food vendors licensed are registered with FSSAI?",
      ],
      points: 2,
      fssaiLink: "https://foscos.fssai.gov.in/",
      documentRequired:
        "Upload the document with the registration/license number provided by FSSAI.",
      additionalInfo: `
<h3>Steps for Licensing/Registration of Canteens/Kitchens/Food Vendors with FSSAI</h3>

<ol>
<li><strong>Access FSSAI Website:</strong> 
  <ul>
    <li>Visit the official FSSAI (Food Safety and Standards Authority of India) website: <a href="https://www.fssai.gov.in" target="_blank">www.fssai.gov.in</a>.</li>
  </ul>
</li>

<li><strong>Choose the Type of License:</strong>
  <ul>
    <li>Determine whether your canteen/kitchen/food vendor falls under the registration or licensing category. 
      <ul>
        <li>Basic Registration for small food businesses.</li>
        <li>State License for medium-sized food businesses.</li>
        <li>Central License for large businesses involved in manufacturing or exporting food products.</li>
      </ul>
    </li>
  </ul>
</li>

<li><strong>Create an Account:</strong> 
  <ul>
    <li>Register on the FSSAI Food Licensing and Registration System (FLRS) portal by creating an account with your business details.</li>
  </ul>
</li>

<li><strong>Fill the Application Form:</strong> 
  <ul>
    <li>Fill out the required application form online with accurate details about the canteen/kitchen/food vendor, including location, food items, and the nature of the business.</li>
  </ul>
</li>

<li><strong>Upload Required Documents:</strong>
  <ul>
    <li>Prepare and upload the following documents:
      <ul>
        <li>Proof of identity and address of the business owner.</li>
        <li>Food safety management system in place (e.g., FSSAI compliance reports, hygiene standards).</li>
        <li>Details of the food business (menu, premises layout, etc.).</li>
      </ul>
    </li>
  </ul>
</li>

<li><strong>Submit Application:</strong>
  <ul>
    <li>Once the form is completed, and documents are uploaded, submit the application for review by the FSSAI.</li>
  </ul>
</li>

<li><strong>Pay the Registration/License Fee:</strong>
  <ul>
    <li>Make the necessary payment for registration or licensing fees, as per the FSSAI guidelines, to complete the process.</li>
  </ul>
</li>

<li><strong>Wait for Approval:</strong>
  <ul>
    <li>After submission, the FSSAI will process the application. If approved, you will receive a registration or license number along with a certificate.</li>
  </ul>
</li>

<li><strong>Download and Upload Certificate:</strong>
  <ul>
    <li>Download the FSSAI certificate from the portal once issued and upload the document on your school's platform for records.</li>
  </ul>
</li>

<li><strong>Renewal and Compliance:</strong>
  <ul>
    <li>Ensure the canteen/kitchen/food vendor remains compliant with FSSAI standards and renew the registration/license as needed (usually every 1-5 years, depending on the type).</li>
  </ul>
</li>
</ol>

<p>These steps will ensure that your canteen/kitchen/food vendor is properly licensed/registered with FSSAI, promoting food safety and hygiene standards in your school environment.</p>
`,
    },
    {
      id: 7,
      question: "Hygiene Rating of the canteens/kitchens/food vendors",
      categroy: "Alignment with Nutrition Programmes",
      section: "B For Govt/Govt aided Schools only",
      subQuestion: [
        "Is there a hygiene rating for the canteens, kitchens, and food vendors?",
      ],
      points: 2,
      documentRequired: "Upload the certificate with the Hygiene Rating",
    },
    {
      id: 8,
      question:
        "Provide fruits, healthy local and seasonal snacks (makhana, nuts, puffed rice, millets etc.) and healthy beverages (water, coconut water, lassi, nimbu paani etc) and green leafy vegetables in mid-day meal menu/Mess/Kitchen/Canteen",
      categroy: "Alignment with Nutrition Programmes",
      section: "B For Govt/Govt aided Schools only",
      subQuestion: [
        "Is the inclusion of local, seasonal, or nutritious foods and healthy beverages implemented in MDM/Canteen/Mess/Kitchen?",
      ],
      points: 2,
      documentRequired:
        "Upload Menu Plan of the food being provided in MDM/Canteen/Mess/Kitchen",
    },
  ];
  // Advisory note,
  const advisoryNote = `
    <p>Dear Parents,</p>
    
    <p>As part of our commitment to fostering the health and well-being of our students, we encourage you to prioritize providing nutritious and wholesome options in your child’s tiffin. A balanced diet is essential for their physical and mental development, and it also helps improve their concentration and energy levels throughout the day.</p>
    
    <p>Here are some suggestions for healthy tiffin options:</p>
    
    <ul>
      <li><strong>Fruits and Vegetables:</strong>
        <ul>
          <li>Include seasonal fruits such as apples, bananas, oranges, or berries, as they are rich in essential vitamins and minerals.</li>
          <li>Add green leafy vegetables like spinach or fenugreek in parathas, sandwiches, or rolls for a nutrient boost.</li>
        </ul>
      </li>
    
      <li><strong>Local and Seasonal Snacks:</strong>
        <ul>
          <li>Consider snacks like makhana (fox nuts), roasted nuts, puffed rice, or millet-based dishes. These are not only healthy but also packed with energy.</li>
          <li>Homemade snacks such as poha, upma, or idlis made with millets are excellent choices.</li>
        </ul>
      </li>
    
      <li><strong>Healthy Beverages:</strong>
        <ul>
          <li>Encourage your child to drink water regularly to stay hydrated.</li>
          <li>Include natural beverages such as coconut water, lassi, or nimbu paani, which are refreshing and healthy alternatives to sugary drinks.</li>
        </ul>
      </li>
    </ul>
    
    <p><strong>Benefits of Healthy Eating:</strong></p>
    <ul>
      <li>Improves immunity and overall health.</li>
      <li>Boosts concentration and academic performance.</li>
      <li>Reduces the risk of lifestyle-related illnesses later in life.</li>
    </ul>
    
    <p>We request your support in making these small but impactful changes to your child's diet. Together, we can build a healthier and brighter future for our children.</p>
    
    <p>Warm regards,<br>
    [School Authority Name/Title]<br>
    [School Name]<br>
    [Contact Information]</p>
  `;

  // Validation
  const getFormikConfig = () => {
    // Ensure currentQuestion is properly defined before using it
    if (currentQuestion.length > 0 && currentQuestion[0]?.id === 1) {
      return {
        initialValues: {
          Field0: retakeResult[0]["Field0"],
          Field1: retakeResult[0]["Field1"],
          Field2: retakeResult[0]["Field2"],
          Field3: retakeResult[3]["Field0"],
        },
        validationSchema: Yup.object({
          Field0: Yup.string().required("*Required Field"),
          Field1: Yup.string().required("*Required Field"),
          Field2: Yup.string().required("*Required Field"),
          Field3: Yup.string().required("*Required Field"),
        }),
        validateOnChange: false, // Turn off validation on change
        validateOnBlur: true,
        onSubmit: (values, { resetForm }) => {
          console.log(values);
          if (values.Field0 !== undefined) {
            updateFields(0, { Field0: values.Field0 });
          }
          if (values.Field1 !== undefined) {
            updateFields(0, { Field1: values.Field1 });
          }
          if (values.Field2 !== undefined) {
            updateFields(0, { Field2: values.Field2 });
          }
          if (values.Field3 !== undefined) {
            updateFields(0, { Field3: values.Field3 });
          }
          nextQuestion();
          setDescription(false);
          resetForm();
        },
      };
    }
    if (currentQuestion.length > 0 && currentQuestion[0]?.id === 2) {
      return {
        initialValues: {
          Field0: retakeResult[1]["Field0"],
          Field1: retakeResult[1]["Field1"],
          Field2: retakeResult[1]["Field2"],
          Field3: retakeResult[1]["Field3"],
          Field4: retakeResult[1]["Field4"],
        },
        validationSchema: Yup.object({
          // Field0: Yup.string().required("*Required Field"),
          // Field1: Yup.string().required("*Required Field"),
          // Field2: Yup.string().required("*Required Field"),
          // Field3: Yup.string().required("*Required Field"),
          // Field4: Yup.string().required("*Required Field"),
        }),
        validateOnChange: false, // Turn off validation on change
        validateOnBlur: true,
        onSubmit: (values, { resetForm }) => {
          console.log(values);
          if (values.Field0 !== undefined) {
            updateFields(1, { Field0: values.Field0 });
          }
          if (values.Field1 !== undefined) {
            updateFields(1, { Field1: values.Field1 });
          }
          if (values.Field2 !== undefined) {
            updateFields(1, { Field2: values.Field2 });
          }
          if (values.Field3 !== undefined) {
            updateFields(1, { Field3: values.Field3 });
          }
          if (values.Field4 !== undefined) {
            updateFields(1, { Field4: values.Field4 });
          }
          nextQuestion();
          setDescription(false);
          resetForm();
        },
      };
    }
    if (currentQuestion.length > 0 && currentQuestion[0]?.id === 3) {
      return {
        initialValues: {
          Field0: retakeResult[2].Field0,
        },
        validationSchema: Yup.object({
          Field0: Yup.string().required("*Required Field"),
        }),
        validateOnChange: false, // Turn off validation on change
        validateOnBlur: true,
        onSubmit: (values, { resetForm }) => {
          console.log(values);
          updateFields(2, { Field0: values.Field0 });
          nextQuestion();
          setDescription(false);
          resetForm();
          // Ensure the form resets after submit
        },
      };
    }
    console.log(retakeResult);
    if (currentQuestion.length > 0 && currentQuestion[0]?.id === 4) {
      return {
        initialValues: {
          Field0: retakeResult[3].Field0,
          Field1: retakeResult[3].Field1,
        },
        // validationSchema: Yup.object({
        //   Field0: Yup.string().required("*Required Field"),
        //   Field1: Yup.string().required("*Required Field"),
        // }),
        validateOnChange: false, // Turn off validation on change
        validateOnBlur: true,
        onSubmit: (values, { resetForm }) => {
          console.log(values);
          if (values.Field0 !== undefined) {
            updateFields(3, { Field0: values.Field0 });
          }
          if (values.Field1 !== undefined) {
            updateFields(3, { Field1: values.Field1 });
          }

          nextQuestion();
          setDescription(false);
          resetForm();
          // Ensure the form resets after submit
        },
      };
    }
    if (currentQuestion.length > 0 && currentQuestion[0]?.id === 5) {
      return {
        initialValues: {
          Field0: "",
          Field1: "",
          Field2: "",
          Field3: "",
        },
        // validationSchema: Yup.object({
        //   Field0: Yup.string().required("*Required Field"),
        //   Field1: Yup.string().required("*Required Field"),
        //   Field2: Yup.string().required("*Required Field"),
        //   Field3: Yup.string().required("*Required Field"),
        // }),
        validateOnChange: false, // Turn off validation on change
        validateOnBlur: true,
        onSubmit: (values, { resetForm }) => {
          console.log(values);

          if (values.Field0 !== undefined) {
            updateFields(4, { Field0: values.Field0 });
          }
          if (values.Field1 !== undefined) {
            updateFields(4, { Field1: values.Field1 });
          }
          if (values.Field2 !== undefined) {
            updateFields(4, { Field2: values.Field2 });
          }
          if (values.Field3 !== undefined) {
            updateFields(4, { Field3: values.Field3 });
          }
          nextQuestion();
          setDescription(false);
          resetForm();
          // Ensure the form resets after submit
        },
      };
    }

    if (currentQuestion.length > 0 && currentQuestion[0]?.id === 6) {
      return {
        initialValues: {
          Field0: "",
          Field1: "",
        },
        // validationSchema: Yup.object({
        //   Field0: Yup.string().required("*Required Field"),
        //   Field1: Yup.string().required("*Required Field"),
        // }),
        validateOnChange: false, // Turn off validation on change
        validateOnBlur: true,
        onSubmit: (values, { resetForm }) => {
          console.log(values);
          if (values.Field1 !== undefined) {
            updateFields(5, { Field1: values.Field1 });
          }
          if (values.Field0 !== undefined) {
            updateFields(5, { Field0: values.Field0 });
          }
          nextQuestion();
          setDescription(false);
          resetForm();
        },
      };
    }

    if (currentQuestion.length > 0 && currentQuestion[0]?.id === 7) {
      return {
        initialValues: {
          Field0: "",
        },
        validationSchema: Yup.object({
          Field0: Yup.string().required("*Required Field"),
        }),
        validateOnChange: false, // Turn off validation on change
        validateOnBlur: true,
        onSubmit: (values, { resetForm }) => {
          console.log(values);
          updateFields(6, { Field0: values.Field0 });
          nextQuestion();
          setDescription(false);
          resetForm();
        },
      };
    }
    if (currentQuestion.length > 0 && currentQuestion[0]?.id === 8) {
      return {
        initialValues: {
          Field0: "",
        },
        validationSchema: Yup.object({
          Field0: Yup.string().required("*Required Field"),
        }),
        validateOnChange: false, // Turn off validation on change
        validateOnBlur: true,
        onSubmit: (values, { resetForm }) => {
          console.log(values);
          updateFields(7, { Field0: values.Field0 });
          nextQuestion();
          setDescription(false);
          resetForm();
          // Ensure the form resets after submit
        },
      };
    }
    if (currentQuestion.length > 0 && currentQuestion[0]?.id === 9) {
      return {
        initialValues: {
          Field0: "",
        },
        validationSchema: Yup.object({
          Field0: Yup.string().required("*Required Field"),
        }),
        validateOnChange: false, // Turn off validation on change
        validateOnBlur: true,
        onSubmit: (values, { resetForm }) => {
          console.log(values);
          updateFields(8, { Field0: values.Field0 });
          nextQuestion();
          setDescription(false);
          resetForm();
          // Ensure the form resets after submit
        },
      };
    }

    return {
      initialValues: {},
      validationSchema: Yup.object({}),
      onSubmit: () => {
        console.log("Submitted");
      },
    };
  };
  const formikConfig = getFormikConfig();
  const { handleChange, handleSubmit, values, errors } = useFormik({
    ...formikConfig,
  });

  const nextQuestion = () => {
    setCurrentQuestion([allRequiredQuestion[populateQuestion.length]]);
    setpopulateQuestion([...populateQuestion, "done"]);
  };

  useEffect(() => {
    // questions that have not been answered.
    const reTakeQuestion = () => {
      // Create a deep copy of the questions array to avoid mutation
      const remainingQuestions = questions.map((question, index) => {
        const remainingSubQuestions = question.subQuestion.filter(
          (_, subIndex) => {
            const answer = answers[index][`Field${subIndex}`];
            return !(
              answer === "Yes" ||
              answer === "1" ||
              answer === "2" ||
              answer === "5+" ||
              answer === "2+" ||
              answer === "6+" ||
              answer === "3+"
            );
          }
        );

        // Return a new question object if there are any remaining subquestions
        return {
          ...question,
          subQuestion: remainingSubQuestions,
        };
      });

      // Filter out questions with no remaining subquestions
      return remainingQuestions.filter(
        (question) => question.subQuestion.length > 0
      );
    };
    setAllRequiredQuestion(reTakeQuestion());
    setCurrentQuestion([reTakeQuestion()[0]]);
    reTakeQuestion();
    setpopulateQuestion(["done"]);
  }, []);

  if (allRequiredQuestion.length <= 0) {
    return <>Question loading/./././/./././/./</>;
  }
  // Render minimum marks obtained message or PDF when marks are greater than 75%.
  if (allRequiredQuestion.length + 1 === populateQuestion.length) {
    // const totalMarks = 19;
    let marks = 0;

    retakeResult.forEach((obj) => {
      for (let key in obj) {
        if (
          obj[key] === "Yes" ||
          obj[key] === "1" ||
          obj[key] === "2" ||
          obj[key] === "5+" ||
          obj[key] === "3+" ||
          obj[key] === "6+" ||
          obj[key] === "2+"
        ) {
          marks++;
        }
      }
    });

    if (marks < 13) {
      return <Notpassed />;
    }

    if (marks >= 13) {
      return (
        <div style={{ width: "90%", height: "80vh", margin: "0rem auto" }}>
          <PDFViewer style={{ width: "100%", height: "100%" }}>
            <PrivateWithCanteen
              answers={retakeResult}
              date={new Date().toLocaleDateString()}
              headName="Mr. John Doe"
            />
          </PDFViewer>
        </div>
      );
    }
  }
  console.log("all required questions", allRequiredQuestion);
  console.log("current question", currentQuestion);
  console.log("retake result", retakeResult);
  return (
    <>
      <Typography
        sx={{
          color: "#C62E2E",
          width: "80%",
          margin: "1rem auto",
          fontSize: "1.2rem",
          fontWeight: "bold",
        }}
      >
        "The institute has not met the required criteria to earn the Eat Right
        India badge. Listed below are the specific questions that need to be
        answered correctly to fulfill the passing requirements."
      </Typography>
      <Box
        sx={{
          padding: {
            xs: "0.8rem",
            sm: "1rem",
            md: "2rem",
          },
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Box
            sx={{
              padding: {
                xs: "0.8rem",
                sm: "1rem",
                md: "2rem 2rem 2rem 1.5rem",
              },
              boxShadow: "0 3.4px 46.5px rgba(0, 0, 0, 0.1)",
              borderRadius: "1rem",
              backgroundColor: "var(--color-ghostwhite)",
              border: "5px solid #2356fe",
              boxSizing: "border-box",
              width: "auto",
            }}
          >
            {/* Challenge Description Wrapper - Top Section */}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                alignContent: "center",
              }}
            >
              <Box
                component="img"
                sx={{
                  maxHeight: { xs: 50, sm: 110, md: 70, lg: 140 },
                  maxWidth: { xs: 90, sm: 140, md: 180, lg: 220 },

                  marginBottom: "1rem",
                }}
                alt="Eat right school"
                src={eatRightSchhol}
              />
              <Box
                component="img"
                sx={{
                  maxHeight: { xs: 30, sm: 30, md: 40, lg: 50 },
                  maxWidth: { xs: 50, sm: 70, md: 70, lg: 70 },
                }}
                alt="Fssai"
                src={fssai}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                flexDirection: "column",
              }}
            >
              {/* Challenge Details - Left Pane */}
              <Box>
                {/* Challenge Title */}
                <Typography
                  component={"h1"}
                  sx={{
                    fontSize: {
                      xs: "1.3rem",
                      sm: "1.6rem",
                      md: "2rem",
                    },
                    fontWeight: 900,
                    color: "#2356fe",
                    padding: "1rem 0rem 1rem 0rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  {currentQuestion.length > 0
                    ? currentQuestion[0].categroy
                    : ""}
                </Typography>
              </Box>

              {/* Progress Bar - Middle Section */}
              <Box sx={{ width: "100%" }}>
                <Typography
                  component={"p"}
                  sx={{
                    fontWeight: 600,
                    color: "gray",
                  }}
                >
                  Progress
                </Typography>
                <Box sx={{ padding: "0.5rem 0rem 0.5rem 0rem" }}>
                  <ProgressBar
                    progress={
                      (populateQuestion?.length / allRequiredQuestion.length) *
                        100 || 0
                    }
                    bgColor={"#2356FE"}
                  />
                </Box>
              </Box>

              {/* Challenge Display Area - Bottom Section */}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  marginBottom: "0.8rem",
                }}
              >
                {/* Task Upload - Left Pane */}
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Box>
                    {/* sub questions yes or no */}
                    <Box
                      sx={{
                        backgroundColor: "#e9e9e9",
                        marginBottom: "1rem",
                        padding: { xs: "0.5rem", sm: "0.8rem" },
                        borderRadius: "1rem",
                      }}
                    >
                      {currentQuestion.length > 0 && currentQuestion[0].id === 1
                        ? currentQuestion[0].subQuestion.map(
                            (subQuestion, index) => {
                              return (
                                <>
                                  <FormControl
                                    key={index}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flexDirection: {
                                        xs: "column",
                                        sm: "row",
                                      },
                                      alignItems: "center",
                                      // alignContent: "center",
                                      borderBottom: {
                                        xs: "1px solid black",
                                        sm: "none",
                                      },
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {subQuestion}
                                    {index === 0 ? (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-around",
                                          alignItems: "center",
                                          margin: "0.8rem auto",
                                        }}
                                      >
                                        <Box
                                          component="img"
                                          src="/yellow_book.jpg"
                                          alt="Yellow_Book"
                                          sx={{
                                            width: "40%",
                                            maxWidth: {
                                              xs: 150,
                                            },
                                            borderRadius: 2,
                                            boxShadow: 3,
                                          }}
                                        />
                                        <Tooltip title="" placement="top">
                                          <IconButton
                                            sx={{ cursor: "pointer" }}
                                            className="text-dark"
                                            component="a"
                                            href="https://fssai.gov.in/flipbook.php?bookid=197#book2/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <VisibilityIcon />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="" placement="top">
                                          <IconButton
                                            sx={{ cursor: "pointer" }}
                                            className="text-dark"
                                            component="a"
                                            href="https://fssai.gov.in/upload/knowledge_hub/1472505b34b3e4c4741Yellow%20Book%20Level%201%20(4-7%20years).pdf"
                                            download
                                          >
                                            <DownloadIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    ) : index === 3 ? (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-around",
                                          alignItems: "center",
                                          margin: "0.8rem auto",
                                        }}
                                      >
                                        <Box
                                          component="img"
                                          src="/pink_book.jpg"
                                          alt="Pink_book"
                                          sx={{
                                            width: "40%",
                                            maxWidth: {
                                              xs: 300,
                                            },
                                            maxHeight: {
                                              xs: 150,
                                            },
                                            borderRadius: 2,
                                            boxShadow: 3,
                                          }}
                                        />
                                        <Tooltip title="" placement="top">
                                          <IconButton
                                            sx={{ cursor: "pointer" }}
                                            className="text-dark"
                                            component="a"
                                            href="https://www.fssai.gov.in/flipbook.php?bookid=348#book2/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <VisibilityIcon />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="" placement="top">
                                          <IconButton
                                            sx={{ cursor: "pointer" }}
                                            className="text-dark"
                                            component="a"
                                            href="https://fssai.gov.in/upload/knowledge_hub/999365e662fbb1af9aPink_Book_09_03_2020.pdf"
                                            download
                                          >
                                            <DownloadIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    ) : (
                                      ""
                                    )}
                                    <RadioGroup
                                      value={values?.[`Field${index}`] || ""}
                                      // Use the stored value or default to an empty string
                                      onChange={handleChange}
                                      name={`Field${index}`}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <FormControlLabel
                                        value={
                                          index === 0
                                            ? "0-4"
                                            : index === 1
                                            ? "0-2"
                                            : index === 2
                                            ? "0-5"
                                            : index === 3
                                            ? "0-2"
                                            : ""
                                        }
                                        control={<Radio />}
                                        label={
                                          index === 0
                                            ? "0-4"
                                            : index === 1
                                            ? "0-2"
                                            : index === 2
                                            ? "0-5"
                                            : index === 3
                                            ? "0-2"
                                            : ""
                                        }
                                        labelPlacement="bottom"
                                      />
                                      <FormControlLabel
                                        value={
                                          index === 0
                                            ? "5+"
                                            : index === 1
                                            ? "3+"
                                            : index === 2
                                            ? "6+"
                                            : index === 3
                                            ? "2+"
                                            : ""
                                        }
                                        control={<Radio />}
                                        label={
                                          index === 0
                                            ? "5+"
                                            : index === 1
                                            ? "3+"
                                            : index === 2
                                            ? "6+"
                                            : index === 3
                                            ? "2+"
                                            : ""
                                        }
                                        labelPlacement="bottom"
                                      />
                                      {errors[`Field${index}`] && (
                                        <Typography
                                          sx={{
                                            fontWeight: "bold",
                                            selfAlign: "start",
                                          }}
                                          color="error"
                                          variant="body2"
                                        >
                                          {errors[`Field${index}`]}
                                        </Typography>
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </>
                              );
                            }
                          )
                        : currentQuestion.length > 0 &&
                          currentQuestion[0].id === 2
                        ? currentQuestion[0].subQuestion.map(
                            (subQuestion, index) => {
                              return (
                                <>
                                  <FormControl
                                    key={index}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flexDirection: {
                                        xs: "column",
                                        sm: "row",
                                      },
                                      alignItems: "center",
                                      // alignContent: "center",
                                      borderBottom: {
                                        xs: "1px solid black",
                                        sm: "none",
                                      },
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {subQuestion}
                                    <RadioGroup
                                      value={values?.[`Field${index}`] || ""}
                                      // Use the stored value or default to an empty string
                                      onChange={handleChange}
                                      name={`Field${index}`}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <FormControlLabel
                                        value="No"
                                        control={<Radio />}
                                        label="No"
                                        labelPlacement="bottom"
                                      />
                                      <FormControlLabel
                                        value="Yes"
                                        control={<Radio />}
                                        label="Yes"
                                        labelPlacement="bottom"
                                      />
                                      <Tooltip title="upload" placement="top">
                                        <IconButton
                                          sx={{
                                            cursor:
                                              values?.[`Field${index}`] ===
                                              "Yes"
                                                ? "pointer"
                                                : "not-allowed",
                                          }}
                                          className="text-dark"
                                          component="label"
                                        >
                                          <input
                                            type="file"
                                            hidden
                                            disabled={
                                              values?.[`Field${index}`] ===
                                              "Yes"
                                                ? false
                                                : true
                                            }
                                          />
                                          <UploadIcon />
                                        </IconButton>
                                      </Tooltip>
                                      {errors[`Field${index}`] && (
                                        <Typography
                                          sx={{ fontWeight: "bold" }}
                                          color="error"
                                          variant="body2"
                                        >
                                          {errors[`Field${index}`]}
                                        </Typography>
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </>
                              );
                            }
                          )
                        : currentQuestion.length > 0 &&
                          currentQuestion[0].id === 3
                        ? currentQuestion[0].subQuestion.map(
                            (subQuestion, index) => {
                              return (
                                <>
                                  <FormControl
                                    key={index}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flexDirection: {
                                        xs: "column",
                                        sm: "row",
                                      },
                                      alignItems: "center",
                                      // alignContent: "center",
                                      borderBottom: {
                                        xs: "1px solid black",
                                        sm: "none",
                                      },
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {subQuestion}
                                    <RadioGroup
                                      value={values?.[`Field${index}`] || ""}
                                      // Use the stored value or default to an empty string
                                      onChange={handleChange}
                                      name={`Field${index}`}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <FormControlLabel
                                        value="No"
                                        control={<Radio />}
                                        label="0"
                                        labelPlacement="bottom"
                                      />
                                      <FormControlLabel
                                        value="1"
                                        control={<Radio />}
                                        label="1"
                                        labelPlacement="bottom"
                                      />
                                      <FormControlLabel
                                        value="2"
                                        control={<Radio />}
                                        label="2"
                                        labelPlacement="bottom"
                                      />
                                      <Tooltip title="upload" placement="top">
                                        <IconButton
                                          sx={{
                                            cursor:
                                              values?.[`Field${index}`] ===
                                                "1" ||
                                              values?.[`Field${index}`] === "2"
                                                ? "pointer"
                                                : "not-allowed",
                                          }}
                                          className="text-dark"
                                          component="label"
                                        >
                                          <input
                                            type="file"
                                            hidden
                                            disabled={
                                              values?.[`Field${index}`] ===
                                                "1" ||
                                              values?.[`Field${index}`] === "2"
                                                ? false
                                                : true
                                            }
                                          />
                                          <UploadIcon />
                                        </IconButton>
                                      </Tooltip>
                                      {errors[`Field${index}`] && (
                                        <Typography
                                          sx={{ fontWeight: "bold" }}
                                          color="error"
                                          variant="body2"
                                        >
                                          {errors[`Field${index}`]}
                                        </Typography>
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </>
                              );
                            }
                          )
                        : currentQuestion.length > 0 &&
                          currentQuestion[0].id === 4
                        ? currentQuestion[0].subQuestion.map(
                            (subQuestion, index) => {
                              return (
                                <>
                                  <FormControl
                                    key={index}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flexDirection: {
                                        xs: "column",
                                        sm: "row",
                                      },
                                      alignItems: "center",
                                      // alignContent: "center",
                                      borderBottom: {
                                        xs: "1px solid black",
                                        sm: "none",
                                      },
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {subQuestion}
                                    <RadioGroup
                                      value={values?.[`Field${index}`] || ""}
                                      // Use the stored value or default to an empty string
                                      onChange={handleChange}
                                      name={`Field${index}`}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <FormControlLabel
                                        value="Yes"
                                        control={<Radio />}
                                        label="Yes"
                                        labelPlacement="bottom"
                                      />
                                      <FormControlLabel
                                        value="No"
                                        control={<Radio />}
                                        label="No"
                                        labelPlacement="bottom"
                                      />

                                      <Tooltip title="upload" placement="top">
                                        <IconButton
                                          sx={{
                                            cursor:
                                              values?.[`Field${index}`] ===
                                              "Yes"
                                                ? "pointer"
                                                : "not-allowed",
                                          }}
                                          className="text-dark"
                                          component="label"
                                        >
                                          <input
                                            type="file"
                                            hidden
                                            disabled={
                                              values?.[`Field${index}`] ===
                                              "Yes"
                                                ? false
                                                : true
                                            }
                                          />
                                          <UploadIcon />
                                        </IconButton>
                                      </Tooltip>
                                      {errors[`Field${index}`] && (
                                        <Typography
                                          sx={{ fontWeight: "bold" }}
                                          color="error"
                                          variant="body2"
                                        >
                                          {errors[`Field${index}`]}
                                        </Typography>
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </>
                              );
                            }
                          )
                        : currentQuestion.length > 0 &&
                          currentQuestion[0].id === 5
                        ? currentQuestion[0].subQuestion.map(
                            (subQuestion, index) => {
                              return (
                                <>
                                  <FormControl
                                    key={index}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flexDirection: {
                                        xs: "column",
                                        sm: "row",
                                      },
                                      alignItems: "center",
                                      // alignContent: "center",
                                      borderBottom: {
                                        xs: "1px solid black",
                                        sm: "none",
                                      },
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {subQuestion}
                                    <RadioGroup
                                      value={values?.[`Field${index}`] || ""}
                                      // Use the stored value or default to an empty string
                                      onChange={handleChange}
                                      name={`Field${index}`}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <FormControlLabel
                                        value="Yes"
                                        control={<Radio />}
                                        label="Yes"
                                        labelPlacement="bottom"
                                      />
                                      <FormControlLabel
                                        value="No"
                                        control={<Radio />}
                                        label="No"
                                        labelPlacement="bottom"
                                      />

                                      <Tooltip title="upload" placement="top">
                                        <IconButton
                                          sx={{
                                            cursor:
                                              values?.[`Field${index}`] ===
                                              "Yes"
                                                ? "pointer"
                                                : "not-allowed",
                                          }}
                                          className="text-dark"
                                          component="label"
                                        >
                                          <input
                                            type="file"
                                            hidden
                                            disabled={
                                              values?.[`Field${index}`] ===
                                              "Yes"
                                                ? false
                                                : true
                                            }
                                          />
                                          <UploadIcon />
                                        </IconButton>
                                      </Tooltip>
                                      {errors[`Field${index}`] && (
                                        <Typography
                                          sx={{ fontWeight: "bold" }}
                                          color="error"
                                          variant="body2"
                                        >
                                          {errors[`Field${index}`]}
                                        </Typography>
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </>
                              );
                            }
                          )
                        : currentQuestion.length > 0 &&
                          currentQuestion[0].id === 6
                        ? currentQuestion[0].subQuestion.map(
                            (subQuestion, index) => {
                              return (
                                <>
                                  <FormControl
                                    key={index}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flexDirection: {
                                        xs: "column",
                                        sm: "row",
                                      },
                                      alignItems: "center",
                                      // alignContent: "center",
                                      borderBottom: {
                                        xs: "1px solid black",
                                        sm: "none",
                                      },
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {subQuestion}
                                    <RadioGroup
                                      value={values?.[`Field${index}`] || ""}
                                      // Use the stored value or default to an empty string
                                      onChange={handleChange}
                                      name={`Field${index}`}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <FormControlLabel
                                        value="Yes"
                                        control={<Radio />}
                                        label="Yes"
                                        labelPlacement="bottom"
                                      />
                                      <FormControlLabel
                                        value="No"
                                        control={<Radio />}
                                        label="No"
                                        labelPlacement="bottom"
                                      />

                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DemoContainer
                                          components={["DateField"]}
                                        >
                                          <DateField
                                            disabled={
                                              values[`Field${index}`] ===
                                                "Yes" ||
                                              values[`Field${index}`] === "Both"
                                                ? false
                                                : true
                                            }
                                            label="Share certified monitoring schedule"
                                          />
                                        </DemoContainer>
                                      </LocalizationProvider>

                                      {errors[`Field${index}`] && (
                                        <Typography
                                          sx={{ fontWeight: "bold" }}
                                          color="error"
                                          variant="body2"
                                        >
                                          {errors[`Field${index}`]}
                                        </Typography>
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </>
                              );
                            }
                          )
                        : currentQuestion.length > 0 &&
                          currentQuestion[0].id === 7
                        ? currentQuestion[0].subQuestion.map(
                            (subQuestion, index) => {
                              return (
                                <>
                                  <FormControl
                                    key={index}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flexDirection: {
                                        xs: "column",
                                        sm: "row",
                                      },
                                      alignItems: "center",
                                      // alignContent: "center",
                                      borderBottom: {
                                        xs: "1px solid black",
                                        sm: "none",
                                      },
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {subQuestion}
                                    <RadioGroup
                                      value={values?.[`Field${index}`] || ""}
                                      // Use the stored value or default to an empty string
                                      onChange={handleChange}
                                      name={`Field${index}`}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <FormControlLabel
                                        value="Yes"
                                        control={<Radio />}
                                        label="Yes"
                                        labelPlacement="bottom"
                                      />
                                      <FormControlLabel
                                        value="No"
                                        control={<Radio />}
                                        label="No"
                                        labelPlacement="bottom"
                                      />

                                      <Box
                                        component="a"
                                        href={currentQuestion[0].fssaiLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                          textDecoration: "none",
                                          color: "primary.main",
                                          fontSize: "1rem",
                                          padding: "2px",
                                          border: "1px solid",
                                          borderColor: "primary.main",
                                          borderRadius: 1,
                                          "&:hover": {
                                            backgroundColor: "primary.light",
                                            color: "white",
                                          },
                                        }}
                                      >
                                        Visit Fssai
                                      </Box>
                                      {errors[`Field${index}`] && (
                                        <Typography
                                          sx={{ fontWeight: "bold" }}
                                          color="error"
                                          variant="body2"
                                        >
                                          {errors[`Field${index}`]}
                                        </Typography>
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </>
                              );
                            }
                          )
                        : currentQuestion.length > 0 &&
                          currentQuestion[0].id === 8
                        ? currentQuestion[0].subQuestion.map(
                            (subQuestion, index) => {
                              return (
                                <>
                                  <FormControl
                                    key={index}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flexDirection: {
                                        xs: "column",
                                        sm: "row",
                                      },
                                      alignItems: "center",
                                      // alignContent: "center",
                                      borderBottom: {
                                        xs: "1px solid black",
                                        sm: "none",
                                      },
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {subQuestion}
                                    <RadioGroup
                                      value={values?.[`Field${index}`] || ""}
                                      // Use the stored value or default to an empty string
                                      onChange={handleChange}
                                      name={`Field${index}`}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <FormControlLabel
                                        value="Yes"
                                        control={<Radio />}
                                        label="Yes"
                                        labelPlacement="bottom"
                                      />
                                      <FormControlLabel
                                        value="No"
                                        control={<Radio />}
                                        label="No"
                                        labelPlacement="bottom"
                                      />

                                      <Tooltip title="upload" placement="top">
                                        <IconButton
                                          sx={{
                                            cursor:
                                              values?.[`Field${index}`] ===
                                              "Yes"
                                                ? "pointer"
                                                : "not-allowed",
                                          }}
                                          className="text-dark"
                                          component="label"
                                        >
                                          <input
                                            type="file"
                                            hidden
                                            disabled={
                                              values?.[`Field${index}`] ===
                                              "Yes"
                                                ? false
                                                : true
                                            }
                                          />
                                          <UploadIcon />
                                        </IconButton>
                                      </Tooltip>
                                      {errors[`Field${index}`] && (
                                        <Typography
                                          sx={{ fontWeight: "bold" }}
                                          color="error"
                                          variant="body2"
                                        >
                                          {errors[`Field${index}`]}
                                        </Typography>
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </>
                              );
                            }
                          )
                        : currentQuestion.length > 0 &&
                          currentQuestion[0].id === 9
                        ? currentQuestion[0].subQuestion.map(
                            (subQuestion, index) => {
                              return (
                                <>
                                  <FormControl
                                    key={index}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flexDirection: {
                                        xs: "column",
                                        sm: "row",
                                      },
                                      alignItems: "center",
                                      // alignContent: "center",
                                      borderBottom: {
                                        xs: "1px solid black",
                                        sm: "none",
                                      },
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {subQuestion}
                                    <RadioGroup
                                      value={values?.[`Field${index}`] || ""}
                                      // Use the stored value or default to an empty string
                                      onChange={handleChange}
                                      name={`Field${index}`}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <FormControlLabel
                                        value="Yes"
                                        control={<Radio />}
                                        label="Yes"
                                        labelPlacement="bottom"
                                      />
                                      <FormControlLabel
                                        value="No"
                                        control={<Radio />}
                                        label="No"
                                        labelPlacement="bottom"
                                      />

                                      <Tooltip title="upload" placement="top">
                                        <IconButton
                                          sx={{
                                            cursor:
                                              values?.[`Field${index}`] ===
                                              "Yes"
                                                ? "pointer"
                                                : "not-allowed",
                                          }}
                                          className="text-dark"
                                          component="label"
                                        >
                                          <input
                                            type="file"
                                            hidden
                                            disabled={
                                              values?.[`Field${index}`] ===
                                              "Yes"
                                                ? false
                                                : true
                                            }
                                          />
                                          <UploadIcon />
                                        </IconButton>
                                      </Tooltip>
                                      {errors[`Field${index}`] && (
                                        <Typography
                                          sx={{ fontWeight: "bold" }}
                                          color="error"
                                          variant="body2"
                                        >
                                          {errors[`Field${index}`]}
                                        </Typography>
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </>
                              );
                            }
                          )
                        : ""}
                    </Box>
                    {}
                    {description && (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          More Information click here
                        </AccordionSummary>
                        <AccordionDetails>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `${advisoryNote}`,
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>
                    )}

                    {currentQuestion[0]?.id === 3 ? (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          More Information click here
                        </AccordionSummary>
                        <AccordionDetails>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `${currentQuestion[0]?.additionalInfo}`,
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      ""
                    )}
                    {currentQuestion[0]?.id === 7 ? (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          More Information click here
                        </AccordionSummary>
                        <AccordionDetails>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `${currentQuestion[0]?.additionalInfo}`,
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      ""
                    )}
                    <Box
                      sx={{
                        position: "relative",
                        marginBottom: "0.8rem",
                      }}
                      id="drop-area"
                    ></Box>
                  </Box>

                  <Box
                    sx={{
                      borderRadius: "0.4rem",
                      padding: "1rem 0rem 0rem 0rem",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: 2,
                      "@media(max-width:900px)": {
                        flexDirection: "column",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Box>
                        {/* Button Container */}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingTop: "0.6rem",
                            gap: "10px",
                          }}
                        >
                          {/* Later Button */}
                          <Button
                          // onClick={(e) => {
                          //   populateTempProgressPrevious(e);
                          //   setSelectedValues({});
                          //   setDescription(false);
                          // }}
                          // disabled={tempProgress.length === 1 ? true : false}
                          // variant="contained"
                          // sx={{
                          //   background: "gray",
                          //   borderRadius: "0.8rem",
                          //   cursor:
                          //     tempProgress.length === 1 ? "not-allowed" : "",
                          // }}
                          >
                            Previous
                          </Button>
                          {/* Submit Button */}
                          <Button
                            // onClick={(e) => {
                            //   handleSubmit(e);
                            //   populateTempProgress(e);
                            //   setSelectedValues({});
                            //   setDescription(false);
                            // }}
                            variant="contained"
                            type="submit"
                            // onClick={nextQuestion}
                            sx={{
                              background: "#2356fe",
                              borderRadius: "0.8rem",
                            }}
                          >
                            Next
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                    {/* Right Pane */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                        "@media(max-width:900px)": {
                          alignItems: "flex-start",
                          gap: 2,
                        },
                      }}
                    ></Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
}

export default RetakeGovtWithCanteen;
