import React, { useState } from "react";

const WasteSegregationQuestion = () => {
  const questionData = {
    id: 56,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    text: "Tick the categories into which waste is segregated into your school:",
    options: [
      { text: "Dry waste", selected: false },
      { text: "Wet waste (Biodegradable)", selected: false },
      { text: "E-waste", selected: false },
      { text: "Hazardous waste from the labs", selected: false },
      { text: "Chemical waste", selected: false },
      { text: "Paints", selected: false },
      { text: "Sanitary waste", selected: false },
      { text: "Garden waste", selected: false },
      { text: "Miscellaneous", selected: false },
    ],
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Waste4",
    media_type: "",
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  };

  const [selectedOptions, setSelectedOptions] = useState(
    questionData.options.map((option) => ({
      ...option,
      selected: false,
    }))
  );

  const handleCheckboxChange = (index) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions[index].selected = !updatedOptions[index].selected;
    setSelectedOptions(updatedOptions);
  };

  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      fontSize: "14px",
      lineHeight: "1.6",
      margin: "20px 40px",
      color: "#333",
    },
    questionTitle: {
      fontSize: "16px",
      marginBottom: "16px",
      fontWeight: "bold",
    },
    section: {
      marginBottom: "20px",
      marginLeft: "60px",
    },
    label: {
      fontSize: "14px",
      marginBottom: "8px",
      display: "block",
    },
    input: {
      marginRight: "10px",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.questionTitle}>Q5. {questionData.text}</h1>
      <form>
        {selectedOptions.map((option, index) => (
          <div key={index} style={styles.section}>
            <label style={styles.label}>
              <input
                type="checkbox"
                checked={option.selected}
                onChange={() => handleCheckboxChange(index)}
                style={styles.input}
              />
              {option.text}
            </label>
          </div>
        ))}
      </form>
    </div>
  );
};

export default WasteSegregationQuestion;
