import React, { useState } from "react";

const AwarenessCampaignQuestion = () => {
  // Data from the array
  const questionData = {
    id: 50,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    text: "Are awareness campaigns conducted for conservation of water in your school?",
    options: [
      { text: "Yes", score: "1", correct: true },
      { text: "No", score: "0", correct: false },
    ],
  };

  // State to handle selected option
  const [selectedOption, setSelectedOption] = useState("");

  // Handler for changing the selected option
  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  // CSS Styles for Horizontal Radio Buttons
  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    lineHeight: "1.6",
    margin: "20px 40px",
    color: "#333",
  };

  const questionTitleStyle = {
    fontSize: "16px",
    marginBottom: "16px",
    fontWeight: "bold",
  };

  const inputGroupStyle = {
    display: "flex",
    alignItems: "center",
    gap: "20px", // Space between the radio buttons
    marginTop: "10px",
    marginLeft: "60px", // Adjust left margin for better alignment
  };

  const labelStyle = {
    fontSize: "14px",
  };

  const inputStyle = {
    marginRight: "8px",
  };

  return (
    <div style={containerStyle}>
      <h1 style={questionTitleStyle}>Q20. {questionData.text}</h1>
      <div style={inputGroupStyle}>
        {questionData.options.map((option, index) => (
          <label key={index} style={labelStyle}>
            <input
              type="radio"
              name="awarenessCampaign"
              value={option.text}
              checked={selectedOption === option.text}
              onChange={() => handleOptionChange(option.text)}
              style={inputStyle}
            />
            {option.text}
          </label>
        ))}
      </div>
    </div>
  );
};

export default AwarenessCampaignQuestion;
