import React, { useState } from "react";

const IrrigationMethodsQuestion = () => {
  const questionData = {
    id: 43,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    text: "Q13. Which of the following methods of irrigation is used for watering of grass and plants in the school?",
    options: [
      { text: "Manual watering using a garden hose pipe", score: "1", correct: true },
      { text: "Sprinkler system with long range sprinklers", score: "2", correct: false },
      { text: "Sprinkler system with short range sprinklers", score: "3", correct: false },
      { text: "Drip irrigation system", score: "4", correct: false },
    ],
  };

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [otherType, setOtherType] = useState("");

  const handleOptionChange = (value) => {
    setSelectedOptions((prev) => {
      if (prev.includes(value)) {
        return prev.filter((option) => option !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  const handleOtherTypeChange = (event) => {
    setOtherType(event.target.value);
  };

  // Updated styles to match WaterSourceQuestion's style
  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      fontSize: "14px",
      lineHeight: "1.6",
      margin: "20px 40px", // Consistent margin as per WaterSourceQuestion
      color: "#333",
    },
    questionTitle: {
      fontSize: "16px",
      marginBottom: "16px",
      fontWeight: "bold",
    },
    section: {
      marginBottom: "20px",
      marginLeft: "60px", // Adjusted margin left for consistency
    },
    label: {
      fontSize: "14px",
      marginBottom: "8px",
      display: "block",
    },
    inputGroup: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      marginTop: "6px",
    },
    input: {
      marginRight: "6px",
    },
    textInput: {
      fontSize: "14px",
      padding: "8px",
      marginTop: "8px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      width: "100%",
      boxSizing: "border-box",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.questionTitle}>{questionData.text}</h1>
      <form>
        {questionData.options.map((option, index) => (
          <div key={index} style={styles.section}>
            <label style={styles.label}>
              <input
                type="checkbox"
                name="irrigationMethod"
                value={option.text}
                checked={selectedOptions.includes(option.text)}
                onChange={() => handleOptionChange(option.text)}
                style={styles.input}
              />
              {option.text}
            </label>
          </div>
        ))}
        <div style={styles.section}>
          <label style={styles.label}>
            <input
              type="checkbox"
              name="otherType"
              value="Any other type of system"
              checked={selectedOptions.includes("Any other type of system")}
              onChange={() => handleOptionChange("Any other type of system")}
              style={styles.input}
            />
            Any other type of system
            {selectedOptions.includes("Any other type of system") && (
              <input
                type="text"
                placeholder="Please specify"
                value={otherType}
                onChange={handleOtherTypeChange}
                style={styles.textInput}
              />
            )}
          </label>
        </div>
      </form>
    </div>
  );
};

export default IrrigationMethodsQuestion;
