import { marginLeft } from "@xstyled/styled-components";
import React, { useState } from "react";

const GreenSpacesQuestion = () => {
  const questionData = {
    id: 27,
    text: "Is there a possibility/ scope for converting rooftops and other concrete/barren spaces into green areas?",
    options: [
      { text: "Yes", score: "1", correct: true },
      { text: "No", score: "0", correct: false },
    ],
  };

  const [selectedOption, setSelectedOption] = useState("");

  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "14px", // Adjusted to match WeedRemovalProcedure font size
    lineHeight: "1.6",
    margin: "20px 40px", // Adjusted margin to match WeedRemovalProcedure component
    color: "#333",
  };

  const sectionStyle = {
    marginBottom: "20px", // Adjusted margin to match WeedRemovalProcedure component
    marginLeft: "40px"
  };

  const inputStyle = {
    marginRight: "10px", // Space between radio button and label
  };

  const questionTitleStyle = {
    fontSize: "16px", // Consistent with WeedRemovalProcedure component
    marginBottom: "16px", // Margin to match WeedRemovalProcedure
    fontWeight: "bold", // Consistent with WeedRemovalProcedure component
  };

  const olStyle = {
    paddingLeft: "20px", // Padding for ordered list
  };

  // Style for aligning the radio buttons horizontally
  const inputGroupStyle = {
    display: "flex",
    alignItems: "center",
    gap: "10px", // Space between Yes and No options
    marginTop: "6px",
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  return (
    <div style={containerStyle}>
      <h1 style={questionTitleStyle}>
        Q8. {questionData.text}
      </h1>
      <ol style={olStyle}>
        <li style={sectionStyle}>
          <div style={inputGroupStyle}>
            {questionData.options.map((option, index) => (
              <label key={index} style={{ fontSize: "14px" }}>
                <input
                  type="radio"
                  name="greenSpaces"
                  value={option.text}
                  checked={selectedOption === option.text}
                  onChange={() => handleOptionChange(option.text)}
                  style={inputStyle}
                />
                {option.text}
              </label>
            ))}
          </div>
        </li>
      </ol>
    </div>
  );
};

export default GreenSpacesQuestion;
