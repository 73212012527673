import React, { useState } from "react";

const FertilizerQuestion = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const questionData = {
    text: "What type of manure/fertilizers are used for plants?",
    options: [
      { text: "Only Compost (prepared at school)", score: "1", correct: true },
      { text: "Mostly Chemical fertilizers", score: "2", correct: true },
      { text: "Organic compost purchased", score: "3", correct: false },
    ],
  };

  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      fontSize: "14px",
      lineHeight: "1.6",
      margin: "20px 40px",
      color: "#333",
    },
    heading: {
      fontSize: "16px",
      marginBottom: "16px",
      fontWeight: "bold",
    },
    inputGroup: {
      display: "flex", // Align options horizontally
      gap: "20px", // Add space between radio button options
      marginTop: "10px",
      marginLeft: "60px", // Align options with heading
    },
    label: {
      display: "flex",
      alignItems: "center",
      fontSize: "14px",
      cursor: "pointer",
    },
    input: {
      marginRight: "8px", // Space between radio button and label text
    },
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Q5. {questionData.text}</h1>
      <div style={styles.inputGroup}>
        {questionData.options.map((option, index) => (
          <label key={index} style={styles.label}>
            <input
              type="radio"
              name="fertilizer"
              value={option.text}
              checked={selectedOption === option.text}
              onChange={() => handleOptionChange(option.text)}
              style={styles.input}
            />
            {option.text}
          </label>
        ))}
      </div>
    </div>
  );
};

export default FertilizerQuestion;
