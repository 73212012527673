import React, { useState } from "react";

const TransportPrograms = () => {
  const [showProgramDetails, setShowProgramDetails] = useState(false);
  const [programs, setPrograms] = useState([
    { program: "", targetGroup: "", frequency: "" },
    { program: "", targetGroup: "", frequency: "" },
    { program: "", targetGroup: "", frequency: "" },
  ]);

  const handleOptionChange = (e) => {
    setShowProgramDetails(e.target.value === "Yes");
  };

  const handleProgramChange = (index, field, value) => {
    const updatedPrograms = [...programs];
    updatedPrograms[index][field] = value;
    setPrograms(updatedPrograms);
  };

  return (
    <div>
      <h1>Q9. Transportation and Sustainability Programs</h1>

      {/* Question 1 */}
      <h2>
        01. A) Does the school organize any programs to encourage the use of
        public transport, carpooling, etc. and to sensitize regarding the
        minimum use of non-renewable fuels?
      </h2>
      <label>
        <input
          type="radio"
          name="programsEncourage"
          value="Yes"
          onChange={handleOptionChange}
        />{" "}
        Yes
      </label>
      <br />
      <label>
        <input
          type="radio"
          name="programsEncourage"
          value="No"
          onChange={handleOptionChange}
        />{" "}
        No
      </label>

      {/* Question 2 (if Yes) */}
      {showProgramDetails && (
        <div id="programDetails">
          <h2>
            02. If yes, what is the nature of these programs and how frequently
            are they organized?
          </h2>
          <table border="1">
            <thead>
              <tr>
                <th>Program</th>
                <th>Target Group</th>
                <th>Frequency</th>
              </tr>
            </thead>
            <tbody>
              {programs.map((row, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="text"
                      value={row.program}
                      onChange={(e) =>
                        handleProgramChange(index, "program", e.target.value)
                      }
                      placeholder="Enter program name"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={row.targetGroup}
                      onChange={(e) =>
                        handleProgramChange(index, "targetGroup", e.target.value)
                      }
                      placeholder="Enter target group"
                    />
                  </td>
                  <td>
                    <select
                      value={row.frequency}
                      onChange={(e) =>
                        handleProgramChange(index, "frequency", e.target.value)
                      }
                    >
                      <option value="">Select frequency</option>
                      <option value="Regularly">Regularly</option>
                      <option value="Frequently">Frequently</option>
                      <option value="Occasionally">Occasionally</option>
                      <option value="Rarely">Rarely</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TransportPrograms;
