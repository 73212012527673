import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

// Styles for the PDF
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 12,
    lineHeight: 1.5,
  },
  logo: {
    width: 90,
    height: 35,
  },
logo1: {
    width: 44,
    height: 50,
    alignItems: "center",
  },
  logosContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  heading: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 10,
    fontWeight: "bold",
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
  },
  boldText: {
    fontWeight: "bold",
  },
  footer: {
    marginTop: 10,
    textAlign: "start",
  },
  signature: {
    marginTop: 10,
  },
  stamp: {
    marginTop: 10,
    width: 90,
    height: 30,
  },
});

// Helper function to format the dynamic content
const formatText = (answers) => {
  let text = "";

  if (
    answers[0].Field0 !== "0-4" ||
    answers[0].Field1 !== "0-2" ||
    answers[0].Field2 !== "0-5" ||
    answers[0].Field3 !== "0-2"
  ) {
    text += `The School has supplemented its ongoing curriculam with the following "Eat Right" resource materials :-\n`;
  }

  if (answers[0].Field0 !== "0-4") {
    text += `• The Yellow Book in ${answers[0].Field0} classes.\n`;
  }
  if (answers[0].Field1 !== "0-2") {
    text += `• The Activity Book in ${answers[0].Field1} classes.\n`;
  }
  if (answers[0].Field2 !== "0-5") {
    text += `• The Food Safety Guidebook For Teacher in ${answers[0].Field2} classes.\n`;
  }
  if (answers[0].Field3 !== "0-2") {
    text += `• The Pink Book in ${answers[0].Field3} classes.\n`;
  }

  if (
    answers[1].Field0 === "Yes" ||
    answers[1].Field1 === "Yes" ||
    answers[1].Field2 === "Yes" ||
    answers[1].Field3 === "Yes" ||
    answers[1].Field4 === "Yes"
  ) {
    text += `\n As a part of the initiative, the school engaged students in various co-curricular activities including :- \n`;
  }

  if (answers[1].Field0 === "Yes") {
    text += `• School assembilies.\n`;
  }
  if (answers[1].Field1 === "Yes") {
    text += `• Poster-making activities.\n`;
  }
  if (answers[1].Field2 === "Yes") {
    text += `• Debates.\n`;
  }
  if (answers[1].Field3 === "Yes") {
    text += `• Prabhat Pheris.\n`;
  }
  if (answers[1].Field4 === "Yes") {
    text += `• Workshops.\n`;
  }

  if (answers[2].Field0 !== "No") {
    text += `\nThe school has also appointed ${answers[2].Field0} Health and wellness ambassador which are certified through online certification program by fssai. \n `;
  }

  if (answers[3].Field0 !== "No") {
    text +=
      "\nAdministrative actions such as issuing advisory notes to parents or sharing a menu plan have been undertaken to encourage the consumption of fruits, healthy local foods, and seasonal snacks. \n";
  }

  if (
    answers[4].Field0 !== "No" ||
    answers[4].Field1 !== "No" ||
    answers[4].Field2 !== "No" ||
    answers[4].Field3 !== "No"
  ) {
    text +=
      "\nThe school is aligned with the wash program/The Swachh Vidyalaya Scheme and has ensured the following :-\n ";
  }

  if (answers[4].Field0 !== "No") {
    text += `• Available of portable drinking water.\n`;
  }
  if (answers[4].Field1 !== "No") {
    text += "• Provision of dustbins and regular waste disposal.\n";
  }
  if (answers[4].Field2 !== "No") {
    text +=
      "• Availability of handwashing facilities, including soap and water.\n";
  }
  if (answers[4].Field3 !== "No") {
    text += "• Availability of operational toilets.\n";
  }
  if (answers[5].Field0 !== "No" || answers[5].Field1 !== "No") {
    text +=
      "\n Encoured students to consume the following as per the `MOHFW(Ministry Of Health And Family Welfare):-\n";
  }
  if (answers[5].Field0 !== "No") {
    text += `• IFA supplementation tablets.\n `;
  }
  if (answers[5].Field1 !== "No") {
    text += `• Deworming tablets.\n`;
  }

  if (answers[6].Field0 !== "No") {
    text +=
      "\n Licensing/Registration of canteens, kitchens, and food vendors with FSSAI has been implemented.";
  }

  if (answers[7].Field0 !== "No") {
    text +=
      "Hygiene rating of the canteens, kitchens, and food vendors has been assessed.";
  }
  if (answers[8].Field0 !== "No") {
    text +=
      "Fruits, healthy local and seasonal snacks (makhana, nuts, puffed rice, millets, etc.), healthy beverages (water, coconut water, lassi, nimbu paani, etc.), and green leafy vegetables have been included in the menu, Mess, Kitchen, and Canteen.";
  }

  return text;
};

// PDF Component
const MyDocument = ({ answers, date, headName }) => {
  const schoolDetails = JSON.parse(localStorage.getItem("formDetails") || "{}");
  const schoolName = schoolDetails?.NameOfTheSchool || "Our School";
  const nodalName = schoolDetails?.Name || "Our School";
  const formattedText = formatText(answers); // Preprocess the text before rendering

  return (
    <Document>
      <Page style={styles.page}>
        {/* Logos */}
        <View style={styles.logosContainer}>
          <Image
            src="https://beta.yuvamanthan.org/eatRightSchool.png"
            style={styles.logo}
          />
          {/* Title */}
          <Text style={styles.heading}>Affidavit</Text>

          <Image src="https://beta.yuvamanthan.org/fssai.png" style={styles.logo} />
        </View>

        <View style={styles.logosContainer}>
          <Image src="/dps_logo1.png" style={styles.logo1} />
          <Text style={styles.heading}>
            Eat Right India - School Initiative
          </Text>
          <Image src="https://beta.yuvamanthan.org/ym.png" style={styles.logo} />
        </View>

        {/* Content */}
        <Text style={styles.text}>
          This is to certify that {schoolName} has actively participated in the
          Eat Right India program, showcasing a commitment to health, hygiene,
          and nutrition education.{"\n"}
          {formattedText}
        </Text>
        {/* Self-Declaration */}
        <Text style={styles.text}>
          We hereby declare that all the information provided in this
          certificate is true, complete, and correct to the best of our
          knowledge and belief. We express our commitment to participating in
          the "Eat Right School" initiative and aspire to be recognized as an
          "Eat Right School."
        </Text>

        {/* Footer */}
        <View style={styles.footer}>
          <Text>Date: {date}</Text>
          <Text>Name: {nodalName}</Text>
          <Text style={styles.signature}>
            Signature (Head of the School): _______________________
          </Text>
          <Text style={styles.stamp}>School Stamp:</Text>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
