import React, { useState } from "react";

const WasteBinFrequency = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const options = [
    {
      text: "Daily",
      score: 1,
      correct: true,
    },
    {
      text: "Two times a day",
      score: 2,
      correct: false,
    },
    {
      text: "Weekly",
      score: 3,
      correct: false,
    },
    {
      text: "Any other",
      score: 4,
      correct: false,
    },
  ];

  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      fontSize: "14px",
      lineHeight: "1.6",
      margin: "20px 40px",
      color: "#333",
    },
    questionTitle: {
      fontSize: "16px",
      marginBottom: "16px",
      fontWeight: "bold",
    },
    section: {
      marginBottom: "20px",
      marginLeft: "60px",
    },
    inputGroup: {
      display: "flex",
      gap: "20px",
      marginTop: "10px",
    },
    input: {
      marginRight: "6px",
    },
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.questionTitle}>
        Q8. How frequently are dust/waste bins emptied?
      </h1>
      <div style={styles.section}>
        <div style={styles.inputGroup}>
          {options.map((option, index) => (
            <label key={index}>
              <input
                type="radio"
                name="binEmptyingFrequency"
                value={option.text}
                checked={selectedOption === option.text}
                onChange={handleChange}
                style={styles.input}
              />
              {option.text}
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WasteBinFrequency;
