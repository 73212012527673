import React, { useState } from 'react';

// The array containing all the options and related information
const questionArray = [
  {
    id: 63,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    text: "Q13. Paper waste",
    description: "Please tick mark the practices which your school encourages for encouraging the reduction in use of paper:",
    options: [
      {
        text: "a. Does your school encourage use of single side paper for printing and other purposes?",
        sub_options: [
          { text: "Yes", score: "1", correct: true },
          { text: "No", score: "0", correct: false },
        ],
      },
      {
        text: "b. Does your school administration practice double side printing and photocopy?",
        sub_options: [
          { text: "Yes", score: "1", correct: true },
          { text: "No", score: "0", correct: false },
        ],
      },
      {
        text: "c. Does your school discourage the use of paper decorations and other promotional materials?",
        sub_options: [
          { text: "Yes", score: "1", correct: true },
          { text: "No", score: "0", correct: false },
        ],
      },
      {
        text: "d. Does your school encourage use of digital/electronic version of assignments, reports, newsletters, etc.?",
        sub_options: [
          { text: "Yes", score: "1", correct: true },
          { text: "No", score: "0", correct: false },
        ],
      },
      {
        text: "e. Has your school encouraged/tried making Paper-Mache items?",
        sub_options: [
          { text: "Yes", score: "1", correct: true },
          { text: "No", score: "0", correct: false },
        ],
      },
    ],
  },
];

const WasteAuditForm = () => {
  const [answers, setAnswers] = useState({});

  const handleChange = (questionText, optionText) => {
    setAnswers({
      ...answers,
      [questionText]: optionText,
    });
  };

  // CSS Styles (adjusted for consistency with the original style)
  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    lineHeight: "1.6",
    margin: "20px 40px",
    color: "#333",
  };

  const questionTitleStyle = {
    fontSize: "16px",
    marginBottom: "16px",
    fontWeight: "bold",
  };

  const sectionStyle = {
    marginBottom: "20px",
    marginLeft: "60px", // Adjusted marginLeft to match the example
  };

  const labelStyle = {
    fontSize: "14px",
    marginBottom: "8px",
    display: "block",
  };

  const subOptionLabelStyle = {
    fontSize: "14px", // Ensuring sub-option font size is consistent
    marginBottom: "6px", // Reduced margin between sub-options
    display: "block",
  };

  const optionTextStyle = {
    fontSize: "14px", // Set font size for the main options
    marginBottom: "8px", // Adjust the margin between options
  };

  const inputStyle = {
    marginRight: "6px",
  };

  // Custom style for description with marginLeft 40px
  const descriptionStyle = {
    marginLeft: "40px", // Applying marginLeft 40px for description
    fontSize: "16px", // Ensuring font size consistency with other text
    lineHeight: "1.6", // Ensuring line height is consistent
    fontWeight: "bold",
  };

  // Horizontal alignment style for sub-options (radio buttons)
  const inputGroupStyle = {
    display: "flex",
    gap: "20px", // Space between radio buttons
    marginTop: "10px",
    marginLeft: "40px", // Added marginLeft for horizontal alignment
  };

  return (
    <div style={containerStyle}>
      {questionArray.map((question) => (
        <div key={question.id}>
          <h1 style={questionTitleStyle}>{question.text}</h1>
          <p style={descriptionStyle}>{question.description}</p> {/* Added marginLeft 40px here */}

          {question.options.map((option, index) => (
            <div key={index} style={sectionStyle}>
              <h2 style={optionTextStyle}>{option.text}</h2>
              <div style={inputGroupStyle}>
                {option.sub_options.map((subOption, idx) => (
                  <label key={idx} style={subOptionLabelStyle}>
                    <input
                      type="radio"
                      name={option.text}
                      value={subOption.text}
                      onChange={() => handleChange(option.text, subOption.text)}
                      checked={answers[option.text] === subOption.text}
                      style={inputStyle}
                    />
                    {subOption.text}
                  </label>
                ))}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default WasteAuditForm;
