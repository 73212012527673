import React, { useState } from "react";

// Array data representing the question and options
const questionData = {
  id: 73,
  category: "Transport",
  text: "Q8. Calculate the per person consumption of fuel and cost by dividing the liters consumed/amount spent by the number of persons in the school.",
  options: [
    {
      text: "01. Fuel consumed per person (Total fuel consumed / Number of persons in the school)",
    },
    {
      text: "02. Cost of transport per person (Excludes the use of personal car / public and other private transport)",
    },
  ],
};

const TransportQuestion = () => {
  const [fuelConsumed, setFuelConsumed] = useState("");
  const [numPersons, setNumPersons] = useState("");
  const [transportCost, setTransportCost] = useState("");
  const [fuelPerPerson, setFuelPerPerson] = useState(null);
  const [costPerPerson, setCostPerPerson] = useState(null);

  // Automatically calculate values when inputs change
  const calculateValues = () => {
    if (fuelConsumed && numPersons) {
      setFuelPerPerson((fuelConsumed / numPersons).toFixed(2));
    } else {
      setFuelPerPerson(null);
    }

    if (transportCost && numPersons) {
      setCostPerPerson((transportCost / numPersons).toFixed(2));
    } else {
      setCostPerPerson(null);
    }
  };

  // CSS Styles (standardized)
  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    lineHeight: "1.4",
    margin: "20px 40px",
    color: "#333",
  };

  const sectionStyle = {
    display: "flex", // Flex layout
    alignItems: "center", // Vertically align items
    marginBottom: "12px",
  };

  const inputStyle = {
    fontSize: "14px",
    padding: "6px",
    marginLeft: "10px", // Space between label and input
    width: "200px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  };

  const questionTitleStyle = {
    fontSize: "16px",
    marginBottom: "12px",
    fontWeight: "bold",
  };

  const labelStyle = {
    fontSize: "14px",
    width: "300px", // Fixed width for labels
    textAlign: "left",
    marginLeft: "60px",
  };

  const resultStyle = {
    fontSize: "14px",
    marginTop: "10px",
    color: "#555",
  };

  const optionStyle = {
    marginLeft: "60px", // Add marginLeft to options text
    fontSize: "14px",
    marginBottom: "12px",
    fontWeight: "bold"
  };

  return (
    <div style={containerStyle}>
      <h1 style={questionTitleStyle}>{questionData.text}</h1>

      {/* Fuel consumption per person */}
      <div>
        <h2 style={optionStyle}>{questionData.options[0].text}</h2>
        <div style={sectionStyle}>
          <label style={labelStyle}>Total fuel consumed (liters):</label>
          <input
            type="number"
            value={fuelConsumed}
            onChange={(e) => {
              setFuelConsumed(e.target.value);
              calculateValues();
            }}
            placeholder="Enter total fuel consumed"
            style={inputStyle}
          />
        </div>
        <div style={sectionStyle}>
          <label style={labelStyle}>Number of persons in the school:</label>
          <input
            type="number"
            value={numPersons}
            onChange={(e) => {
              setNumPersons(e.target.value);
              calculateValues();
            }}
            placeholder="Enter number of persons"
            style={inputStyle}
          />
        </div>
        <div style={resultStyle}>
          {fuelPerPerson !== null && (
            <p>
              Fuel consumed per person: <strong>{fuelPerPerson} liters</strong>
            </p>
          )}
        </div>
      </div>

      {/* Cost of transport per person */}
      <div>
        <h2 style={optionStyle}>{questionData.options[1].text}</h2>
        <div style={sectionStyle}>
          <label style={labelStyle}>Total transport cost:</label>
          <input
            type="number"
            value={transportCost}
            onChange={(e) => {
              setTransportCost(e.target.value);
              calculateValues();
            }}
            placeholder="Enter total transport cost"
            style={inputStyle}
          />
        </div>
        <div style={resultStyle}>
          {costPerPerson !== null && (
            <p>
              Cost of transport per person: <strong>{costPerPerson} units</strong>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransportQuestion;
