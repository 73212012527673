import React, { useState } from "react";

const ConcretePavementsQuestion = () => {
  const [selectedPercentage, setSelectedPercentage] = useState("");

  const handlePercentageChange = (percentage) => {
    setSelectedPercentage(percentage);
  };

  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      fontSize: "14px",
      lineHeight: "1.6",
      margin: "20px 40px",
      color: "#333",
    },
    heading: {
      fontSize: "16px",
      marginBottom: "16px",
      fontWeight: "bold",
    },
    inputGroup: {
      display: "flex",
      alignItems: "center",
      gap: "20px", // Space between radio button options
      marginTop: "10px",
      marginLeft: "60px", // Align with heading
    },
    label: {
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
    },
    input: {
      marginRight: "8px",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>
        Q2. Approximately what percentage of the passages, paths, and pavements in the school are made of concrete?
      </h1>
      <form>
        <div style={styles.inputGroup}>
          <label style={styles.label}>
            <input
              type="radio"
              name="concretePercentage"
              value="25%"
              checked={selectedPercentage === "25%"}
              onChange={() => handlePercentageChange("25%")}
              style={styles.input}
            />
            25%
          </label>
          <label style={styles.label}>
            <input
              type="radio"
              name="concretePercentage"
              value="50%-75%"
              checked={selectedPercentage === "50%-75%"}
              onChange={() => handlePercentageChange("50%-75%")}
              style={styles.input}
            />
            50%-75%
          </label>
          <label style={styles.label}>
            <input
              type="radio"
              name="concretePercentage"
              value="75-100%"
              checked={selectedPercentage === "75-100%"}
              onChange={() => handlePercentageChange("75-100%")}
              style={styles.input}
            />
            75-100%
          </label>
          <label style={styles.label}>
            <input
              type="radio"
              name="concretePercentage"
              value="Nearly All"
              checked={selectedPercentage === "Nearly All"}
              onChange={() => handlePercentageChange("Nearly All")}
              style={styles.input}
            />
            Nearly All
          </label>
        </div>
      </form>
    </div>
  );
};

export default ConcretePavementsQuestion;
