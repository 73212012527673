import React, { useState } from "react";

const KitchenCanteenWaste = () => {
  const [foodType, setFoodType] = useState("");
  const [cutleryType, setCutleryType] = useState("");

  const optionsFood = [
    { text: "Fresh food only", value: "Fresh food only", score: 1 },
    { text: "Packaged food only", value: "Packaged food only", score: 0 },
    { text: "Both fresh and packaged food", value: "Both fresh and packaged food", score: 0 },
  ];

  const optionsCutlery = [
    { text: "Steel/utensils", value: "Steel/utensils", score: 1 },
    { text: "Plastic plates & disposables", value: "Plastic plates & disposables", score: 0 },
    { text: "Wooden cutlery", value: "Wooden cutlery", score: 0 },
    { text: "Bring your own cutlery", value: "Bring your own cutlery", score: 0 },
    { text: "Any other", value: "Any other", score: 0 },
  ];

  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      fontSize: "14px",
      lineHeight: "1.6",
      margin: "20px 40px",
      color: "#333",
    },
    questionTitle: {
      fontSize: "16px",
      marginBottom: "16px",
      fontWeight: "bold",
    },
    sectionTitle: {
      fontSize: "14px",
      fontWeight: "bold",
      marginBottom: "12px",
      marginLeft: "60px"
    },
    section: {
      marginBottom: "20px",
      marginLeft: "60px",
    },
    inputGroup: {
      display: "flex",
      gap: "20px",
      marginTop: "10px",
    },
    input: {
      marginRight: "6px",
    },
  };

  const handleFoodTypeChange = (event) => {
    setFoodType(event.target.value);
  };

  const handleCutleryTypeChange = (event) => {
    setCutleryType(event.target.value);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.questionTitle}>Q7. Kitchen/Canteen waste</h1>

      <h2 style={styles.sectionTitle}>i) What kind of food is served in your canteen/kitchen?</h2>
      <div style={styles.section}>
        <div style={styles.inputGroup}>
          {optionsFood.map((option) => (
            <label key={option.value}>
              <input
                type="radio"
                name="foodType"
                value={option.value}
                checked={foodType === option.value}
                onChange={handleFoodTypeChange}
                style={styles.input}
              />
              {option.text}
            </label>
          ))}
        </div>
      </div>

      <h2 style={styles.sectionTitle}>ii) What kind of cutlery is used in your canteen/kitchen?</h2>
      <div style={styles.section}>
        <div style={styles.inputGroup}>
          {optionsCutlery.map((option) => (
            <label key={option.value}>
              <input
                type="radio"
                name="cutleryType"
                value={option.value}
                checked={cutleryType === option.value}
                onChange={handleCutleryTypeChange}
                style={styles.input}
              />
              {option.text}
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};

export default KitchenCanteenWaste;
