import React, { useState } from "react";

const StorageTankCleaningFrequency = () => {
  const questionData = {
    id: 41,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    text: "How often are storage tanks cleaned?",
    options: [
      { text: "Once a year", score: "1", correct: true },
      { text: "Twice a year", score: "2", correct: false },
      { text: "Quarterly", score: "3", correct: false },
      { text: "Any other periodicity?", score: "4", correct: false },
    ],
  };

  const [selectedOption, setSelectedOption] = useState("");
  const [otherFrequency, setOtherFrequency] = useState("");

  const handleOptionChange = (value) => {
    setSelectedOption(value);
    if (value !== "Any other periodicity?") {
      setOtherFrequency("");
    }
  };

  const handleOtherFrequencyChange = (event) => {
    setOtherFrequency(event.target.value);
  };

  // Updated CSS Styles
  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    lineHeight: "1.6",
    margin: "20px 40px",
    color: "#333",
  };

  const questionTitleStyle = {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "16px",
  };

  const inputGroupStyle = {
    display: "flex",
    flexDirection: "column", // Stack the radio buttons vertically
    marginTop: "10px",
    marginLeft: "60px", // Adjust left margin for alignment
  };

  const labelStyle = {
    fontSize: "14px",
    marginBottom: "8px", // Add some space between options
  };

  const inputStyle = {
    marginRight: "8px",
  };

  const textInputStyle = {
    marginLeft: "10px",
    padding: "5px",
    fontSize: "14px",
    width: "calc(100% - 120px)",
  };

  return (
    <div style={containerStyle}>
      <h1 style={questionTitleStyle}>Q11. {questionData.text}</h1>
      <div style={inputGroupStyle}>
        {questionData.options.map((option, index) => (
          <label key={index} style={labelStyle}>
            <input
              type="radio"
              name="tankCleaningFrequency"
              value={option.text}
              checked={selectedOption === option.text}
              onChange={() => handleOptionChange(option.text)}
              style={inputStyle}
            />
            {option.text}
            {option.text === "Any other periodicity?" &&
              selectedOption === "Any other periodicity?" && (
                <input
                  type="text"
                  value={otherFrequency}
                  onChange={handleOtherFrequencyChange}
                  placeholder="Specify here..."
                  style={textInputStyle}
                />
              )}
          </label>
        ))}
      </div>
    </div>
  );
};

export default StorageTankCleaningFrequency;
