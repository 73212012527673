import React, { useState } from "react";

const DryWasteSegregation = () => {
  const questionData = {
    id: 57,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    text: "Is dry waste segregated in your school?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Waste5",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: "0",
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  };

  const categories = [
    "Plastic and stationery waste",
    "Electronics",
    "Metal",
    "Thermocol",
    "Food packaging",
    "Tetrapack",
    "Wood",
    "Any other",
  ];

  const [responses, setResponses] = useState({
    dryWasteSegregation: "",
    categories: [],
  });

  const handleOptionChange = (questionKey, value) => {
    setResponses({
      ...responses,
      [questionKey]: value,
    });
  };

  const handleCategoryChange = (category, isChecked) => {
    const updatedCategories = isChecked
      ? [...responses.categories, category]
      : responses.categories.filter((item) => item !== category);

    setResponses({
      ...responses,
      categories: updatedCategories,
    });
  };

  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      fontSize: "14px",
      lineHeight: "1.6",
      margin: "20px 40px",
      color: "#333",
    },
    questionTitle: {
      fontSize: "16px",
      marginBottom: "16px",
      fontWeight: "bold",
    },
    inputGroup: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      marginTop: "10px",
      marginLeft: "60px",
    },
    checkboxOption: {
      display: "block",
      marginBottom: "8px",
      marginLeft: "60px",
    },
    input: {
      marginRight: "8px",
    },
    subQuestionTitle: {
      fontSize: "14px",
      marginBottom: "12px",
      fontWeight: "bold",
      marginLeft: "60px",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.questionTitle}>{`Q6. ${questionData.text}`}</h1>

      <div style={styles.inputGroup}>
        {questionData.options.map((option, index) => (
          <label key={index}>
            <input
              type="radio"
              name="dryWasteSegregation"
              value={option.text}
              checked={responses.dryWasteSegregation === option.text}
              onChange={() =>
                handleOptionChange("dryWasteSegregation", option.text)
              }
              style={styles.input}
            />
            {option.text}
          </label>
        ))}
      </div>

      {responses.dryWasteSegregation === "Yes" && (
        <div>
          <h2 style={styles.subQuestionTitle}>
            If yes, then in how many categories? (Dustbin & dumpsters)
          </h2>
          {categories.map((category, index) => (
            <label key={index} style={styles.checkboxOption}>
              <input
                type="checkbox"
                name="category"
                value={category}
                checked={responses.categories.includes(category)}
                onChange={(e) =>
                  handleCategoryChange(category, e.target.checked)
                }
                style={styles.input}
              />
              {category}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default DryWasteSegregation;
