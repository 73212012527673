import React, { useState } from "react";

const TalkInteractiveSessionQuestion = () => {
  const questionData = {
    id: 29,
    text: "Does your school organize any talk/interactive session to emphasize the interdependence of living creatures and encourage values and virtues of cooperation and cohabitation?",
    options: [
      { text: "Yes", score: "1", correct: true },
      { text: "No", score: "0", correct: false },
    ],
  };

  const [selectedOption, setSelectedOption] = useState("");

  // CSS Styles
  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    lineHeight: "1.6",
    margin: "20px 40px",
    color: "#333",
  };

  const questionTitleStyle = {
    fontSize: "16px",
    marginBottom: "16px",
    fontWeight: "bold",
  };

  const inputGroupStyle = {
    display: "flex",
    alignItems: "center",
    gap: "20px", // Spacing between Yes and No options
    marginTop: "8px",
    marginLeft: "60px"
  };

  const inputStyle = {
    marginRight: "6px",
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  return (
    <div style={containerStyle}>
      <h1 style={questionTitleStyle}>Q10. {questionData.text}</h1>
      <div style={inputGroupStyle}>
        {questionData.options.map((option, index) => (
          <label
            key={index}
            style={{ display: "flex", alignItems: "center", fontSize: "14px" }}
          >
            <input
              type="radio"
              name="talkInteractiveSession"
              value={option.text}
              checked={selectedOption === option.text}
              onChange={() => handleOptionChange(option.text)}
              style={inputStyle}
            />
            {option.text}
          </label>
        ))}
      </div>
    </div>
  );
};

export default TalkInteractiveSessionQuestion;
