export const complianceArray= [
            {
                "id": 3,
                "title": "NCPCR CHECKLIST ON CHILD SAFETY IN SCHOOLS",
                "thumbnail": "http://glcloud.in/uploads/safeinschool/public/65084541c0543.jpg",
                "description": "",
                "createdAt": "2023-09-04T12:13:38.000Z",
                "updatedAt": "2023-09-04T12:13:38.000Z"
            }
        
]