import React, { useState } from "react";

const NonAirConditionedSchoolQuestion = () => {
  const [openableWindows, setOpenableWindows] = useState("");
  const [ventilationSpaces, setVentilationSpaces] = useState([]);
  const [comfortableWithFans, setComfortableWithFans] = useState("");

  const handleVentilationChange = (space) => {
    setVentilationSpaces((prev) =>
      prev.includes(space) ? prev.filter((s) => s !== space) : [...prev, space]
    );
  };

  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    lineHeight: "1.6",
    margin: "20px 40px",
    color: "#333",
  };

  const headingStyle = {
    fontSize: "16px",
    marginBottom: "16px",
    fontWeight: "bold",
  };

  const sectionStyle = {
    marginBottom: "20px",
    marginLeft: "40px",
  };

  const labelStyle = {
    display: "inline-block",
    marginRight: "12px",
    fontSize: "14px",
  };

  const inputStyle = {
    marginRight: "6px",
  };

  const textareaStyle = {
    display: "block",
    width: "100%",
    maxWidth: "500px",
    marginTop: "8px",
    fontSize: "14px",
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    resize: "vertical",
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Q4. For Non-Air Conditioned School</h1>
      <ol style={{ paddingLeft: "20px" }}>
        {/* Question 1 */}
        <li style={sectionStyle}>
          <h2 style={headingStyle}>1. Does the school have openable windows?</h2>
          <label style={labelStyle}>
            <input
              type="radio"
              name="openableWindows"
              value="Yes"
              checked={openableWindows === "Yes"}
              onChange={() => setOpenableWindows("Yes")}
              style={inputStyle}
            />
            Yes
          </label>
          <label style={labelStyle}>
            <input
              type="radio"
              name="openableWindows"
              value="No"
              checked={openableWindows === "No"}
              onChange={() => setOpenableWindows("No")}
              style={inputStyle}
            />
            No
          </label>
        </li>

        {/* Question 2 */}
        <li style={sectionStyle}>
          <h2 style={headingStyle}>
            2. Do the windows allow natural/cross-ventilation in the:
          </h2>
          {["Classrooms", "Auditorium", "Multipurpose Halls", "Lab/s", "Office space/s"].map((space) => (
            <label key={space} style={{ ...labelStyle, display: "block" }}>
              <input
                type="checkbox"
                value={space}
                checked={ventilationSpaces.includes(space)}
                onChange={() => handleVentilationChange(space)}
                style={inputStyle}
              />
              {space}
            </label>
          ))}
          <label style={{ ...labelStyle, display: "block" }}>
            <input
              type="checkbox"
              value="Any other"
              checked={ventilationSpaces.includes("Any other")}
              onChange={() => handleVentilationChange("Any other")}
              style={inputStyle}
            />
            Any other (Specify):
          </label>
          {ventilationSpaces.includes("Any other") && (
            <textarea
              placeholder="Specify other spaces..."
              style={textareaStyle}
            />
          )}
        </li>

        {/* Question 3 */}
        <li style={sectionStyle}>
          <h2 style={headingStyle}>
            3. Are the occupied spaces comfortable with ceiling fans?
          </h2>
          <label style={labelStyle}>
            <input
              type="radio"
              name="comfortableWithFans"
              value="Yes"
              checked={comfortableWithFans === "Yes"}
              onChange={() => setComfortableWithFans("Yes")}
              style={inputStyle}
            />
            Yes
          </label>
          <label style={labelStyle}>
            <input
              type="radio"
              name="comfortableWithFans"
              value="No"
              checked={comfortableWithFans === "No"}
              onChange={() => setComfortableWithFans("No")}
              style={inputStyle}
            />
            No
          </label>
        </li>
      </ol>
    </div>
  );
};

export default NonAirConditionedSchoolQuestion;
