import React, { useState } from "react";

const DustbinTypeSurvey = () => {
  const questionData = {
    id: 55,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    text: "Are there different dustbins for different types of waste?",
    options: [
      {
        text: "Yes",
        score: "1",
        correct: true,
        risk: false,
        subOptions: [
          {
            id: "a",
            text: "If yes, specify how are they identified?",
            options: [
              { text: "Color Coded", score: "1", correct: true, risk: false },
              { text: "Labeled", score: "1", correct: true, risk: false },
              { text: "Other", score: "1", correct: true, risk: false },
            ],
          },
        ],
      },
      {
        text: "No",
        score: "0",
        correct: false,
        risk: false,
      },
    ],
    additionalQuestion: {
      id: "b",
      text: "Do the dustbins have proper covers/lids?",
      options: [
        { text: "Yes", score: "1", correct: true, risk: false },
        { text: "No", score: "0", correct: false, risk: false },
      ],
    },
  };

  const [responses, setResponses] = useState({
    mainQuestion: "",
    subQuestion: "",
    additionalQuestion: "",
    otherSubOptionText: "",
  });

  const handleOptionChange = (key, value) => {
    setResponses((prev) => ({ ...prev, [key]: value }));
  };

  const handleOtherTextChange = (e) => {
    setResponses((prev) => ({ ...prev, otherSubOptionText: e.target.value }));
  };

  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      fontSize: "14px",
      lineHeight: "1.6",
      margin: "20px 40px",
      color: "#333",
    },
    questionTitle: {
      fontSize: "16px",
      marginBottom: "16px",
      fontWeight: "bold",
    },
    section: {
      marginBottom: "20px",
      marginLeft: "60px",
    },
    label: {
      fontSize: "14px",
      marginBottom: "8px",
      display: "block",
    },
    input: {
      marginRight: "6px",
    },
    textArea: {
      fontSize: "14px",
      padding: "8px",
      marginTop: "8px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      width: "100%",
      boxSizing: "border-box",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.questionTitle}>Q3. {questionData.text}</h1>
      <div style={styles.section}>
        {questionData.options.map((option, index) => (
          <label key={index} style={styles.label}>
            <input
              type="radio"
              name="dustbinsType"
              value={option.text}
              checked={responses.mainQuestion === option.text}
              onChange={() => handleOptionChange("mainQuestion", option.text)}
              style={styles.input}
            />
            {option.text}
          </label>
        ))}
      </div>

      {responses.mainQuestion === "Yes" &&
        questionData.options
          .find((opt) => opt.text === "Yes")
          .subOptions.map((sub, index) => (
            <div key={index} style={styles.section}>
              <h2 style={{ fontSize: "14px", marginBottom: "12px" }}>
                {sub.text}
              </h2>
              {sub.options.map((subOption, idx) => (
                <label key={idx} style={styles.label}>
                  <input
                    type="radio"
                    name="subQuestion"
                    value={subOption.text}
                    checked={responses.subQuestion === subOption.text}
                    onChange={() =>
                      handleOptionChange("subQuestion", subOption.text)
                    }
                    style={styles.input}
                  />
                  {subOption.text}
                </label>
              ))}
              {responses.subQuestion === "Other" && (
                <textarea
                  placeholder="Please specify here"
                  value={responses.otherSubOptionText}
                  onChange={handleOtherTextChange}
                  style={styles.textArea}
                />
              )}
            </div>
          ))}

      <div style={styles.section}>
        <h2 style={{ fontSize: "14px", marginBottom: "12px" }}>
          {questionData.additionalQuestion.text}
        </h2>
        {questionData.additionalQuestion.options.map((option, index) => (
          <label key={index} style={styles.label}>
            <input
              type="radio"
              name="additionalQuestion"
              value={option.text}
              checked={responses.additionalQuestion === option.text}
              onChange={() =>
                handleOptionChange("additionalQuestion", option.text)
              }
              style={styles.input}
            />
            {option.text}
          </label>
        ))}
      </div>
    </div>
  );
};

export default DustbinTypeSurvey;
