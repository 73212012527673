import React, { useState } from "react";

const WaterPotableProcedureQuestion = () => {
  const questionData = {
    id: 34,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Q4.  What process/procedure has your school adopted to make water potable (fit for drinking)?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: true,
    comment_required: true,
    files_required: false,
    description: "",
    question_type: "Text",
    media_type: "",
    images: null,
    options: [],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  };

  const [answer, setAnswer] = useState("");

  const handleChange = (event) => {
    setAnswer(event.target.value);
  };

  // CSS Styles
  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    lineHeight: "1.6",
    margin: "20px 40px",
    color: "#333",
  };

  const questionTitleStyle = {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "16px",
  };

  const textareaStyle = {
    width: "100%",
    height: "100px",
    padding: "8px",
    fontSize: "14px",
    lineHeight: "1.6",
    borderRadius: "4px",
    border: "1px solid #ccc",
    marginLeft: "60px"
  };

  return (
    <div style={containerStyle}>
      <h1 style={questionTitleStyle}>{questionData.text}</h1>
      <textarea
        value={answer}
        onChange={handleChange}
        style={textareaStyle}
        placeholder="Please describe the process/procedure here..."
      />
    </div>
  );
};

export default WaterPotableProcedureQuestion;
