import React, { useState } from "react";

const AirConditionedSchool = ({ data }) => {
  const [isFullyAirConditioned, setIsFullyAirConditioned] = useState("");
  const [selectedSpaces, setSelectedSpaces] = useState([]);
  const [anyOtherDetails, setAnyOtherDetails] = useState("");
  const [thermostatSetting, setThermostatSetting] = useState("");
  const [doorsWindowsSealed, setDoorsWindowsSealed] = useState("");
  const [ceilingFansUsed, setCeilingFansUsed] = useState("");

  const handleFullyAirConditionedChange = (value) => {
    setIsFullyAirConditioned(value);
    if (value === "Yes") {
      setSelectedSpaces([]);
      setAnyOtherDetails("");
    }
  };

  const handleSpaceSelection = (space) => {
    setSelectedSpaces((prev) =>
      prev.includes(space) ? prev.filter((s) => s !== space) : [...prev, space]
    );
  };

  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      lineHeight: "1.6",
      margin: "20px 40px",
      color: "#333",
    },
    questionTitle: {
      fontSize: "16px",
      marginBottom: "16px",
      fontWeight: "bold",
    },
    section: {
      marginBottom: "20px",
      marginLeft: "40px",
      fontSize: "14px",
      fontWeight: "bold",
    },
    label: {
      fontSize: "14px",
      display: "block",
      marginBottom: "8px",
    },
    inputGroup: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      marginTop: "6px",
    },
    input: {
      marginRight: "6px",
    },
    textarea: {
      display: "block",
      width: "100%",
      maxWidth: "500px",
      marginTop: "5px",
      marginBottom: "15px",
      fontSize: "14px",
      padding: "8px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      resize: "vertical",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.questionTitle}>Q3. {data.text}</h1>
      <ol type="1" style={{ paddingLeft: "20px" }}>
        <li style={styles.section}>
          <p>1. Is your school fully air-conditioned?</p>
          <div style={styles.inputGroup}>
            {data.options.map((option) => (
              <label key={option.text} style={styles.label}>
                <input
                  type="radio"
                  name="fullyAirConditioned"
                  value={option.text}
                  checked={isFullyAirConditioned === option.text}
                  onChange={() => handleFullyAirConditionedChange(option.text)}
                  style={styles.input}
                />
                {option.text}
              </label>
            ))}
          </div>

          {isFullyAirConditioned === "No" && (
            <div style={{ marginLeft: "40px", marginTop: "10px" }}>
              <p>If No, which spaces are air-conditioned? Please tick:</p>
              <ol type="a" style={{ paddingLeft: "20px" }}>
                {["Classrooms – Junior/Senior", "Auditorium", "Multipurpose Hall", "Science Lab", "Teachers’ Room", "Closed Gym", "Music Room", "Computer Lab", "Maths Lab", "Office Space"].map((space) => (
                  <li key={space} style={styles.label}>
                    <label>
                      <input
                        type="checkbox"
                        value={space}
                        checked={selectedSpaces.includes(space)}
                        onChange={() => handleSpaceSelection(space)}
                        style={styles.input}
                      />
                      {space}
                    </label>
                  </li>
                ))}
                <li style={styles.label}>
                  <label>
                    <input
                      type="checkbox"
                      value="Any other"
                      checked={selectedSpaces.includes("Any other")}
                      onChange={() => handleSpaceSelection("Any other")}
                      style={styles.input}
                    />
                    Any other (Specify):
                  </label>
                  {selectedSpaces.includes("Any other") && (
                    <textarea
                      placeholder="Specify other spaces..."
                      value={anyOtherDetails}
                      onChange={(e) => setAnyOtherDetails(e.target.value)}
                      style={styles.textarea}
                    ></textarea>
                  )}
                </li>
              </ol>
            </div>
          )}
        </li>

        <li style={styles.section}>
          <p>2. What is the thermostat setting of the air-conditioner (on average)?</p>
          <textarea
            placeholder="Enter thermostat setting..."
            value={thermostatSetting}
            onChange={(e) => setThermostatSetting(e.target.value)}
            style={styles.textarea}
          ></textarea>
        </li>

        <li style={styles.section}>
          <p>3. Are exterior doors and windows of air-conditioned spaces properly sealed and closed?</p>
          <div style={styles.inputGroup}>
            <label>
              <input
                type="radio"
                name="doorsWindowsSealed"
                value="Yes"
                checked={doorsWindowsSealed === "Yes"}
                onChange={(e) => setDoorsWindowsSealed(e.target.value)}
                style={styles.input}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="doorsWindowsSealed"
                value="No"
                checked={doorsWindowsSealed === "No"}
                onChange={(e) => setDoorsWindowsSealed(e.target.value)}
                style={styles.input}
              />
              No
            </label>
          </div>
        </li>

        <li style={styles.section}>
          <p>4. Are ceiling fans used along with AC?</p>
          <div style={styles.inputGroup}>
            <label>
              <input
                type="radio"
                name="ceilingFansUsed"
                value="Yes"
                checked={ceilingFansUsed === "Yes"}
                onChange={(e) => setCeilingFansUsed(e.target.value)}
                style={styles.input}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="ceilingFansUsed"
                value="No"
                checked={ceilingFansUsed === "No"}
                onChange={(e) => setCeilingFansUsed(e.target.value)}
                style={styles.input}
              />
              No
            </label>
          </div>
        </li>
      </ol>
    </div>
  );
};

export default AirConditionedSchool;
