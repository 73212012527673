import React, { useState } from 'react';

const Q14Component = () => {
  const [rewardSystem, setRewardSystem] = useState('');
  const [rewardDetails, setRewardDetails] = useState('');

  const handleRadioChange = (e) => {
    setRewardSystem(e.target.value);
  };

  const handleTextChange = (e) => {
    setRewardDetails(e.target.value);
  };

  // Styles matching the DustbinTypeSurvey styles
  const styles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      fontSize: '14px',
      lineHeight: '1.6',
      margin: '20px 40px',
      color: '#333',
    },
    questionTitle: {
      fontSize: '16px',
      marginBottom: '16px',
      fontWeight: 'bold',
    },
    section: {
      marginBottom: '20px',
      marginLeft: '40px', // To match the indentation from DustbinTypeSurvey
    },
    label: {
      fontSize: '14px',
      marginBottom: '4px', // Reduced margin to fix vertical gap
      display: 'block',
    },
    input: {
      marginRight: '6px',
    },
    textArea: {
      fontSize: '14px',
      padding: '8px',
      marginTop: '8px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      width: '100%',
      boxSizing: 'border-box',
    },
    radioGroup: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px', // Space between radio buttons
      marginTop: '6px',
      marginLeft: '20px', // To match the left margin for consistent indentation
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.questionTitle}>
        Q14. Is there a student appreciation/recognition reward system for observing efficient waste management practices?
      </h1>
      <div style={styles.section}>
        <div style={styles.radioGroup}>
          <label style={styles.label}>
            <input
              type="radio"
              name="rewardSystem"
              value="Yes"
              checked={rewardSystem === 'Yes'}
              onChange={handleRadioChange}
              style={styles.input}
            />
            Yes
          </label>
          <label style={styles.label}>
            <input
              type="radio"
              name="rewardSystem"
              value="No"
              checked={rewardSystem === 'No'}
              onChange={handleRadioChange}
              style={styles.input}
            />
            No
          </label>
        </div>
      </div>

      {rewardSystem === 'Yes' && (
        <div style={styles.section}>
          <h2 style={{ fontSize: '14px', marginBottom: '12px' }}>
            If yes, specify its nature...
          </h2>
          <textarea
            value={rewardDetails}
            onChange={handleTextChange}
            placeholder="Describe the nature of the reward system"
            rows="6"
            cols="50"
            style={styles.textArea}
          />
        </div>
      )}
    </div>
  );
};

export default Q14Component;
