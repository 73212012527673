import React, { useState } from "react";

const WasteAuditQ12 = () => {
  const [answer, setAnswer] = useState("");

  const handleChange = (e) => {
    setAnswer(e.target.value);
  };

  // CSS Styles
  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    lineHeight: "1.6",
    margin: "20px 40px",
    color: "#333",
  };

  const questionTitleStyle = {
    fontSize: "16px",
    marginBottom: "16px",
    fontWeight: "bold",
  };

  const inputGroupStyle = {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    marginTop: "10px",
    marginLeft: "60px", // Add margin for alignment
  };

  const labelStyle = {
    fontSize: "14px",
  };

  const inputStyle = {
    marginRight: "8px",
  };

  return (
    <div style={containerStyle}>
      <h1 style={questionTitleStyle}>
        Q12. Are posters/slogans placed at various points in school to remind students of proper ways of waste disposal?
      </h1>
      <div style={inputGroupStyle}>
        <label style={labelStyle}>
          <input
            type="radio"
            name="postersSlogans"
            value="Yes"
            checked={answer === "Yes"}
            onChange={handleChange}
            style={inputStyle}
          />
          Yes
        </label>
        <label style={labelStyle}>
          <input
            type="radio"
            name="postersSlogans"
            value="No"
            checked={answer === "No"}
            onChange={handleChange}
            style={inputStyle}
          />
          No
        </label>
      </div>
    </div>
  );
};

export default WasteAuditQ12;
