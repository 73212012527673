import { Box, Typography } from "@mui/material";
import React, { useState } from "react";

const HealthFirstPage = ({onClick}) => {

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
        padding: "1rem",
        overflow: "auto",
        "@media (max-width: 900px)": {
          padding: "0.5rem",
        },
      }}
    >
    <Box
        sx={{
          fontSize: "1.25rem",
          padding: "2rem",
          "@media (max-width: 900px)": {
            padding: "1rem",
          },
        }}
    >
        <Box
          sx={{
            borderRadius: "1rem",
            backgroundColor: "#f0f0f0",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "2rem",
            boxSizing: "border-box",
            "@media (max-width: 900px)": {
              width: "100%",
              padding: "1rem",
            },
          }}
        >
          <div className="lets-begin-your-first-day-cha-parent">
            <Typography
              component="b"
              sx={{
                fontSize: "1.25rem",
                fontWeight: 700,
                // fontFamily: "cursive",
              }}
              className="preview"
            >
              CBSE circular no.Acad-I012018
            </Typography>
            <img className="tick-icon" alt="" src="/tick.svg" />
          </div>
          <div className="click-here-to-container">
            <Typography
              component="span"
              sx={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              className="click-here"
            >
              Click here
            </Typography>
            <Typography component="span" className="to-know-more">
              {" "}
              to know more.
            </Typography>
          </div>
        </Box>

        <Box
          sx={{
            marginTop: "2rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "2rem 2rem 1rem 1.5rem",
            borderRadius: "1rem",
            backgroundColor: "#FFFFFF",
            border: "5px solid #2356fe",
            boxSizing: "border-box",
            width: "100%",
            overflow: "hidden",
            "@media (max-width: 900px)": {
              padding: "1rem",
            },
          }}
        >
            <Box
                sx={{
                    padding: "2rem",
                }}
            >
                <Typography
                    sx={{
                        fontSize: "1.875rem",
                        fontWeight: 600,
                        // fontFamily: "cursive",
                    }}
                    className="hello_name"
                >  
                    Hello Meenakshi Pandey,
                </Typography>

                <Typography
                    sx={{
                        fontSize: "1.375rem",
                        fontWeight: 400,
                        // fontFamily: "cursive",
                    }}
                    className="mo_of"
                >  
                    M.O. of Dheeraj Pandey
                </Typography>
            </Box>

            <Box
                sx={{
                    padding: "1rem 2rem",
                }}
            >
                <Box
                    sx={{
                        marginBottom: "1rem",
                    }}
                >
                    <Typography
                        component="span"
                        sx={{
                            fontSize: "1.375rem",
                            fontWeight: 600,
                            color: "blue",
                            // fontFamily: "cursive",
                        }}
                        className="children_title"
                    >  
                    Children
                </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        marginBottom: "7rem",
                    }}
                >
                    <Box
                        sx={{
                            padding: "1rem 1.5rem",
                            marginRight: "2rem",
                            border: "1px solid #2356FE",
                            borderRadius: "10px",
                            backgroundColor: "#F9F9F9",
                            width: "fit-content",
                            textAlign: "baseline",
                        }}
                    >
                        <Typography
                            component="span"
                            sx={{
                                fontSize: "1.375rem",
                                fontWeight: 400,
                                color: "#2356FE",
                                // fontFamily: "cursive",
                            }}
                            className="add_children"
                        >  
                            Add Children
                        </Typography>
                        <Typography
                            component="span"
                            sx={{
                                fontSize: "2rem",
                                fontWeight: 700,
                                color: "#2356FE",
                                // fontFamily: "cursive",
                            }}
                            className="plus_symbol"
                        >  
                            
                        </Typography>
                    </Box>
                    <Box onClick = {()=> onClick()}
                        sx={{
                            padding: "1rem 1.5rem",
                            backgroundColor: "#2356FE",
                            borderRadius: "10px",
                            width: "fit-content",
                        }}
                    >
                        <Typography
                            component="span"
                            sx={{
                                fontSize: "1.375rem",
                                fontWeight: 600,
                                color: "white",
                                // fontFamily: "cursive",
                            }}
                            className="children_title"
                        >  
                            Dheeraj Pandey, Male
                        </Typography>

                        <Typography
                            // component="span"
                            sx={{
                                fontSize: "1.375rem",
                                fontWeight: 400,
                                color: "white",
                                // fontFamily: "cursive",
                            }}
                            className="children_title"
                        >  
                            2 May, 2007
                        </Typography>
                    </Box>
                </Box>
            </Box>  
          </Box>
        </Box>
        </Box>
  );
};

export default HealthFirstPage;
