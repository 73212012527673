import React, { useContext, useEffect, useState } from "react";
import EventNoteIcon from "@mui/icons-material/EventNote";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Card } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import OnBoardContext from "../context/onBoardContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { api, apiJson, apiJsonAuth } from "api";
import { toast } from "react-toastify";
import { useGlobalContext } from "global/context";
import googleCustomIcon from "icons/googleCustomIcon";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "../style/OnBoardingStyle.css";
import { Button, Input, Typography } from "@mui/joy";
import {
  CheckCircle,
  ErrorOutlineRounded,
  ForwardRounded,
} from "@mui/icons-material";
import { debounce } from "lodash";
import StyledAlert from "components/elements/StyledAlert";
import StyledRadioGroup from "components/elements/StyledRadioGroup";
import {
  ChevronRightTwoTone,
  Details,
  Facebook,
  FormatQuote,
  Instagram,
  LinkedIn,
  LocationOn,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import { Modal } from "react-bootstrap";
import Faq from "./Faq";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";

// import { GoogleLogin } from 'react-google-login';
export const OnBoardingComponent = () => {
  const location = useLocation();
  const googleAuthStatus = new URLSearchParams(location.search).get(
    "googleAuthStatus"
  );

  const stepCount = new URLSearchParams(location.search).get("count");
  const types = new URLSearchParams(location.search).get("type");
  let registration_type = new URLSearchParams(location.search).get(
    "registration_type"
  );
  const instituteIdParams = new URLSearchParams(location.search).get(
    "instituteId"
  );
  // google redirect set type and email
  const googleUserType = new URLSearchParams(location.search).get("userType");
  const authStep = new URLSearchParams(location.search).get("authStep");
  const googleEmail = new URLSearchParams(location.search).get("email");

  const isNipam = new URLSearchParams(location.search).get("isNipam");

  // Get the value of a specific query parameter
  const navigate = useNavigate();
  const { userData } = useGlobalContext();
  const { setToken, setUser, setUserPreRegister } = useGlobalContext();
  const [step, setStep] = useState(1);
  const [selectedType, setSelectedType] = useState("");
  const [showType, setShowType] = useState("");
  const [validEmail, setValidEmail] = useState("");
  const [emailExist, setemailExist] = useState(false);
  const [emailExistLogin, setemailLoginExist] = useState(true);
  const [emailLoading, setEmailLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [sentVerificationEmail, setsentVerificationEmail] = useState(false);
  const [currentToken, setCurrentToken] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [isSubmitingEmail, setisSubmitingEmail] = useState(false);
  const [showEyeIconNew, setShowEyeIconNew] = useState(false);
  const [showEyeIconConfirm, setShowEyeIconConfirm] = useState(false);
  const [showEyeIconEnterPass, setShowEyeIconEnterPass] = useState(false);
  const [showCredential, setShowCredential] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [managerDetails, setManagerdetails] = useState({});
  const [insDetail, setInsDetail] = useState();
  const [getInstDetailLoader, setGetInstDetailLoader] = useState(false);
  // console.log(googleAuthStatus)
  const sid = new URLSearchParams(location.search).get("sid");
  const managerType = new URLSearchParams(location.search).get("managerType");
  let urlParams = new URLSearchParams(location.search);
  const userDetail = useFormik({
    initialValues: {
      email: urlParams.get("email") || "",
      type: "Student",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .email("Invalid email address"),
    }),
  });
  const sendResetPassword = (email) => {
    apiJson(`v2/auth/v2-reset-password-email/${email}`)
      .then((res) => {
        navigate(`/v2/auth/password-reset/email/verify?email=${email}`);
        toast.dismiss();
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss();

        toast.error("Server Busy. Please try again later");
      });
  };

  const storeQueryParameters = (url) => {
    let queryParams = {};
    if (["Moderator", "Manager", "Admin"].includes(managerType)) {
      url.forEach((value, key) => {
        queryParams[key] = !isNaN(value) ? Number(value) : value;
      });
    } else if (!managerType) {
      url.forEach((value, key) => {
        queryParams[key] = !isNaN(value) ? Number(value) : value;
      });
    } else {
      queryParams = {
        sid: parseInt(urlParams.get("sid")),
        name: urlParams.get("name"),
        type: urlParams.get("type"),
        instituteId: parseInt(urlParams.get("instituteId")),
        count: parseInt(urlParams.get("count")),
        email: urlParams.get("email"),
        phone: parseInt(urlParams.get("phone")),
        eventType: urlParams.get("eventType"),
        event: {
          [urlParams.get("eventType")]: {
            accessType: urlParams.get("accessType"),
            managerType: urlParams.get("managerType"),
          },
        },
      };
      setManagerdetails(urlParams.get("email"));
      setFormikData("email", urlParams.get("email") || "");
    }
    if (registration_type) {
      setUserPreRegister(queryParams);
    }
  };

  // ========= Get manager Details by sid from institute acccount manager details table ============\\
  const getDetails = async () => {
    try {
      const result = await apiJson.get(`v2/register/managerDetails/${sid}`);
      setManagerdetails(result?.data?.details);
      setFormikData("email", result?.data?.details?.email || "");
      toast.dismiss();
    } catch (error) {
      toast.dismiss();

      toast.error("server error");
    }
  };

  // if fail then show message
  useEffect(() => {
    // console.log(googleAuthStatus)
    // if(googleAuthStatus==='fail'){
    //   toast.dismiss()
    //   toast.error('Not a registered Email')
    // }
    if (sid && ["Moderator", "Manager", "Admin"].includes(managerType)) {
      getDetails();
    }
    storeQueryParameters(urlParams);
  }, []);
  const validation = Yup.object().shape({
    newPassword: Yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])(?=.{6,})/,
        "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 symbol, 1 number, and be at least 6 characters"
      )
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };
  const onSubmit = () => {
    // console.log("asfjasklfj")

    checkpassExistorNot();
  };
  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit,
  });

  useEffect(() => {
    if (location.pathname.includes("/login")) {
      setEmailLoading(false);
      setStep(6);
    }
    if (location.pathname.includes("/registration")) {
      setEmailLoading(false);
      setStep(2);
    }
  }, [location.pathname]);

  useEffect(() => {
    setemailLoginExist(true);
    setemailExist(false);
  }, [step]);
  useEffect(() => {
    if (userData) {
      userDetail.setValues({
        email: userData?.email,
        type: types,
      });
    }
  }, [userData, types]);

  const setFormikData = (name, value) => {
    userDetail.setFieldValue(name, value);
  };

  const checkpassExistorNot = async () => {
    console.log(userDetail, "'userDetail value");
    const url = `v2/register/instituteGetData/${userDetail.values.email}`;
    try {
      const result = await apiJson.get(url);
      console.log(result, "result value");
      if (result?.data?.success === true) {
        Swal.fire({
          text: result?.data?.message,
          icon: "warning",
          confirmButtonText: "Login",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            navigate("/login");
          }
        });
      } else {
        if (userDetail?.values?.type === "Institution") {
          newInstituteRegister();
        } else if (userDetail.values.type === "Student") {
          // console.log("alsdfjasdklfj")
          newStudentRegister();
        } else if (userDetail.values.type === "Teacher") {
          newStudentRegister();
        }
      }
    } catch (error) {
      console.log(error?.message);
    }
  };

  // Institute register with email and password
  const newInstituteRegister = () => {
    const data = {
      email: userDetail.values.email,
      password: formik.values.newPassword,
      isNipam,
    };
    setNewStudentLoader(true);

    apiJson
      .post("v2/register/newInstituteRegister", data)
      .then((res) => {
        setNewStudentLoader(false);
        if (res.data.success === true) {
          navigate("/login");

          //call nipam api here
        }
      })
      .then(() => {
        setNewStudentLoader(false);
        // window.location.href = '/institute-on-boarding';
        navigate("/login");
        toast.dismiss();
      })
      .catch((error) => {
        setNewStudentLoader(false);
        console.log(error.message);
        toast.dismiss();

        toast.dismiss("Server Busy. Please try again later");
      });
  };
  const [newStudentLoader, setNewStudentLoader] = useState(false);
  const newStudentRegister = () => {
    const data = {
      email: userDetail.values.email,
      password: formik.values.newPassword,
      role: userDetail.values.type === "Student" ? "student" : "teacher",
      institute_id: instituteIdParams,
    };
    setNewStudentLoader(true);
    apiJson
      .post("v2/register/newStudentRegister", data)
      .then((res) => {
        setNewStudentLoader(false);
        if (res.data.success === true) {
          navigate("/login");
        }
      })
      .then(() => {
        setNewStudentLoader(false);
        // window.location.href = '/student-on-boarding';
        navigate("/login");
        toast.dismiss();
      })
      .catch((error) => {
        setNewStudentLoader(false);
        console.log(error.message);
        toast.dismiss();

        toast.dismiss("Server Busy. Please try again later");
      });
  };

  // after enter email it execute
  const checkIfEmailExist = (email) => {
    // console.log('=>>>', email)

    if (email) {
      setEmailLoading(true);
      apiJson
        .post("v2/register/checkEmailExist", { email: email, isNipam })
        .then((res) => {
          setEmailLoading(false);
          setemailExist(res.data.result);
          setemailLoginExist(res.data.result);
          // setFormikData('email', "")
          // console.log(res.data.result)
          toast.dismiss();

          if (res.data.result) {
            toast.dismiss();
            toast.error("Email Already Exist");
            // console.log('Email Already Exist')
          } else {
            setStep(5);
            sendVerifyEmail(email);
            toast.dismiss();
          }
        })
        .catch((error) => {
          setEmailLoading(false);
          toast.dismiss();
          toast.error("Server Busy. Please try again later");
        });
    } else {
      toast.dismiss();
      toast.error("Email is Required");
    }
  };
  const checkIfEmailExistLogIn = (email) => {
    if (email) {
      setEmailLoading(true);
      apiJson
        .post("v2/register/checkEmailExist", { email: email })
        .then((res) => {
          setEmailLoading(false);
          setemailLoginExist(res.data.result);

          if (res.data.result) {
            setStep(7);
          }
        })
        .catch((error) => {
          setEmailLoading(false);
          toast.dismiss();
          toast.error("Server Busy. Please try again later");
        });
    } else {
      toast.dismiss();
      toast.error("Email is Required");
    }
  };

  const handleLogIn = () => {
    const data = {
      email: userDetail.values.email,
      password: userDetail.values.password,
    };
    if (!data.password && data.email) {
      setShowCredential("Enter your Password");
    } else {
      apiJson
        .post("v2/auth/v2-login", data)
        .then((res) => {
          if (res.data.success === true) {
            if (
              (res?.data?.user?.role === "teacher" &&
                res?.data.user?.nipamCheck) ||
              (res?.data?.user?.role === "student" &&
                res?.data.user?.nipamCheck)
            ) {
              setUser(res.data.user);
              setToken(res.data.jwt);
              navigate("/course/overview/4");
            } else {
              setUser(res.data.user);
              setToken(res.data.jwt);
              if (res?.data?.user?.role === "admin") {
                navigate("/admin/allInstituteDetails");
              } else {
                navigate("/new-dashboard");
              }
            }
          } else {
            setShowCredential("Invalid password");
          }

          // if (res.data.success === true && ((res?.data?.user?.role === "institute" && res?.data?.user?.nipamCheck) || !res?.data?.user?.nipamCheck)
          // ) {

          //   setUser(res.data.user);
          //   setToken(res.data.jwt);
          //   navigate('/new-dashboard');
          // }
          // else if(res.data.success === true && res?.data.user?.nipamCheck){
          //   setUser(res.data.user);
          //   setToken(res.data.jwt);
          //   navigate('/course/detail/4');
          // } else {
          //   setShowCredential("Invalid password");
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleInputChange = debounce((value) => {
    if (!value) {
      setShowCredential("");
    }
  }, 300);

  useEffect(() => {
    console.log("code execute");
  
    if (emailLoading) {
      let checkInterval = setInterval(() => {
        if (!emailLoading) {
          // Stop the interval if emailLoading is false
          clearInterval(checkInterval);
        } else {
            checkEmailVerificationStatus(userData.email);
        
        }
      }, 5000);
  
      // Cleanup function to clear interval when the component unmounts or emailLoading changes
      return () => {
        clearInterval(checkInterval);
      };
    }
  }, [emailLoading, userData.email]);
  

  //
  const checkEmailVerificationStatus = (email) => {
    debugger
    if (userDetail.values.type === "Institution") {
      apiJson
        .get(`v2/register/is-verified/status/${email}`)
        .then((res) => {
          setEmailLoading(false);
          setIsVerified(res.data.result);
          if (res.data.result) {
            clearInterval(checkInterval);
          }

          // console.log(res.data.result)
        })
        .catch((error) => {
          setEmailLoading(false);
          console.log(error.message);
        });
    } else {
      apiJson
        .post("v2/register/is-verified/student", {
          email,
          role: userDetail.values.type === "Student" ? "student" : "teacher",
        })
        .then((res) => {
          setEmailLoading(false);
          setIsVerified(res.data.result);
          if (res.data.result) {
            clearInterval(checkInterval);
          }
        })
        .catch((error) => {
          setEmailLoading(false);
          console.log(error.message);
        });
    }
  };

  const checkEmailVerification = (email) => {
    // console.log("===>>>>", email)
    if (userDetail.values.type === "Institution") {
      apiJson
        .post("v2/register/is-verified", { email })
        .then((res) => {
          setEmailLoading(false);
          setIsVerified(res.data.result);
          if (res.data.result) {
            clearInterval(checkInterval);
          }

          // console.log(res.data.result)
        })
        .catch((error) => {
          setEmailLoading(false);
          console.log(error.message);
        });
    } else {
      apiJson
        .post("v2/register/is-verified/student", {
          email,
          role: userDetail.values.type === "Student" ? "student" : "teacher",
        })
        .then((res) => {
          setEmailLoading(false);
          setIsVerified(res.data.result);
          if (res.data.result) {
            clearInterval(checkInterval);
          }
        })
        .catch((error) => {
          setEmailLoading(false);
          console.log(error.message);
        });
    }
  };
  let checkInterval; // Define checkInterval outside of any function scope

  const sendVerifyEmail = (email) => {
    setEmailLoading(true);

    // if (checkInterval) {
    //   clearInterval(checkInterval);
    // }

    // Set up the interval
    // checkInterval = setInterval(() => {
    //   const tempVerified = isVerified
    //   if (tempVerified) {
    //     clearInterval(checkInterval);
    //     setsentVerificationEmail(false);
    //   } else {

    //     checkEmailVerification(userDetail.values.email);
    //   }
    // }, 5000);

    // for Institutes
    if (userDetail.values.type === "Institution") {
      apiJson
        .post("/v2/register/institution/verify-email", { email, isNipam })
        .then((res) => {
          setsentVerificationEmail(true);
          toast.dismiss();

          toast.success("Email sent SuccessFully");
          setCurrentToken(res.data.token);
          setUser({ email: res.data.email, token: res.data.token });
        })
        .catch((error) => {
          setEmailLoading(false);
          toast.dismiss();

          toast.warning("Server Busy. Please try again later");
        });
    } else {
      // for student/teacher
      apiJson
        .post("/v2/register/student/verify-email", {
          email,
          role: userDetail.values.type === "Student" ? "student" : "teacher",
          instituteId: Number(instituteIdParams),
        })
        .then((res) => {
          setsentVerificationEmail(true);
          toast.dismiss();

          toast.success("Email sent SuccessFully");
          setCurrentToken(res.data.token);
          setUser({ email: res.data.email, token: res.data.token });
        })
        .catch((error) => {
          setEmailLoading(false);
          toast.dismiss();

          toast.warning("Server Busy. Please try again later");
        });
    }
  };

  useEffect(() => {
    if (isVerified) {
      setStep(8);
    }
  }, [isVerified]);
  useEffect(() => {
    if (step === 2) {
      setSelectedType("Student");
    }
    if (step === 3) {
      setSelectedType("Individual Institute");
    }
  }, [step]);

  const handleClickAccountType = (type) => {
    console.log("type", type);
    if (type === "Institution") {
      setStep(3);
      return;
    }

    setShowType(
      accountType.find((item) => {
        return item.type === type;
      }).show
    );
    setStep(4);
  };

  // useEffect(()=>{
  //   if(isNipam){
  //     setUser({isNipam:true})
  //   }
  // },[isNipam])

  useEffect(() => {
    if (googleUserType && authStep && googleEmail) {
      userDetail.setFieldValue("type", googleUserType);
      userDetail.setFieldValue("email", googleEmail);
      setStep(Number(authStep));
    } else if (authStep && googleEmail) {
      userDetail.setFieldValue("type", googleUserType);
      userDetail.setFieldValue("email", googleEmail);
      setStep(Number(authStep));
    }

    if (stepCount && types) {
      if (types === "Institution") {
        setShowType(
          accountType.find((item) => {
            return item.type === types;
          }).show
        );
        setSelectedType("Individual Institute");
        setFormikData("type", types);
        setStep(Number(stepCount));

        return;
      }
      // handleClickAccountType(types)
      setShowType(
        accountType.find((item) => {
          return item.type === types;
        })?.show
      );
      setSelectedType(types);
      setFormikData("type", types);
      setStep(Number(stepCount));
    }
  }, []);

  const getInsDetail = async () => {
    const getUserData = new URLSearchParams(window.location.search);
    const instituteId = getUserData.get("instituteId");
    // console.log("instituteId", instituteId);
    if (instituteId) {
      setGetInstDetailLoader(true);
      apiJson
        .get(`v2/register/getInsDetail/${instituteId}`)
        .then((res) => {
          // console.log("res", res);
          setGetInstDetailLoader(false);
          setInsDetail(res?.data?.getInstituteData);
        })
        .catch((error) => {
          setGetInstDetailLoader(false);
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getInsDetail();
  }, []);

  const accountType = [
    {
      type: "Student",
      body: "Students who wish to participate in Yuvamanthan events and their institutions have already registered.",
      show: "Register Student",
    },
    {
      type: "Teacher",
      body: "Teachers who are involved in Yuvamanthan events in their institutions.",
      show: "Register Teacher",
    },
    {
      type: "Institution",
      body: "Educational institution that wishes to register and create an account with Yuvamanthan.",
      show: "Register Your Educational Institute Now!",
    },
    {
      type: "Individual Institute",
      show: "Register Individual Institute",
      showFor: "Institute",
    },
    {
      type: "Group of Institution",
      show: "Register Group of Institute",
      showFor: "Institute",
    },
  ];

  const handleEyeToogleNew = () => {
    setShowEyeIconNew(!showEyeIconNew);
  };
  const handleEyeToogleConfirm = () => {
    setShowEyeIconConfirm(!showEyeIconConfirm);
  };
  const handleEyeToogleEnterPass = () => {
    setShowEyeIconEnterPass(!showEyeIconEnterPass);
  };

  const [showGetHelpModal, setGetHelpModal] = useState(false);

  // Modal Toggle
  const handleShowModal = () => {
    setGetHelpModal(true);
  };
  const handleClose = () => {
    setGetHelpModal(false);
  };

  const renderStepContent = () => {
    // step 1 create Account
    // step 2 choose Account Type
    // step 3 creating Institution Account
    // step 4 Register
    // step 5 Verify
    // step 6 Login

    switch (step) {
      // create Account
      case 1:
        return (
          <div>
            <span className="create-acc-title mb-50 d-block fw-semibold">
              Create Account
            </span>
            <span className="mb-4  d-block fs-4 fw-semibold">
              Welcome to Yuvamanthan
            </span>
            <span
              className="intro-content-crate-acc fw-500"
              style={{ color: "#989898" }}
            >
              Experiential learning lies at the heart of Yuvamanthan's pedagogy.
              Through interactive sessions, simulated situations, and guided
              discussions, the platform offers the youth a practical learning
              environment, where they can apply their knowledge and skills in a
              risk-free setting.
            </span>

            <div className="d-flex justify-content-between align-items-center mt-4">
              <Link
                to="/login"
                className="fw-bold"
                // onClick={() => navigate('/login')}
              >
                Login
              </Link>

              <Button
                variant="solid"
                size="lg"
                className="rounded-3"
                onClick={() => setStep(2)}
                endDecorator={<ForwardRounded />}
              >
                Continue
              </Button>
            </div>
          </div>
        );

      // Choose Account Type
      case 2:
        return (
          <div>
            <div>
              <span className="create-acc-title mb-3 d-block fw-semibold">
                Create Account
              </span>
              <span className="mb-2  d-block fs-4 fw-semibold">
                Welcome to Yuvamanthan
              </span>
              <span
                className="intro-content-crate-acc fw-500 mb-3"
                style={{ color: "#989898" }}
              >
                Experiential learning lies at the heart of Yuvamanthan's
                pedagogy. Through interactive sessions, simulated situations,
                and guided discussions, the platform offers the youth a
                practical learning environment, where they can apply their
                knowledge and skills in a risk-free setting.
              </span>
            </div>
            <div className="mb-3 mt-2">
              <span className="choose-acc-type fs-3 fw-600 ">
                Choose The Account Type{" "}
              </span>
            </div>
            <div>
              {accountType.map((acc, index) => {
                return (
                  <>
                    {!acc.showFor && (
                      <Card
                        onClick={() => {
                          setFormikData("type", acc.type);
                          setSelectedType(acc.type);
                        }}
                        style={{ borderColor: "transparent" }}
                        className={`cursor-pointer rounded-3 mb-4  border-2 ${
                          selectedType === acc.type && "border-primary "
                        }`}
                      >
                        <Card.Body>
                          <div className="form-check d-flex ">
                            <span>
                              <input
                                onChange={() => {
                                  setSelectedType(acc.type);
                                  setFormikData("type", acc.type);
                                }}
                                className="form-check-input"
                                type="radio"
                                name="account"
                                id={acc.type}
                                value={acc.type}
                                checked={selectedType === acc.type}
                              />
                            </span>
                            <div
                              className="form-check-label ms-2 "
                              for={acc.type}
                            >
                              <div>
                                <span className="font-stud-teach-insti fw-500">
                                  {" "}
                                  For {acc.type}
                                </span>
                              </div>
                              <div>
                                <span
                                  style={{
                                    color: "#9A9A9A",
                                  }}
                                  className="fs-15px fw-500"
                                >
                                  {" "}
                                  {acc.body}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    )}
                  </>
                );
              })}
            </div>
            {/* <StyledRadioGroup
            name="account-type"
            items={accountType}
            type='detailed'
            /> */}
            <div className="d-flex justify-content-between py-4 sticky-bottom bg-white">
              <Link
                to="/login"
                className="fw-bold"
                // onClick={() => navigate('/login')}
              >
                Login
              </Link>

              <Button
                onClick={() => {
                  if (!googleUserType && authStep && googleEmail) {
                    setStep(8);
                  } else {
                    handleClickAccountType(selectedType);
                  }
                }}
                variant="solid"
                size="lg"
                color="primary"
              >
                Next{" "}
                <span className="ms-4">
                  <ForwardRounded />
                </span>
              </Button>
            </div>
          </div>
        );

      // creating Institution Account
      case 3:
        return (
          <div>
            <div className="mb-4">
              <span className="create-institute-title fw-600 ">
                Choose the Account Type{" "}
                <span>
                  <Tooltip
                    title={
                      <div>
                        <div>
                          <span>
                            You may register as an{" "}
                            <strong>individual institute</strong> if you manage
                            a single educational institution.
                          </span>
                        </div>
                        <div className="mt-3">
                          <span>
                            Register as a <strong>Group of Institutions</strong>{" "}
                            if you manage more than one institute or multiple
                            institutions across various locations and want them
                            to join.
                          </span>
                        </div>
                      </div>
                    }
                    placement="bottom"
                  >
                    <HelpOutlineIcon
                      sx={{ color: "#5686E1", fontSize: "20px" }}
                    />
                  </Tooltip>
                </span>
              </span>
            </div>
            <div>
              {accountType?.map((inst) => {
                return (
                  <>
                    {inst.showFor === "Institute" && (
                      <Card
                        onClick={() => setSelectedType("Individual Institute")}
                        style={{
                          cursor: "pointer",
                          userSelect: "none",
                          borderColor: "transparent",
                          cursor:
                            inst.type === "Group of Institution" &&
                            "not-allowed",
                        }}
                        className={`rounded-3 mb-2 px-2 border-2  ${
                          inst.type === "Individual Institute" &&
                          "border-primary "
                        } 
                          ${
                            inst.type === "Group of Institution" &&
                            "btn-onboard-fill-disabled-Inter"
                          }
                          `}
                      >
                        <Card.Body>
                          <div className={`form-check px-4`}>
                            <div className="">
                              <input
                                onChange={() =>
                                  setSelectedType("Individual Institute")
                                }
                                className="form-check-input"
                                type="radio"
                                name="account"
                                id="student"
                                value="student"
                                checked={inst.type === "Individual Institute"}
                              />
                            </div>
                            <div
                              className="form-check-label ms-2 "
                              for="student"
                            >
                              <div>
                                <span className="font-stud-teach-insti fw-500">
                                  {" "}
                                  {inst.type}{" "}
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      color: "#0571ed",
                                    }}
                                  >
                                    {inst.type === "Group of Institution" &&
                                      "(Coming Soon)"}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    )}
                  </>
                );
              })}
            </div>
            <div className="d-flex justify-content-between mt-4">
              <Button variant="outlined" size="lg" onClick={() => setStep(2)}>
                Previous
              </Button>

              <Button
                variant="solid"
                size="lg"
                className="rounded-3"
                onClick={() => {
                  handleClickAccountType(selectedType);
                }}
              >
                Next
                <span className="ms-4">
                  <ForwardRounded />
                </span>
              </Button>
            </div>
          </div>
        );

      // Register
      case 4:
        return (
          <>
            <div className="py-4 bg-white">
              <div className="">
                <div className="row">
                  <div className="col-12">
                    <div className="bg-white">
                      {getInstDetailLoader ? (
                        <div className="text-center">
                          <CircularProgress />
                        </div>
                      ) : null}
                      {insDetail && !getInstDetailLoader && (
                        <div
                          style={{
                            background: "#efefef9e",
                            borderRadius: "10px",
                            padding: "8px",
                          }}
                        >
                          <div className="row mb-4">
                            <div className="col-12 col-lg-3">
                              <div className="d-flex justify-content-center align-items-center">
                                <div className="col-12 col-md-8 col-lg-12 text-center">
                                  <img
                                    className=""
                                    style={{ width: "60%" }}
                                    src={insDetail?.logo}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-lg-9">
                              <div className="row justify-content-start align-items-center g-0">
                                <div className="col-12 col-md-8 order-2">
                                  <div className="ps-1 p-lg-3 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                                    <h4
                                      className="intitute_name"
                                      style={{ color: "#fd9700" }}
                                    >
                                      {insDetail?.institution_name}
                                    </h4>
                                    <span
                                      className="institute_address"
                                      style={{
                                        fontWeight: "500",
                                        color: "Grey",
                                      }}
                                    >
                                      {insDetail?.address ? (
                                        <> {insDetail?.address}</>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="border-1">
                            <span className="welcome_name fw-600">
                              Welcome to Yuvamanthan
                            </span>
                            <div className="mt-1">
                              <span className="yuvamanthan_disc fw-400">
                                Yuvamanthan focuses on experiential learning,
                                creating a practical and risk-free environment
                                for youth through interactive sessions and
                                simulated situations. The platform encourages
                                hands-on application of knowledge and skills in
                                guided discussions.
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form
              onSubmit={userDetail.handleSubmit}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.type === "click") {
                  e.preventDefault();
                  if (userDetail.isValid) {
                    checkIfEmailExist(userDetail.values.email);
                  }
                }
              }}
            >
              <div>
                <div className="mb-4">
                  <span className="sign-in-title fw-600"> {showType}</span>
                </div>
                <div className="mb-4">
                  <div className="">
                    <span
                      className="register-detail-content"
                      style={{
                        color: "#989898",
                        fontWeight: "500",
                      }}
                    >
                      {showType === "Register Student"
                        ? "Verify your email ID to register as a student"
                        : showType === "Register Teacher"
                        ? "Verify your email ID to register as a teacher"
                        : "Begin your registration process by verifying your email ID."}
                    </span>
                  </div>
                  <div
                    style={{
                      marginBottom: "",
                    }}
                    className=""
                  >
                    <span
                      className="register-detail-content"
                      style={{
                        color: "black",
                        fontWeight: "400",
                      }}
                    ></span>
                  </div>
                  <div
                    style={{
                      padding: "31px 0 29px 0",
                    }}
                    className=""
                  >
                    {emailLoading ? (
                      <div className="w-100 text-center">
                        <div class="spinner-border text-center" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <input
                        style={{ boxShadow: "none", background: "#dcdcdc2b" }}
                        class={`form-control rounded-3 form-control-lg  custom-placeholder ${
                          emailExist ? "border-danger border-2" : "border-0"
                        }`}
                        type="email"
                        placeholder="Email Address"
                        aria-label=".form-control-lg example"
                        value={userDetail.values.email}
                        onBlur={userDetail.handleBlur}
                        onChange={(e) => {
                          setFormikData("email", e.target.value);
                          setemailExist(false);
                          setisSubmitingEmail(false);
                        }}
                        disabled={sid ? true : false}
                      />
                    )}
                    {userDetail.errors.email && isSubmitingEmail ? (
                      <span className="text-danger">
                        {userDetail.errors.email}
                      </span>
                    ) : null}
                    {emailExist && !userDetail.errors.email && (
                      <StyledAlert
                        type="danger"
                        content="Email already exists"
                      />
                    )}
                  </div>
                  <StyledAlert
                    type="warning"
                    content={
                      showType === "Register Student"
                        ? "Enroll now and become a member of Yuvamanthan's dynamic student community"
                        : showType === "Register Teacher"
                        ? "Enlist as a Teacher today and join Yuvamanthan in shaping the future leaders!"
                        : " Register your institution and begin its transformative journey into experiential learning based-intervention. A journey whose impact will reverberate through generations, shaping a future that is truly Amrit Kaal - a time of limitless possibilities and collective growth."
                    }
                  />
                </div>

                <div className="d-flex justify-content-between my-4 mb-4">
                  <button
                    className="btn-onboard"
                    onClick={() => {
                      setFormikData("email", "");
                      setisSubmitingEmail(false);
                      navigate("/login");
                    }}
                  >
                    Login
                  </button>

                  <Button
                    disabled={emailLoading}
                    variant="solid"
                    size="lg"
                    className={`rounded-3 ${
                      emailLoading
                        ? "btn-onboard-fill-disabled"
                        : "next-btn-background-color"
                    }`}
                    // style={{ backgroundColor: emailLoading ? 'cursor' : '#0b6bcb' }}
                    // className="rounded-3"
                    onClick={(e) => {
                      e.preventDefault();
                      setisSubmitingEmail(true);
                      if (userDetail.isValid) {
                        console.log(
                          "userDetail.values.email",
                          userDetail.values.email
                        );
                        checkIfEmailExist(userDetail.values.email);
                      }
                    }}
                  >
                    <span>
                      Next
                      <span className="ms-4">
                        <ForwardRounded />
                      </span>
                    </span>
                  </Button>
                </div>
                <div className="row justify-content-center mb-4 ">
                  <span
                    style={{
                      fontSize: "19px",
                      color: "grey",
                    }}
                    className="col-3 fw-semibold text-center "
                  >
                    OR
                  </span>
                </div>
                <div className="row justify-content-center">
                  <div className=" col-12  text-center ">
                    <div
                      onClick={() =>
                        (window.location.href = `${process.env.REACT_APP_API_BASE_URL}auth/google?userType=${userDetail.values.type}`)
                      }
                      style={{ background: "#FAFBFB" }}
                      className="px-4 py-2 shadow-sm d-inline-block rounded-3 cursor-pointer"
                    >
                      {/* <googleCustomIcon/> */}
                      <svg
                        style={{ width: "30px", height: "30px" }}
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="100"
                        height="100"
                        viewBox="0 0 48 48"
                      >
                        <path
                          fill="#FFC107"
                          d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                        ></path>
                        <path
                          fill="#FF3D00"
                          d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                        ></path>
                        <path
                          fill="#4CAF50"
                          d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                        ></path>
                        <path
                          fill="#1976D2"
                          d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                        ></path>
                      </svg>
                      <span className="fw-semibold color-grey rs-font">
                        {" "}
                        Register With Google
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        );

      // Verify
      case 5:
        return (
          <div>
            <div className="mb-2">
              <span className="sign-in-title">Email Verification</span>
            </div>
            <div>
              <div className="mb-4">
                <span
                  className="credentials-content"
                  style={{
                    color: "#989898",
                    // fontSize: '19px',
                    // fontWeight: '500'
                  }}
                >
                  Verifying if you are an authentic user
                </span>
              </div>
              <div className="mt-4 mb-4">
                <span className="verify-content fw-500">
                  A verification email was sent to
                  <span
                    className=" ms-1 me-1 fw-600 "
                    style={{
                      color: "#3600A9",
                    }}
                  >
                    {userDetail.values.email}
                  </span>
                  , check your email and click on the link to verify email.
                </span>
              </div>
              {isVerified && (
                <div className="d-flex align-content-center">
                  <span className="me-2">
                    <VerifiedIcon
                      className="verified-icon"
                      sx={{ color: "#4caf50" }}
                    />
                  </span>
                  <span className="email-verified fw-500">Email Verified</span>
                </div>
              )}
              {!isVerified &&
                !emailLoading &&
                {
                  /* <button onClick={()=>sendVerifyEmail(userDetail?.values?.email)} disabled={emailLoading} className={`btn btn-outline-primary rounded-3`}>
                  <span className={emailLoading?`spinner-border spinner-border-sm`:``} >{emailLoading?``:`Resend Verification Email`}</span>
                 </button> */
                }}

              {emailLoading && (
                <div className="w-100 text-center">
                  <div class="spinner-border text-center" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
            </div>

            <div className="d-flex justify-content-between mt-4">
              <button
                className="btn-onboard"
                onClick={() => {
                  // window.location.href = '/registration'
                  setFormikData("email", "");
                  setEmailLoading(false);
                  setStep(4);
                }}
              >
                Change Email
              </button>

              <button
                disabled={!isVerified}
                className={`${
                  isVerified ? "btn-onboard-fill" : "btn-onboard-fill-disabled"
                }`}
                onClick={() => {
                  if (isVerified) {
                    setStep(8);
                    // if(showType==='Register Student'){
                    //   navigate('/student-on-boarding')
                    // }
                    // else if (showType==='Register Teacher'){
                    //   navigate('/teacher-on-boarding')
                    // }
                    // else{

                    //   navigate('/institute-on-boarding')
                    // }
                  }
                }}
              >
                Next{" "}
                <span className="ms-4">
                  <ForwardRounded />
                </span>
              </button>
            </div>
          </div>
        );

      // Login
      case 6:
        return (
          <div>
            <div className="user-select-none">
              <div className="mb-2">
                <span className="sign-in-title">Login</span>
              </div>
              <div>
                <div
                  style={
                    {
                      // marginBottom: '2rem'
                    }
                  }
                  className=""
                >
                  <span
                    className="credentials-content mb-5 d-block"
                    style={{
                      color: "#989898",
                      fontSize: "",
                      fontWeight: "",
                    }}
                  >
                    Enter your credentials to continue
                  </span>
                </div>
                <div className="mb-5">
                  {emailLoading ? (
                    <div className="w-100 text-center">
                      <div class="spinner-border text-center" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    //  <input className='' style={{ boxShadow: 'none', background: '#dcdcdc2b' }} class={`form-control rounded-3 form-control-lg  custom-placeholder ${!emailExistLogin ? 'border-danger border-2' : 'border-0'}`}
                    //   type="email"
                    //   placeholder="Registered Email Address"
                    //   aria-label=".form-control-lg example"
                    //   value={userDetail.values.email}
                    //   onBlur={userDetail.handleBlur}
                    //   onChange={(e) => {
                    //     setFormikData('email', e.target.value)
                    //     setemailLoginExist(true)
                    //     setisSubmitingEmail(false)
                    //   }}
                    // />
                    <Input
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.type === "click") {
                          e.preventDefault();
                          if (userDetail.isValid) {
                            checkIfEmailExistLogIn(userDetail.values.email);
                          }
                        }
                      }}
                      type="email"
                      variant="plain"
                      color={!emailExistLogin ? "danger" : "neutral"}
                      // sx={{

                      //   fontSize: 24,
                      //   padding: 2,
                      //   fontFamily: 'Poppins',
                      //   borderRadius: 15,
                      //   border: '1px solid #f7f2ff',

                      // }}
                      className="custom-placeholder"
                      endDecorator={
                        !emailExistLogin ? (
                          <ErrorOutlineRounded color="error" />
                        ) : (
                          <CheckCircle
                            color={
                              userDetail?.values?.email ? "success" : "disabled"
                            }
                          />
                        )
                      }
                      placeholder="Registered Email Address"
                      aria-label=".form-control-lg example"
                      value={userDetail.values.email}
                      onBlur={userDetail.handleBlur}
                      onChange={(e) => {
                        const email = e.target.value;
                        setFormikData("email", email);
                        setemailLoginExist(true);
                        setisSubmitingEmail(false);

                        // Email validation using a regular expression
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        const isValidEmail = emailRegex.test(email);

                        // You can add additional validation if needed
                        if (!isValidEmail) {
                          setemailLoginExist(false);
                        }
                      }}
                    />
                  )}
                  {userDetail.errors.email && isSubmitingEmail ? (
                    // <span className='text-danger'>{userDetail.errors.email}</span>
                    <StyledAlert
                      type="danger"
                      content={userDetail.errors.email}
                    />
                  ) : null}
                  {!emailExistLogin &&
                    isSubmitingEmail &&
                    !emailLoading &&
                    !userDetail.errors.email && (
                      // <span className='text-danger'>Email Not Exist</span>
                      <StyledAlert
                        type="danger"
                        content="Email does not exist, please check for typos in your email or create a new account"
                      />
                    )}
                </div>
                {/* <div>
                <span className='guest-content'>Not your computer? User Guest mode to Login privately.</span>
                <br />
                <a href='#'>Learn More</a>
              </div> */}
              </div>

              <div className="d-flex justify-content-between align-items-center mt-4 mb-4">
                <Link
                  to={"/registration"}
                  className=""
                  onClick={(e) => {
                    setFormikData("email", "");
                    setisSubmitingEmail(false);

                    window.location.href = "/registration";
                  }}
                >
                  Create Account
                </Link>

                <Button
                  variant="solid"
                  size="lg"
                  className="rounded-3"
                  onClick={(e) => {
                    setisSubmitingEmail(true);
                    if (userDetail.isValid) {
                      checkIfEmailExistLogIn(userDetail.values.email);
                    }
                  }}
                >
                  Next
                  <span className="ms-4">
                    <ForwardRounded />
                  </span>
                </Button>
              </div>
              <div className="row justify-content-center mb-4 ">
                <span
                  style={{
                    fontSize: "19px",
                    color: "grey",
                  }}
                  className="col-3 fw-semibold text-center "
                >
                  OR
                </span>
              </div>
              <div className="row justify-content-center">
                <div className=" col-12  text-center ">
                  <div
                    onClick={() =>
                      (window.location.href = `${process.env.REACT_APP_API_BASE_URL}auth/google`)
                    }
                    style={{ background: "#FAFBFB" }}
                    className="px-4 py-2 shadow-sm d-inline-block rounded-3 cursor-pointer"
                  >
                    <svg
                      style={{ width: "30px", height: "30px" }}
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="100"
                      height="100"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="#FFC107"
                        d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                      ></path>
                      <path
                        fill="#FF3D00"
                        d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                      ></path>
                      <path
                        fill="#4CAF50"
                        d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                      ></path>
                      <path
                        fill="#1976D2"
                        d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                      ></path>
                    </svg>{" "}
                    <span
                      style={{
                        fontSize: "",
                        color: "#717171",
                      }}
                      className="sign-in-google fw-semibold rs-font"
                    >
                      {" "}
                      Login With Google
                    </span>
                  </div>
                  <div>
                    {/* <h2>Login with Google</h2>
    <a href="http://localhost:2100/auth/google">Login with Google</a> */}
                  </div>
                  <div>
                    {/* <GoogleLogin
    clientId={`440877516726-bnd95t54g7lg6j5e35r6k9rrkjn8btp8.apps.googleusercontent.com`}
    buttonText="Log in with Google"
    onSuccess={handleLogin}
    onFailure={handleLogin}
    cookiePolicy={'single_host_origin'}
/> */}
                  </div>
                </div>
              </div>

              <Typography className="fw-bold mt-5 d-md-none text-center">
                Having Trouble?{" "}
                <Link
                  to={"#"}
                  onClick={handleShowModal}
                  className="text-decoration-none"
                >
                  Get Help
                </Link>
              </Typography>
            </div>

            <Modal
              size="lg"
              show={showGetHelpModal}
              onHide={handleClose}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                {/* <Modal.Title id="example-modal-sizes-title-lg"> */}
                <span className="fw-bolder fs-5">
                  Frequently asked questions
                </span>
                {/* </Modal.Title> */}
              </Modal.Header>
              <Modal.Body>
                <div className="mt-3">
                  <span
                    style={{
                      fontWeight: "500",
                    }}
                    classname="me-2"
                  >
                    Having Trouble?
                  </span>
                  &nbsp;
                  <span
                    style={{
                      // color: "#7341e6",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                  >
                    Get Help
                  </span>
                </div>

                <div className="mt-3">
                  <h3 style={{ fontSize: "17px", fontWeight: "600" }}>
                    Contact our dedicated customer support at{" "}
                    <a href="mailto:help@yuvamanthan.org">
                      help@yuvamanthan.org
                    </a>{" "}
                    or call us on 9560771911
                  </h3>
                </div>

                <div>
                  <div className="mt-4">
                    <span style={{ fontSize: "1.3rem", fontWeight: "600" }}>
                      FAQs
                    </span>
                  </div>

                  <Faq />
                </div>
              </Modal.Body>
            </Modal>
          </div>
        );

      // Enter Password
      case 7:
        return (
          <>
            <div className="enter-password-in-div d-flex flex-column col-12 col-md-6 mb-4 ">
              <span className="enter-password-content fw-500 mb-3 d-block">
                Enter Password
              </span>
              <div className="d-flex justify-content-center align-items-center background-grey box-shadow-0 border-0 sm-wfull">
                <input
                  style={{ fontSize: "18px" }}
                  onChange={(e) => setFormikData("password", e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.type === "click") {
                      handleLogIn();
                    }
                  }}
                  placeholder="********"
                  type={showEyeIconEnterPass ? "text" : "password"}
                  className="form-control rounded-3 background-grey box-shadow-0 border-0 sm-wfull ps-3"
                />
                {showEyeIconEnterPass ? (
                  <div className="pe-2">
                    <VisibilityIcon
                      className="cursor-pointer"
                      onClick={handleEyeToogleEnterPass}
                    />
                  </div>
                ) : (
                  <div className="pe-2">
                    <VisibilityOffIcon
                      className="cursor-pointer"
                      onClick={handleEyeToogleEnterPass}
                    />
                  </div>
                )}
              </div>
              <div>
                {showCredential ? (
                  <span className="text-danger">{showCredential}</span>
                ) : null}
              </div>
            </div>

            <div
              className="py-3"
              onClick={() => {
                sendResetPassword(userDetail?.values?.email);
              }}
            >
              <a className="text-primary text-decoration-underline">
                Forgot password?
              </a>
            </div>

            {/* <div className='learn-more-span-div'>
              <span className=''>Not your computer? User Guest mode to Login privately.  <a href='#'>Learn More</a></span>
            </div> */}

            <div className="d-flex justify-content-between  mt-4">
              <button
                onClick={() => {
                  setFormikData("password", "");
                  setStep(6);
                }}
                className="btn-onboard"
              >
                Previous
              </button>
              <button
                onClick={() => {
                  handleLogIn();
                }}
                className="btn-onboard-fill"
              >
                <span>Next Step</span>
                <img
                  className="ms-2"
                  src="./images/onBoarding/right-icon.png"
                />
              </button>
            </div>
          </>
        );

      // create password
      case 8:
        return (
          <>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-2">
                <span className="sign-in-title fw-600">Set Password</span>
              </div>
              <div className="mb-50">
                <span className="credentials-content fw-500 color-grey">
                  Create a strong password for your yuvamanthan account
                </span>
              </div>

              <div className="d-flex flex-column mb-4">
                <div className="d-flex flex-column col-12 col-md-6 mb-4">
                  <span className="new-confirm-password fw-500 mb-3 d-block">
                    New Password
                  </span>
                  <div className="d-flex justify-content-center align-items-center background-grey box-shadow-0 border-0">
                    <input
                      style={{ fontSize: "18px" }}
                      onChange={(e) =>
                        formik.setFieldValue("newPassword", e.target.value)
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent form submission on Enter key press
                          document
                            .getElementById("confirmPasswordInput")
                            .focus();
                        }
                      }}
                      placeholder="********"
                      type={showEyeIconNew ? "text" : "password"}
                      className="form-control letter-spacing-5px rounded-3 background-grey box-shadow-0 border-0 ps-3"
                    />
                    {showEyeIconNew ? (
                      <div className="pe-2">
                        <VisibilityIcon
                          className="cursor-pointer"
                          onClick={handleEyeToogleNew}
                        />
                      </div>
                    ) : (
                      <div className="pe-2">
                        <VisibilityOffIcon
                          className="cursor-pointer"
                          onClick={handleEyeToogleNew}
                        />
                      </div>
                    )}
                  </div>

                  {/* <div><VisibilityOffIcon onClick={handleEyeToogle}/></div> */}
                  {formik.errors.newPassword && formik.touched.newPassword && (
                    <span className="text-danger">
                      {formik.errors.newPassword}
                    </span>
                  )}
                </div>
                <div className="d-flex flex-column col-12 col-md-6">
                  <span className="new-confirm-password fw-500 mb-3 d-block">
                    Confirm Password
                  </span>
                  <div className="d-flex justify-content-center align-items-center  background-grey box-shadow-0 border-0">
                    <input
                      id="confirmPasswordInput"
                      style={{ fontSize: "18px" }}
                      onChange={(e) => {
                        formik.setFieldValue("confirmPassword", e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          formik.handleSubmit();
                        }
                      }}
                      placeholder="********"
                      type={showEyeIconConfirm ? "text" : "password"}
                      className="form-control letter-spacing-5px rounded-3 background-grey box-shadow-0 border-0 ps-3"
                    />
                    {showEyeIconConfirm ? (
                      <div className="pe-2">
                        <VisibilityIcon
                          className="cursor-pointer"
                          onClick={handleEyeToogleConfirm}
                        />
                      </div>
                    ) : (
                      <div className="pe-2">
                        <VisibilityOffIcon
                          className="cursor-pointer"
                          onClick={handleEyeToogleConfirm}
                        />
                      </div>
                    )}
                  </div>
                  {formik.errors.confirmPassword &&
                    formik.touched.confirmPassword && (
                      <span className="text-danger">
                        {formik.errors.confirmPassword}
                      </span>
                    )}
                </div>
              </div>

              <div className=" d-flex background-purple p-3 rounded-3 border-purple">
                <span>
                  {" "}
                  <img className="me-2" src="./images/onBoarding/note.png" />
                </span>
                <span className="signup-content-password color-purple fw-500">
                  Your password must include at least 1 uppercase letter, 1
                  lowercase letter, 1 symbol, and 1 number.
                </span>
              </div>

              <div className="d-flex justify-content-between  mt-4">
                {googleUserType && authStep && googleEmail ? (
                  <button
                    onClick={() => {
                      userDetail.setFieldValue("email", "");
                      navigate("/login");
                    }}
                    className="btn-onboard"
                  >
                    SignIn
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setFormikData("password", "");
                      setStep(6);
                    }}
                    className="btn-onboard"
                  >
                    Previous
                  </button>
                )}
                {newStudentLoader ? (
                  <div className="text-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <button type="submit" className="btn-onboard-fill">
                    <span>Next Step</span>
                    <img
                      className="ms-2"
                      src="./images/onBoarding/right-icon.png"
                    />
                  </button>
                )}
              </div>
            </form>
          </>
        );

      default:
        return null;
    }
  };
  return (
    <>
      <div className="container ">
        <div className="card border-0">
          <div className="card-body vh-100 p-0">{renderStepContent()}</div>
        </div>
      </div>
    </>
  );
};
