import { Box, FormControl, Grid, InputLabel, MenuItem, Radio, Typography, RadioGroup, FormControlLabel, Select, Container, Button } from "@mui/material";
// import ModeIcon from '@mui/icons-material/Edit';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import React, { useState } from "react";
// import { Radio } from "@mui/icons-material";


const HealthThirdPage = ({onClick, onSubmit}) => {

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
        padding: "1rem",
        overflow: "auto",
        "@media (max-width: 900px)": {
          padding: "0.5rem",
        },
      }}
    >
    <Box
        sx={{
          fontSize: "1.25rem",
          padding: "2rem",
          "@media (max-width: 900px)": {
            padding: "1rem",
          },
        }}
    >
        <Box
          sx={{
            borderRadius: "1rem",
            backgroundColor: "#f0f0f0",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "2rem",
            boxSizing: "border-box",
            "@media (max-width: 900px)": {
              width: "100%",
              padding: "1rem",
            },
          }}
        >
          <div className="lets-begin-your-first-day-cha-parent">
            <Typography
              component="b"
              sx={{
                fontSize: "1.25rem",
                fontWeight: 700,
                // fontFamily: "cursive",
              }}
              className="preview"
            >
              CBSE circular no.Acad-I012018
            </Typography>
            <img className="tick-icon" alt="" src="/tick.svg" />
          </div>
          <div className="click-here-to-container">
            <Typography
              component="span"
              sx={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              className="click-here"
            >
              Click here
            </Typography>
            <Typography component="span" className="to-know-more">
              {" "}
              to know more.
            </Typography>
          </div>
        </Box>

        <Box
          sx={{
            marginTop: "2rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "2rem 2rem 1rem 1.5rem",
            borderRadius: "1rem",
            backgroundColor: "#FFFFFF",
            border: "5px solid #2356fe",
            boxSizing: "border-box",
            width: "100%",
            overflow: "hidden",
            "@media (max-width: 900px)": {
              padding: "1rem",
            },
          }}
        >
            <Box
                sx={{marginLeft: "2rem"}}
            >
                <Typography
                    sx={{
                        fontSize: "1.875rem",
                        fontWeight: 600,
                        color: "blue",
                        // fontFamily: "cursive",
                    }}
                        className="hello_name"
                >  
                    General Information
                </Typography>
            </Box>

            <Box
                sx={{
                    padding: "2rem",
                }}
            >
                <Typography
                    sx={{
                        fontSize: "1.875rem",
                        fontWeight: 600,
                        // fontFamily: "cursive",
                    }}
                    className="mo_of"
                >  
                    Student Health and Activity Record
                </Typography>
            </Box>
            <Container>
      <Grid container spacing={2}>

        {/* Vision Section */}
        <Grid item xs={12}>
          <Typography variant="h5">Vision <span style={{color: 'red'}}>*</span> <span><HelpOutlineIcon/></span></Typography>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
          <Typography variant="h6">Right Eye</Typography>
          <FormControl fullWidth>
            <InputLabel>Right Eye</InputLabel>
            <Select>
              <MenuItem value="20/20">20/20</MenuItem>
              <MenuItem value="20/40">20/40</MenuItem>
              <MenuItem value="20/60">20/60</MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
          <Typography variant="h6">Left Eye</Typography>
          <FormControl fullWidth>
            <InputLabel>Left Eye</InputLabel>
            <Select>
              <MenuItem value="20/20">20/20</MenuItem>
              <MenuItem value="20/40">20/40</MenuItem>
              <MenuItem value="20/60">20/60</MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Grid>

        {/* Ears Section */}
        <Grid item xs={12}>
          <Typography variant="h5">Ears <span style={{color: 'red'}}>*</span> <span><HelpOutlineIcon/></span></Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Left</Typography>
          <FormControl component="fieldset">
            <RadioGroup row>
              <FormControlLabel value="normal" control={<Radio />} label="Normal" />
              <FormControlLabel value="abnormal" control={<Radio />} label="Abnormal" />
            </RadioGroup>
          </FormControl>
          </Box>
        </Grid>
        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Right</Typography>
          <FormControl component="fieldset">
            <RadioGroup row>
              <FormControlLabel value="normal" control={<Radio />} label="Normal" />
              <FormControlLabel value="abnormal" control={<Radio />} label="Abnormal" />
            </RadioGroup>
          </FormControl>
          </Box>
        </Grid>

        {/* Teeth Section */}
        <Grid item xs={12}>
          <Typography variant="h5">Teeth <span style={{color: 'red'}}>*</span> <span><HelpOutlineIcon/></span></Typography>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Occulsion</Typography>
          <FormControl component="fieldset">
            <RadioGroup row>
              <FormControlLabel value="normal" control={<Radio />} label="Normal" />
              <FormControlLabel value="abnormal" control={<Radio />} label="Abnormal" />
            </RadioGroup>
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Caries</Typography>
          <FormControl component="fieldset">
            <RadioGroup row>
              <FormControlLabel value="normal" control={<Radio />} label="Normal" />
              <FormControlLabel value="absent" control={<Radio />} label="Absent" />
            </RadioGroup>
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Tonsils</Typography>
          <FormControl component="fieldset">
            <RadioGroup row>
              <FormControlLabel value="present" control={<Radio />} label="Present" />
              <FormControlLabel value="absent" control={<Radio />} label="Absent" />
            </RadioGroup>
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Gums</Typography>
          <FormControl component="fieldset">
            <RadioGroup row>
              <FormControlLabel value="healthy" control={<Radio />} label="Healthy" />
              <FormControlLabel value="unhealthy" control={<Radio />} label="Unhealthy" />
            </RadioGroup>
          </FormControl>
          </Box>
        </Grid>


        <Grid item xs={12}>
          <Typography variant="h5">Circumference <span style={{color: 'red'}}>*</span> <span><HelpOutlineIcon/></span></Typography>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Hip</Typography>
          <FormControl fullWidth>
            <InputLabel>cm</InputLabel>
            <Select>
              <MenuItem value="20/20">20/20</MenuItem>
              <MenuItem value="20/40">20/40</MenuItem>
              <MenuItem value="20/60">20/60</MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Waist</Typography>
          <FormControl fullWidth>
            <InputLabel>cm</InputLabel>
            <Select>
              <MenuItem value="20/20">20/20</MenuItem>
              <MenuItem value="20/40">20/40</MenuItem>
              <MenuItem value="20/60">20/60</MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Grid>


        <Grid item xs={12}>
          <Typography variant="h5">Health Status <span style={{color: 'red'}}>*</span> <span><HelpOutlineIcon/></span></Typography>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Pulse</Typography>
          <FormControl fullWidth>
            <InputLabel>bpm</InputLabel>
            <Select>
              <MenuItem value="20/20">20/20</MenuItem>
              <MenuItem value="20/40">20/40</MenuItem>
              <MenuItem value="20/60">20/60</MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Blood Presure (Systolic)</Typography>
          <FormControl fullWidth>
            <InputLabel>mmhg</InputLabel>
            <Select>
              <MenuItem value="20/20">20/20</MenuItem>
              <MenuItem value="20/40">20/40</MenuItem>
              <MenuItem value="20/60">20/60</MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Blood Presure (Diastolic)</Typography>
          <FormControl fullWidth>
            <InputLabel>mmhg</InputLabel>
            <Select>
              <MenuItem value="20/20">20/20</MenuItem>
              <MenuItem value="20/40">20/40</MenuItem>
              <MenuItem value="20/60">20/60</MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Grid>
        {/* Posture Evaluation */}
        <Grid item xs={12}>
          <Typography variant="h5">Posture Evaluation <span style={{color: 'red'}}>*</span> <span><HelpOutlineIcon/></span></Typography>
        </Grid>
        
        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Head Forward</Typography>
          <FormControl component="fieldset">
            <RadioGroup row>
              <FormControlLabel value="normal" control={<Radio />} label="True" />
              <FormControlLabel value="abnormal" control={<Radio />} label="False" />
            </RadioGroup>
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Sunken Chest</Typography>
          <FormControl component="fieldset">
            <RadioGroup row>
              <FormControlLabel value="normal" control={<Radio />} label="True" />
              <FormControlLabel value="abnormal" control={<Radio />} label="False" />
            </RadioGroup>
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Round Shoulders</Typography>
          <FormControl component="fieldset">
            <RadioGroup row>
              <FormControlLabel value="normal" control={<Radio />} label="True" />
              <FormControlLabel value="abnormal" control={<Radio />} label="False" />
            </RadioGroup>
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Kyphosis</Typography>
          <FormControl component="fieldset">
            <RadioGroup row>
              <FormControlLabel value="normal" control={<Radio />} label="True" />
              <FormControlLabel value="abnormal" control={<Radio />} label="False" />
            </RadioGroup>
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Lordosis</Typography>
          <FormControl component="fieldset">
            <RadioGroup row>
              <FormControlLabel value="normal" control={<Radio />} label="True" />
              <FormControlLabel value="abnormal" control={<Radio />} label="False" />
            </RadioGroup>
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Abdominal Ptosis</Typography>
          <FormControl component="fieldset">
            <RadioGroup row>
              <FormControlLabel value="normal" control={<Radio />} label="True" />
              <FormControlLabel value="abnormal" control={<Radio />} label="False" />
            </RadioGroup>
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Body Lean</Typography>
          <FormControl component="fieldset">
            <RadioGroup row>
              <FormControlLabel value="normal" control={<Radio />} label="True" />
              <FormControlLabel value="abnormal" control={<Radio />} label="False" />
            </RadioGroup>
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Tilted Head</Typography>
          <FormControl component="fieldset">
            <RadioGroup row>
              <FormControlLabel value="normal" control={<Radio />} label="True" />
              <FormControlLabel value="abnormal" control={<Radio />} label="False" />
            </RadioGroup>
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Shoulders Uneven</Typography>
          <FormControl component="fieldset">
            <RadioGroup row>
              <FormControlLabel value="normal" control={<Radio />} label="True" />
              <FormControlLabel value="abnormal" control={<Radio />} label="False" />
            </RadioGroup>
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Scoliosis</Typography>
          <FormControl component="fieldset">
            <RadioGroup row>
              <FormControlLabel value="normal" control={<Radio />} label="True" />
              <FormControlLabel value="abnormal" control={<Radio />} label="False" />
            </RadioGroup>
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Flat Feet</Typography>
          <FormControl component="fieldset">
            <RadioGroup row>
              <FormControlLabel value="normal" control={<Radio />} label="True" />
              <FormControlLabel value="abnormal" control={<Radio />} label="False" />
            </RadioGroup>
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
          <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
        <Typography variant="h6">Knock Knees</Typography>
          <FormControl component="fieldset">
            <RadioGroup row>
              <FormControlLabel value="normal" control={<Radio />} label="True" />
              <FormControlLabel value="abnormal" control={<Radio />} label="False" />
            </RadioGroup>
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={6} sm={3}>
          <Box sx={{
            border: "1px solid black",
            borderRadius: "0.75rem",
            padding: "1rem",
          }}>
            <Typography variant="h6">Bow Legs</Typography>
            <FormControl component="fieldset">
              <RadioGroup row>
                <FormControlLabel value="normal" control={<Radio />} label="True" />
                <FormControlLabel value="abnormal" control={<Radio />} label="False" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
      </Grid>

        {/* Fitness Components */}
        <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: "2rem 0rem",
        }}>
        <Box>
        <Grid item xs={12}>
          <Typography variant="h5">Fitness Components <span style={{color: 'red'}}>*</span> <span><HelpOutlineIcon/></span></Typography>
        </Grid>
        <Box sx={{display: "flex", justifyContent: "space-between",}}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
          <Typography variant="h6">Body Composition (BMI)</Typography>
          <FormControl fullWidth>
            <InputLabel>bmi</InputLabel>
            <Select>
              <MenuItem value="20/20">20/20</MenuItem>
              <MenuItem value="20/40">20/40</MenuItem>
              <MenuItem value="20/60">20/60</MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Box>
      </Box>

        {/* Muscular Strength */}
        <Box>
        <Grid item xs={12}>
          <Typography variant="h5">Muscular Strength <span style={{color: 'red'}}>*</span> <span><HelpOutlineIcon/></span></Typography>
        </Grid>

        <Box sx={{display: "flex",}}>
        <Box sx={{
          border: "1px solid black",
          borderRadius: "0.75rem",
          padding: "1rem",
          marginRight: "2rem",
        }}>
          <Typography variant="h6">Core Partial Curl-up</Typography>
          <FormControl fullWidth>
            <InputLabel>value</InputLabel>
            <Select>
              <MenuItem value="20/20">20/20</MenuItem>
              <MenuItem value="20/40">20/40</MenuItem>
              <MenuItem value="20/60">20/60</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box sx={{
          border: "1px solid black",
          borderRadius: "0.75rem",
          padding: "1rem",
        }}>
          <Typography variant="h6">Upper Body Flexed/Bent Arm Hang</Typography>
          <FormControl fullWidth>
            <InputLabel>value</InputLabel>
            <Select>
              <MenuItem value="20/20">20/20</MenuItem>
              <MenuItem value="20/40">20/40</MenuItem>
              <MenuItem value="20/60">20/60</MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Box>
        </Box>
      </Box>

        {/* Flexibility */}
      <Box sx={{display: "flex", justifyContent: "space-between", margin: "2rem 0rem"}}>
        <div>
        <Grid item xs={12}>
          <Typography variant="h5">Flexibility <span style={{color: 'red'}}>*</span> <span><HelpOutlineIcon/></span></Typography>
        </Grid>

        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
          <Typography variant="h6">Sit and Reach</Typography>
          <FormControl fullWidth>
            <InputLabel>cm</InputLabel>
            <Select>
              <MenuItem value="20/20">20/20</MenuItem>
              <MenuItem value="20/40">20/40</MenuItem>
              <MenuItem value="20/60">20/60</MenuItem>
            </Select>
          </FormControl>
          </Box>
          </div>

        {/* Endurance */}
        <div>
        <Grid item xs={12}>
          <Typography variant="h5">Endurance <span style={{color: 'red'}}>*</span> <span><HelpOutlineIcon/></span></Typography>
        </Grid>

        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
          <Typography variant="h6">600 Meter Run</Typography>
          <FormControl fullWidth>
            <InputLabel>Seconds</InputLabel>
            <Select>
              <MenuItem value="20/20">20/20</MenuItem>
              <MenuItem value="20/40">20/40</MenuItem>
              <MenuItem value="20/60">20/60</MenuItem>
            </Select>
          </FormControl>
          </Box>
          </div>

        {/* Balance */}
        <div>
        <Grid item xs={12}>
          <Typography variant="h5">Balance <span style={{color: 'red'}}>*</span> <span><HelpOutlineIcon/></span></Typography>
        </Grid>

        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
          <Typography variant="h6">Flamingo Balance Test</Typography>
          <FormControl fullWidth>
            <InputLabel>Seconds</InputLabel>
            <Select>
              <MenuItem value="20/20">20/20</MenuItem>
              <MenuItem value="20/40">20/40</MenuItem>
              <MenuItem value="20/60">20/60</MenuItem>
            </Select>
          </FormControl>
          </Box>
          </div>
        </Box>

        <Box sx={{display: "flex", justifyContent: "space-between", margin: "2rem 0rem"}}>
        {/* Agility */}
        <div>
        <Grid item xs={12}>
          <Typography variant="h5">Agility <span style={{color: 'red'}}>*</span> <span><HelpOutlineIcon/></span></Typography>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
          <Typography variant="h6">Shuttle Run</Typography>
          <FormControl fullWidth>
            <InputLabel>Seconds</InputLabel>
            <Select>
              <MenuItem value="20/20">20/20</MenuItem>
              <MenuItem value="20/40">20/40</MenuItem>
              <MenuItem value="20/60">20/60</MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Grid>
        </div>

        {/* Speed */}
        <div>
        <Grid item xs={12}>
          <Typography variant="h5">Speed <span style={{color: 'red'}}>*</span> <span><HelpOutlineIcon/></span></Typography>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
          <Typography variant="h6">Sprint/Dash</Typography>
          <FormControl fullWidth>
            <InputLabel>Seconds</InputLabel>
            <Select>
              <MenuItem value="20/20">20/20</MenuItem>
              <MenuItem value="20/40">20/40</MenuItem>
              <MenuItem value="20/60">20/60</MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Grid>
        </div>

        {/* Power */}
        <div>
        <Grid item xs={12}>
          <Typography variant="h5">Power <span style={{color: 'red'}}>*</span> <span><HelpOutlineIcon/></span></Typography>
        </Grid>

        <Grid item xs={6} sm={3}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
          <Typography variant="h6">Standing Vertical Jump</Typography>
          <FormControl fullWidth>
            <InputLabel>cm</InputLabel>
            <Select>
              <MenuItem value="20/20">20/20</MenuItem>
              <MenuItem value="20/40">20/40</MenuItem>
              <MenuItem value="20/60">20/60</MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Grid>
        </div>
      </Box>

        {/* Coordination */}
      <Box sx={{margin: "2rem 0rem"}}>
        <Grid item xs={12}>
          <Typography variant="h5">Coordination <span style={{color: 'red'}}>*</span> <span><HelpOutlineIcon/></span></Typography>
        </Grid>
        <Box sx={{display: "flex"}}>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
              marginRight: "2rem",
          }}>
          <Typography variant="h6">Plate Tapping</Typography>
          <FormControl fullWidth>
            <InputLabel>Seconds</InputLabel>
            <Select>
              <MenuItem value="20/20">20/20</MenuItem>
              <MenuItem value="20/40">20/40</MenuItem>
              <MenuItem value="20/60">20/60</MenuItem>
            </Select>
          </FormControl>
          </Box>

        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
          }}>
          <Typography variant="h6">Alternative Hand Wall Toss Test</Typography>
          <FormControl fullWidth>
            <InputLabel>Catches</InputLabel>
            <Select>
              <MenuItem value="20/20">20/20</MenuItem>
              <MenuItem value="20/40">20/40</MenuItem>
              <MenuItem value="20/60">20/60</MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Box>
        </Box>

      <Box>
        <Grid item xs={12}>
          <Typography variant="h5">Posture Evaluation <span style={{color: 'red'}}>*</span> <span><HelpOutlineIcon/></span></Typography>
        </Grid>
        
        <div>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
              width: "fit-content",
          }}>
          <Typography variant="h6" color="grey">Strand 1</Typography>
          <FormControl component="fieldset">
            <RadioGroup row>
              <FormControlLabel value="ath" control={<Radio />} label="ATHLETICS/SWIMMING" />
              <FormControlLabel value="tgame" control={<Radio />} label="TEAM GAME" />
              <FormControlLabel value="igame" control={<Radio />} label="INDIVIDUAL GAME" />
              <FormControlLabel value="adv" control={<Radio />} label="ADVENTURE SPORTS" />
            </RadioGroup>
          </FormControl>
        </Box>
        </div>

        <div>
        <Box sx={{
              border: "1px solid black",
              borderRadius: "0.75rem",
              padding: "1rem",
              margin: "1rem 0",
          }}>
          <Typography variant="h6" color="grey">Strand 2</Typography>
          <FormControl component="fieldset">
            <RadioGroup row>
              <FormControlLabel value="1" control={<Radio />} label="MASS PT" />
              <FormControlLabel value="2" control={<Radio />} label="YOGA" />
              <FormControlLabel value="3" control={<Radio />} label="DANCE" />
              <FormControlLabel value="4" control={<Radio />} label="CALISTHENICS" />
              <FormControlLabel value="5" control={<Radio />} label="JOGGING" />
              <FormControlLabel value="6" control={<Radio />} label="CROSS COUNTRY RUN" />
              <FormControlLabel value="7" control={<Radio />} label="WORKOUTS USING WEIGHTS/GYM EQUIPMENT" />
              <FormControlLabel value="8" control={<Radio />} label="TAI-CHI" />
              <FormControlLabel value="9" control={<Radio />} label="OTHERS" />
            </RadioGroup>
          </FormControl>
          </Box>
        </div>
        </Box>

        <Box sx={{display: "flex",
          justifyContent: "space-between"
        }}>
          {/* Previous Button */}
          <Grid item xs={12}>
            <Button onClick = {() => onClick()} type="previous" variant="contained" color="primary" sx={{ marginTop: '2rem', padding: "1rem 3.5rem", }}>
              PREVIOUS
            </Button>
          </Grid>
        
          {/* Submit Button */}
          <Grid item xs={12}>
            <Button onSubmit = {() => {onSubmit()}} type="submit" variant="contained" color="primary" sx={{ marginTop: '2rem', padding: "1rem 3.5rem", }}>
              SUBMIT
            </Button>
          </Grid>
        </Box>
    
    </Container>
          </Box>
      </Box>
    </Box>
  );
};

export default HealthThirdPage;
