import React, { useState } from "react";

const LawnAreaQuestion = () => {
  const questionData = {
    id: 42,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    text: "What is the approximate area of lawns with green grass in your school that requires regular watering? ",
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: "0",
        correct: false,
        riskText: "",
      },
    ],
  };

  const [lawnArea, setLawnArea] = useState("");

  const handleLawnAreaChange = (event) => {
    setLawnArea(event.target.value);
  };

  // CSS Styles
  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    lineHeight: "1.6",
    margin: "20px 40px",
    color: "#333",
  };

  const questionTitleStyle = {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "16px",
  };

  const labelStyle = {
    display: "block",
    marginBottom: "8px",
    fontSize: "14px",
    marginLeft: "60px"
  };

  const inputStyle = {
    padding: "5px",
    fontSize: "14px",
    width: "calc(100% - 120px)",
    marginLeft: "60px"
  };

  return (
    <div style={containerStyle}>
      <h1 style={questionTitleStyle}>Q12. {questionData.text}</h1>
      <p style={{ marginBottom: "16px" }}>
        (Take help of Google Maps to calculate the area of lawns) Provide units.
      </p>
      <label htmlFor="lawnAreaInput" style={labelStyle}>
        Area of lawns (with units):
      </label>
      <input
        type="text"
        id="lawnAreaInput"
        value={lawnArea}
        onChange={handleLawnAreaChange}
        placeholder="Enter the area (e.g., 500 sq. meters)"
        style={inputStyle}
      />
    </div>
  );
};

export default LawnAreaQuestion;
