import React, { useState } from "react";

const WaterSourceQuestion = () => {
  const questionData = {
    id: 32,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    text: "Q2. What source/s does your school draw water from?",
    options: [
      { text: "Municipal water", score: "1", correct: false },
      { text: "Ground water (Bore well)", score: "1", correct: false },
      { text: "Packed water", score: "1", correct: false },
      { text: "Tanker", score: "1", correct: false },
      { text: "Any other (please specify)", score: "1", correct: false },
    ],
  };

  const [formData, setFormData] = useState({
    waterSourceMunicipal: "",
    waterSourceGroundWater: "",
    waterSourcePackedWater: "",
    waterSourceTanker: "",
    waterSourceOther: "",
  });

  const handleOptionChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      fontSize: "14px",
      lineHeight: "1.6",
      margin: "20px 40px",
      color: "#333",
    },
    questionTitle: {
      fontSize: "16px",
      marginBottom: "16px",
      fontWeight: "bold",
    },
    section: {
      marginBottom: "20px",
      marginLeft: "60px",
    },
    label: {
      fontSize: "14px",
      marginBottom: "8px",
      display: "block",
    },
    inputGroup: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      marginTop: "6px",
    },
    input: {
      marginRight: "6px",
    },
    textInput: {
      fontSize: "14px",
      padding: "8px",
      marginTop: "8px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      width: "100%",
      boxSizing: "border-box",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.questionTitle}>{questionData.text}</h1>
      <form>
        {questionData.options.map((option, index) => (
          <div key={index} style={styles.section}>
            <label style={styles.label}>{option.text}</label>
            <div style={styles.inputGroup}>
              <label>
                <input
                  type="radio"
                  name={`waterSource${option.text.replace(/\s+/g, "")}`}
                  value="Yes"
                  checked={
                    formData[
                      `waterSource${option.text.replace(/\s+/g, "")}`
                    ] === "Yes"
                  }
                  onChange={handleOptionChange}
                  style={styles.input}
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name={`waterSource${option.text.replace(/\s+/g, "")}`}
                  value="No"
                  checked={
                    formData[
                      `waterSource${option.text.replace(/\s+/g, "")}`
                    ] === "No"
                  }
                  onChange={handleOptionChange}
                  style={styles.input}
                />
                No
              </label>
            </div>
          </div>
        ))}
        <div style={styles.section}>
          <label style={styles.label}>
            Any other (please specify)
            <input
              type="text"
              name="waterSourceOther"
              value={formData.waterSourceOther}
              onChange={handleOptionChange}
              placeholder="Specify here"
              style={styles.textInput}
            />
          </label>
        </div>
      </form>
    </div>
  );
};

export default WaterSourceQuestion;
