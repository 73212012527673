import React, { useState } from "react";

const WaterHarvestUsage = () => {
  const questionData = {
    id: 48,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    text: "How is the water harvested utilized?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Water18",
    media_type: "",
    images: null,
    options: [
      {
        text: "Gardening",
        score: "1",
        correct: false,
      },
      {
        text: "Toilets",
        score: "2",
        correct: false,
      },
      {
        text: "Cleaning of school",
        score: "3",
        correct: false,
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
    fields: [
      {
        fieldType: "checkbox",
        name: "waterUsage",
        label: "Internal recycling in school campus applications such as:",
        options: [
          {
            label: "Gardening",
            value: "Gardening",
          },
          {
            label: "Toilets",
            value: "Toilets",
          },
          {
            label: "Cleaning of school",
            value: "Cleaning of school",
          },
        ],
      },
    ],
  };

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.includes(value)
        ? prevSelectedOptions.filter((option) => option !== value)
        : [...prevSelectedOptions, value]
    );
  };

  // CSS Styles (from WaterSourceQuestion)
  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    lineHeight: "1.6",
    margin: "20px 40px",
    color: "#333",
  };

  const sectionStyle = {
    marginBottom: "20px",
    marginLeft: "60px", // Added margin left for consistency
  };

  const labelStyle = {
    display: "block",
    marginBottom: "8px",
    fontSize: "14px", // Consistent font size
  };

  const checkboxContainerStyle = {
    display: "flex",
    flexDirection: "column",
    marginLeft: "60px", // Adds a consistent margin for checkboxes
  };

  return (
    <div style={containerStyle}>
      <h1 style={{ fontSize: "16px", marginBottom: "16px", fontWeight: "bold" }}>
        Q18. {questionData.text}
      </h1>

      {/* Adding fontWeight: bold to this label */}
      <label
        htmlFor="waterUsage"
        style={{
          ...labelStyle,
          marginLeft: "60px",
          fontWeight: "bold", // Makes the label text bold
        }}
      >
        {questionData.fields[0].label}
      </label>

      <div style={checkboxContainerStyle}>
        {questionData.fields[0].options.map((option, index) => (
          <label key={index} style={labelStyle}>
            <input
              type="checkbox"
              name="waterUsage"
              value={option.value}
              checked={selectedOptions.includes(option.value)}
              onChange={handleCheckboxChange}
              style={{ marginRight: "10px" }} // Maintain spacing between checkbox and label
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  );
};

export default WaterHarvestUsage;
