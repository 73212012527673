import React, { useState } from "react";

const EnergySourcesQuestion = () => {
  const [selectedMainSource, setSelectedMainSource] = useState("");

  const handleMainSourceChange = (source) => {
    setSelectedMainSource(source);
  };

  const isElectricitySelected = selectedMainSource === "electricity";
  const isGasSelected = selectedMainSource === "gas";

  // Consistent CSS styles
  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    lineHeight: "1.6",
    margin: "20px 40px",
    color: "#333",
  };

  const sectionStyle = {
    marginBottom: "20px",
    marginLeft: "40px", // Added margin-left for consistency
  };

  const labelStyle = {
    display: "block",
    marginBottom: "8px",
    fontSize: "14px",
  };

  const inputStyle = {
    marginRight: "10px",
  };

  const textareaStyle = {
    display: "block",
    width: "100%",
    maxWidth: "400px",
    marginTop: "5px",
    marginBottom: "10px",
    fontSize: "14px",
    padding: "6px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  };

  const nestedListStyle = {
    marginLeft: "40px", // Updated margin for consistency
    listStyleType: "none",
  };

  const questionTitleStyle = {
    fontSize: "16px",
    marginBottom: "16px",
    fontWeight: "bold",
  };

  return (
    <div style={containerStyle}>
      <h1 style={questionTitleStyle}>
        Q1. What are the various source(s) of energy used in school? (Please tick in the box)
      </h1>
      <ol type="A" style={{ paddingLeft: "20px" }}>
        {/* Section A: Electricity */}
        <li style={sectionStyle}>
          <label>
            <input
              type="radio"
              id="electricity-heading"
              name="main-source"
              value="electricity"
              checked={isElectricitySelected}
              onChange={() => handleMainSourceChange("electricity")}
              style={inputStyle}
            />
            <strong>Electricity</strong>
          </label>
          <ul style={nestedListStyle}>
            <li>
              <label>
                <input
                  type="radio"
                  id="electricity-company"
                  name="electricity"
                  disabled={!isElectricitySelected}
                  style={inputStyle}
                />
                Electricity (from Municipality/Electrical Company)
              </label>
            </li>
            <li>
              <label>
                <input
                  type="radio"
                  id="electricity-generator"
                  name="electricity"
                  disabled={!isElectricitySelected}
                  style={inputStyle}
                />
                Electricity from generator
              </label>
              <div style={{ marginLeft: "40px", marginTop: "10px" }}>
                <p>If a generator is used, specify the fuel used:</p>
                <ul style={{ listStyleType: "lower-alpha", marginTop: "5px" }}>
                  <li>
                    <label htmlFor="fuel-amount" style={labelStyle}>
                      Amount of Fuel used in a month:
                    </label>
                    <textarea
                      id="fuel-amount"
                      name="fuel-amount"
                      rows="1"
                      placeholder="Enter amount of fuel"
                      style={textareaStyle}
                      disabled={!isElectricitySelected}
                    ></textarea>
                  </li>
                  <li>
                    <label htmlFor="generator-capacity" style={labelStyle}>
                      Generator Capacity:
                    </label>
                    <textarea
                      id="generator-capacity"
                      name="generator-capacity"
                      rows="1"
                      placeholder="Enter generator capacity"
                      style={textareaStyle}
                      disabled={!isElectricitySelected}
                    ></textarea>
                  </li>
                  <li>
                    <label style={{ marginBottom: "5px" }}>
                      Frequency of use of the generator:
                    </label>
                    <ul style={{ listStyleType: "none", paddingLeft: "20px" }}>
                      <li>
                        <label>
                          <input
                            type="radio"
                            id="emergency-use"
                            name="frequency"
                            disabled={!isElectricitySelected}
                            style={inputStyle}
                          />
                          Only in an emergency (in case of electricity supply failure)
                        </label>
                      </li>
                      <li>
                        <label>
                          <input
                            type="radio"
                            id="regular-use"
                            name="frequency"
                            disabled={!isElectricitySelected}
                            style={inputStyle}
                          />
                          Regularly (Specify purposes and duration)
                        </label>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </li>

        {/* Section B: Gas */}
        <li style={sectionStyle}>
          <label>
            <input
              type="radio"
              id="gas-heading"
              name="main-source"
              value="gas"
              checked={isGasSelected}
              onChange={() => handleMainSourceChange("gas")}
              style={inputStyle}
            />
            <strong>Gas</strong>
          </label>
          <ul style={nestedListStyle}>
            <li>
              <label>
                <input
                  type="radio"
                  id="gas-cylinder"
                  name="gas"
                  disabled={!isGasSelected}
                  style={inputStyle}
                />
                Gas cylinders
              </label>
            </li>
            <li>
              <label>
                <input
                  type="radio"
                  id="piped-gas"
                  name="gas"
                  disabled={!isGasSelected}
                  style={inputStyle}
                />
                Piped National Gas (PNG)
              </label>
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
};

export default EnergySourcesQuestion;
