import React from "react";

const TransportModes = () => {
  const questionData = {
    id: 67,
    complianceId: 3,
    categoryId: 8,
    category: "Transport",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Q2. Write the approximate percentage of the school students who travel to and from school by various modes of transportation.",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "(You will need to do a survey in school by asking the students. You can as well use a Google form or Survey Monkey to get estimates. For junior students, please visit the classrooms and talk to the students regarding the same.)",
    question_type: "PercentageInput",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Walkers",
        inputType: "number",
      },
      {
        risk: false,
        text: "Bicycle",
        inputType: "number",
      },
      {
        risk: false,
        text: "School transport",
        inputType: "number",
      },
      {
        risk: false,
        text: "Private transport/personal vehicle",
        inputType: "number",
      },
      {
        risk: false,
        text: "Public vehicle",
        inputType: "number",
      },
      {
        risk: false,
        text: "Any other",
        inputType: "text", // Only one input retained here
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  };

  // CSS Styles (adjusted flex layout)
  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    lineHeight: "1.4",
    margin: "20px 40px",
    color: "#333",
  };

  const sectionStyle = {
    display: "flex", // Flex layout
    alignItems: "center", // Vertically align items
    marginBottom: "12px",
  };

  const inputStyle = {
    fontSize: "14px",
    padding: "6px",
    marginLeft: "10px", // Space between label and input
    width: "200px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  };

  const questionTitleStyle = {
    fontSize: "16px",
    marginBottom: "12px",
    fontWeight: "bold",
  };

  const labelStyle = {
    fontSize: "14px",
    width: "220px", // Set a fixed width for label to align inputs
    textAlign: "left",
    marginLeft: "60px"
  };

  return (
    <div style={containerStyle}>
      <h1 style={questionTitleStyle}>{questionData.text}</h1>
      <p>{questionData.description}</p>
      {questionData.options.map((option, index) => (
        <div key={index} style={sectionStyle}>
          <label style={labelStyle}>{option.text}:</label>
          <input
            type={option.inputType}
            style={inputStyle}
          />
        </div>
      ))}
    </div>
  );
};

export default TransportModes;
