import React, { useState } from "react";

const WaterConservationQuestion = () => {
  const questionData = {
    id: 38,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    text: "If YES, then what water conservation practices does your school adopt?",
    options: [
      {
        text: "Do majority of taps in your school have fixtures that minimize loss of water (low flow taps, self-closing taps, use of sensors, timer shut-off devices, use of low regulators, etc.)?",
        sub_options: [
          { text: "Yes", score: 1, correct: true },
          { text: "No", score: 0, correct: false },
        ],
      },
      {
        text: "Do you have a dual flush system in your school toilets to minimize loss of water?",
        sub_options: [
          { text: "Yes", score: 1, correct: true },
          { text: "No", score: 0, correct: false },
        ],
      },
      {
        text: "Are float-valves fixed in various water tanks to avoid water wastage?",
        sub_options: [
          { text: "Yes", score: 1, correct: true },
          { text: "No", score: 0, correct: false },
        ],
      },
      {
        text: "Is there a mechanism for grey water recycling (reuse of water used for washing, AC water) in your school?",
        sub_options: [
          { text: "Yes", score: 1, correct: true },
          { text: "No", score: 0, correct: false },
        ],
      },
      {
        text: "Is rain-water harvesting done in the school?",
        sub_options: [
          { text: "Yes", score: 1, correct: true },
          { text: "No", score: 0, correct: false },
        ],
      },
    ],
  };

  const [selectedOptions, setSelectedOptions] = useState({});

  const handleOptionChange = (event, index) => {
    const { value } = event.target;
    setSelectedOptions((prev) => ({ ...prev, [index]: value }));
  };

  // CSS Styles (adjusted for consistency with the original style)
  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    lineHeight: "1.6",
    margin: "20px 40px",
    color: "#333",
  };

  const questionTitleStyle = {
    fontSize: "16px",
    marginBottom: "16px",
    fontWeight: "bold",
  };

  const sectionStyle = {
    marginBottom: "20px",
    marginLeft: "60px",
  };

  const labelStyle = {
    fontSize: "14px",
    marginBottom: "8px",
    display: "block",
  };

  const inputGroupStyle = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginTop: "6px",
  };

  const inputStyle = {
    marginRight: "6px",
  };

  const optionStyle = {
    fontSize: "14px",   // Applying font size 14px to the options
    fontWeight: "bold", // Applying bold font weight to the options
    marginBottom: "12px",
    
  };

  return (
    <div style={containerStyle}>
      <h1 style={questionTitleStyle}>Q8. {questionData.text}</h1>

      {/* Iterating over each main option */}
      {questionData.options.map((option, index) => (
        <div key={index} style={sectionStyle}>
          <p style={optionStyle}>{option.text}</p> {/* Applying the style to the option text */}

          {/* Rendering sub-options for Yes/No */}
          <div style={inputGroupStyle}>
            {option.sub_options.map((subOption, subIndex) => (
              <label key={subIndex} style={labelStyle}>
                <input
                  type="radio"
                  name={`option_${index}`}
                  value={subOption.text}
                  checked={selectedOptions[index] === subOption.text}
                  onChange={(e) => handleOptionChange(e, index)}
                  style={inputStyle}
                />
                {subOption.text}
              </label>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default WaterConservationQuestion;
