import React, { useState } from "react";

const TransportQuestionnaire = () => {
  const [answers, setAnswers] = useState({
    busCondition: "",
    safetyFeatures: "",
    maintenance: "",
    pollutionCheck: "",
    parkingSpace: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAnswers({
      ...answers,
      [name]: value,
    });
  };

  const questions = [
    {
      text: "How would you rate the condition of buses/school transport?",
      name: "busCondition",
      options: ["Very good", "Good", "Average", "Bad", "Very Bad"],
    },
    {
      text: "Are buses and other vehicles provided with horns, lights, reflectors, reversing lights and other safety features?",
      name: "safetyFeatures",
      options: ["Yes", "No"],
    },
    {
      text: "Is the preventive maintenance of school transport done regularly?",
      name: "maintenance",
      options: ["Yes", "No"],
    },
    {
      text: "Are pollution checks regularly done for school buses and other vehicles? (You can verify by looking at the records)",
      name: "pollutionCheck",
      options: ["Yes", "No"],
    },
    {
      text: "Is there adequate parking space for vehicles in the school campus?",
      name: "parkingSpace",
      options: ["Yes", "No"],
    },
  ];

  // CSS Styles
  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    lineHeight: "1.6",
    margin: "20px 40px",
    color: "#333",
  };

  const questionTitleStyle = {
    fontSize: "16px",
    marginBottom: "16px",
    fontWeight: "bold",
  };

  const sectionStyle = {
    marginBottom: "20px",
    marginLeft: "60px",
  };

  const labelStyle = {
    fontSize: "14px",
    marginBottom: "8px",
    display: "block",
  };

  const inputStyle = {
    marginRight: "6px",
  };

  const inputGroupStyle = {
    display: "flex",
    gap: "15px", // To provide space between options
    marginTop: "8px",
    alignItems: "center",
  };

  return (
    <div style={containerStyle}>
      <h1 style={questionTitleStyle}>Q5. If the school has its own transport vehicles:</h1>

      {questions.map((question, index) => (
        <div key={index} style={sectionStyle}>
          <h2 style={questionTitleStyle}>{question.text}</h2>
          <div style={inputGroupStyle}>
            {question.options.map((option, idx) => (
              <label key={idx} style={labelStyle}>
                <input
                  type="radio"
                  name={question.name}
                  value={option}
                  checked={answers[question.name] === option}
                  onChange={handleChange}
                  style={inputStyle}
                />
                {option}
              </label>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TransportQuestionnaire;
