import React, { useState } from "react";

const EnergyConservationPractices = () => {
  const [responses, setResponses] = useState({
    energyConservationEfforts: "",
    lightsOffNotInUse: "",
    automatedSensors: "",
    energyMonitor: "",
    computersPrintersEnergySaving: "",
    otherPractices: "",
  });

  // Handle change for Yes/No radio buttons
  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setResponses((prevResponses) => ({
      ...prevResponses,
      [name]: value,
    }));
  };

  // Handle change for the "Other Practices" textarea
  const handleTextareaChange = (e) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      otherPractices: e.target.value,
    }));
  };

  // CSS Styles
  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    lineHeight: "1.6",
    margin: "20px 40px",
    color: "#333",
  };

  const questionTitleStyle = {
    fontSize: "16px",
    marginBottom: "16px",
    fontWeight: "bold",
  };

  const sectionStyle = {
    marginBottom: "20px",
    marginLeft: "60px",
  };

  const labelStyle = {
    fontSize: "14px",
    marginBottom: "8px",
    display: "block",
  };

  const inputGroupStyle = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginTop: "6px",
  };

  const inputStyle = {
    marginRight: "6px",
  };

  const textareaStyle = {
    display: "block",
    width: "100%",
    maxWidth: "500px",
    marginTop: "8px",
    fontSize: "14px",
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    resize: "vertical",
  };

  return (
    <div style={containerStyle}>
      <h1 style={questionTitleStyle}>Q5. Energy Conservation Practices of the School</h1>

      {/* Question a */}
      <div style={sectionStyle}>
        <h2 style={questionTitleStyle}>a. Does the school make conscious efforts to avoid wastage of energy and conserve energy?</h2>
        <div style={inputGroupStyle}>
          <label>
            <input
              type="radio"
              name="energyConservationEfforts"
              value="Yes"
              checked={responses.energyConservationEfforts === "Yes"}
              onChange={handleRadioChange}
              style={inputStyle}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name="energyConservationEfforts"
              value="No"
              checked={responses.energyConservationEfforts === "No"}
              onChange={handleRadioChange}
              style={inputStyle}
            />
            No
          </label>
        </div>
      </div>

      {/* Question b */}
      <div style={sectionStyle}>
        <h2 style={questionTitleStyle}>
          b. Are lights in not-in-use areas switched off when not needed (e.g., reception, lobby, sports field lights, etc.)?
        </h2>
        <div style={inputGroupStyle}>
          <label>
            <input
              type="radio"
              name="lightsOffNotInUse"
              value="Yes"
              checked={responses.lightsOffNotInUse === "Yes"}
              onChange={handleRadioChange}
              style={inputStyle}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name="lightsOffNotInUse"
              value="No"
              checked={responses.lightsOffNotInUse === "No"}
              onChange={handleRadioChange}
              style={inputStyle}
            />
            No
          </label>
        </div>
      </div>

      {/* Question c */}
      <div style={sectionStyle}>
        <h2 style={questionTitleStyle}>c. Are lights fixed with automated sensors?</h2>
        <div style={inputGroupStyle}>
          <label>
            <input
              type="radio"
              name="automatedSensors"
              value="Yes"
              checked={responses.automatedSensors === "Yes"}
              onChange={handleRadioChange}
              style={inputStyle}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name="automatedSensors"
              value="No"
              checked={responses.automatedSensors === "No"}
              onChange={handleRadioChange}
              style={inputStyle}
            />
            No
          </label>
        </div>
      </div>

      {/* Question d */}
      <div style={sectionStyle}>
        <h2 style={questionTitleStyle}>
          d. Is there a dedicated person/energy monitor responsible for turning off switches when areas are not in use?
        </h2>
        <div style={inputGroupStyle}>
          <label>
            <input
              type="radio"
              name="energyMonitor"
              value="Yes"
              checked={responses.energyMonitor === "Yes"}
              onChange={handleRadioChange}
              style={inputStyle}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name="energyMonitor"
              value="No"
              checked={responses.energyMonitor === "No"}
              onChange={handleRadioChange}
              style={inputStyle}
            />
            No
          </label>
        </div>
      </div>

      {/* Question e */}
      <div style={sectionStyle}>
        <h2 style={questionTitleStyle}>
          e. Are the school's computers and printers programmed for energy-saving (e.g., sleep/shutdown mode)?
        </h2>
        <div style={inputGroupStyle}>
          <label>
            <input
              type="radio"
              name="computersPrintersEnergySaving"
              value="Yes"
              checked={responses.computersPrintersEnergySaving === "Yes"}
              onChange={handleRadioChange}
              style={inputStyle}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name="computersPrintersEnergySaving"
              value="No"
              checked={responses.computersPrintersEnergySaving === "No"}
              onChange={handleRadioChange}
              style={inputStyle}
            />
            No
          </label>
        </div>
      </div>

      {/* Question f */}
      <div style={sectionStyle}>
        <h2 style={questionTitleStyle}>f. Any other (please specify):</h2>
        <textarea
          style={textareaStyle}
          placeholder="Enter other energy conservation practices here..."
          value={responses.otherPractices}
          onChange={handleTextareaChange}
        ></textarea>
      </div>
    </div>
  );
};

export default EnergyConservationPractices;
