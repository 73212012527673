import React, { useState } from "react";

const GreenAmbienceQuestion = () => {
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [otherComment, setOtherComment] = useState("");

  const handleAnswerChange = (event) => {
    setSelectedAnswer(event.target.value);
  };

  const handleCommentChange = (event) => {
    setOtherComment(event.target.value);
  };

  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      fontSize: "14px", // Match the font size from AirConditionedSchool
      lineHeight: "1.6",
      margin: "20px 40px", // Match the margin from AirConditionedSchool
      color: "#333",
    },
    heading: {
      fontSize: "16px", // Match the heading size from AirConditionedSchool
      marginBottom: "16px",
      fontWeight: "bold",
    },
    section: {
      marginBottom: "20px",
      marginLeft: "60px",
    },
    label: {
      fontSize: "14px", // Ensure consistent font size for labels
      marginBottom: "8px",
      display: "block",
    },
    inputGroup: {
      display: "flex",
      alignItems: "center",
      gap: "10px", // Space between radio buttons
      marginTop: "6px",
    },
    input: {
      marginRight: "6px", // Small gap between radio and label
    },
    textarea: {
      display: "block",
      width: "100%",
      maxWidth: "500px", // Adjust the maxWidth for consistency
      marginTop: "5px",
      marginBottom: "15px", // Match margin-bottom from AirConditionedSchool
      fontSize: "14px", // Ensure consistent font size for textarea
      padding: "8px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      resize: "vertical",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Q1. Does your school have a natural green ambience?</h1>
      <form>
        <div style={styles.section}>
          <div style={styles.inputGroup}>
            <label>
              <input
                type="radio"
                name="greenAmbience"
                value="Yes"
                checked={selectedAnswer === "Yes"}
                onChange={handleAnswerChange}
                style={styles.input}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="greenAmbience"
                value="No"
                checked={selectedAnswer === "No"}
                onChange={handleAnswerChange}
                style={styles.input}
              />
              No
            </label>
          </div>
        </div>

        {selectedAnswer === "No" && (
          <div style={styles.section}>
            <label style={{ ...styles.label, display: "block", marginBottom: "8px" }}>
              Please specify why there is no green ambience:
            </label>
            <textarea
              value={otherComment}
              onChange={handleCommentChange}
              placeholder="Enter details..."
              style={styles.textarea}
            />
          </div>
        )}
      </form>
    </div>
  );
};

export default GreenAmbienceQuestion;
