import React, { useState } from "react";

const WaterConsumptionQuestion = () => {
  const questionData = {
    id: 36,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "What is the total water consumption of the school?",
    sub_question: [
      {
        id: "a",
        text: "Municipal water (Cubic meters)",
        description:
          "Calculate using annual water bills from municipal authorities. Average annual water bills to find out mean consumption per month.",
        placeholder: "Enter municipal water details here...",
        inputType: "textarea",
        required: true,
      },
      {
        id: "b",
        text: "Ground water - Bore well",
        description:
          "Calculate hours the pump runs and the volume of bore well outlet. Borewells are banned in most states in India.",
        placeholder: "Enter ground water details here...",
        inputType: "textarea",
        required: true,
      },
      {
        id: "c",
        text: "Tankers",
        description:
          "Calculate the capacity of tankers and multiply by the number of tankers.",
        placeholder: "Enter tanker water details here...",
        inputType: "textarea",
        required: true,
      },
      {
        id: "d",
        text: "Packaged drinking water",
        description: "Quantity purchased per month/quarter.",
        placeholder: "Enter packaged drinking water details here...",
        inputType: "textarea",
        required: true,
      },
      {
        id: "e",
        text: "Calculate average consumption per person of the school",
        description:
          "Divide total volume by the number of people in school (students, teachers, and other employees) to calculate per capita water consumption and cost.",
        placeholder: "Enter average consumption details here...",
        inputType: "textarea",
        required: true,
      },
    ],
    images_required: false,
    image_max: null,
    file_max: null,
    required: true,
    comment_required: true,
    files_required: false,
    description: "",
    question_type: "WaterConsumption",
    media_type: "",
    images: null,
    options: [],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  };

  const [responses, setResponses] = useState(
    questionData.sub_question.reduce((acc, sub) => {
      acc[sub.id] = "";
      return acc;
    }, {})
  );

  const handleInputChange = (id, value) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [id]: value,
    }));
  };

  // CSS styles matching the WaterSourceQuestion component
  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    lineHeight: "1.6",
    margin: "20px 40px",
    color: "#333",
  };

  const sectionStyle = {
    marginBottom: "20px",
    marginLeft: "40px",
  };

  const inputStyle = {
    marginRight: "10px",
  };

  const questionTitleStyle = {
    fontSize: "16px",
    marginBottom: "16px",
    fontWeight: "bold",
  };

  const labelStyle = {
    display: "block",
    marginBottom: "8px",
    fontSize: "14px",
  };

  const descriptionStyle = {
    fontSize: "13px",
    color: "#555",
    marginBottom: "8px",
  };

  const textAreaStyle = {
    width: "95%",
    height: "60px",
    fontSize: "14px",
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    marginTop: "8px",
  };

  return (
    <div style={containerStyle}>
      <h1 style={questionTitleStyle}>Q6. {questionData.text}</h1>
      <ol style={{ paddingLeft: "20px" }}>
        {questionData.sub_question.map((sub) => (
          <li key={sub.id} style={sectionStyle}>
            <label style={labelStyle}>
              <strong>{sub.text}</strong>
            </label>
            <p style={descriptionStyle}>{sub.description}</p>
            <textarea
              style={textAreaStyle}
              placeholder={sub.placeholder}
              value={responses[sub.id]}
              onChange={(e) => handleInputChange(sub.id, e.target.value)}
              required={sub.required}
            />
          </li>
        ))}
      </ol>
    </div>
  );
};

export default WaterConsumptionQuestion;
