import React, { useState } from "react";

const RainWaterTanksQuestion = () => {
  const questionData = {
    id: 47,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    text: "How many tanks/structures and their locations?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: true,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Water17",
    media_type: "",
    images: null,
    options: [],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
    fields: [
      {
        fieldType: "input",
        id: "tanksCount",
        name: "tanksCount",
        label: "Enter the number of rain water storage tanks/structures:",
        placeholder: "Enter number of tanks/structures",
        required: true,
        type: "number",
        style: {
          padding: "8px",
          margin: "8px 0",
          width: "200px",
          borderRadius: "4px",
          border: "1px solid #ccc",
        },
      },
      {
        fieldType: "textarea",
        id: "tankLocations",
        name: "tankLocations",
        label: "Indicate the location of rain water storage tanks:",
        placeholder: "Describe the location of tanks",
        required: true,
        rows: 4,
        style: {
          padding: "8px",
          margin: "8px 0",
          width: "300px",
          borderRadius: "4px",
          border: "1px solid #ccc",
        },
      },
    ],
  };

  const [tanksCount, setTanksCount] = useState("");
  const [tankLocations, setTankLocations] = useState("");

  const handleTanksCountChange = (event) => {
    setTanksCount(event.target.value);
  };

  const handleTankLocationsChange = (event) => {
    setTankLocations(event.target.value);
  };

  // CSS Styles (from WaterSourceQuestion)
  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    lineHeight: "1.6",
    margin: "20px 40px",
    color: "#333",
  };

  const sectionStyle = {
    marginBottom: "20px",
    marginLeft: "60px", // Added margin left for consistency
  };

  const inputStyle = {
    marginRight: "10px",
  };

  const questionTitleStyle = {
    fontSize: "16px",
    marginBottom: "16px",
    fontWeight: "bold",
  };

  const labelStyle = {
    display: "block",
    marginBottom: "8px",
    fontSize: "14px", // Consistent font size
  };

  return (
    <div style={containerStyle}>
      <h1 style={questionTitleStyle}>Q17. {questionData.text}</h1>

      {/* Render fields dynamically */}
      {questionData.fields.map((field, index) => {
        return (
          <div key={index} style={sectionStyle}>
            <label htmlFor={field.id} style={labelStyle}>
              {field.label}
            </label>
            {field.fieldType === "input" ? (
              <input
                type={field.type}
                id={field.id}
                name={field.name}
                placeholder={field.placeholder}
                value={field.id === "tanksCount" ? tanksCount : ""}
                onChange={handleTanksCountChange}
                required={field.required}
                style={field.style}
              />
            ) : field.fieldType === "textarea" ? (
              <textarea
                id={field.id}
                name={field.name}
                rows={field.rows}
                placeholder={field.placeholder}
                value={tankLocations}
                onChange={handleTankLocationsChange}
                required={field.required}
                style={field.style}
              />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default RainWaterTanksQuestion;
