import React, { useState } from "react";

const WeedsFrequencyQuestion = () => {
  const questionData = {
    id: 22,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    text: "Q3. Do you see weeds in your school campus? If yes, how frequently?",
    options: [
      {
        text: "Very frequently",
        score: 1,
      },
      {
        text: "Rarely",
        score: 2,
      },
      {
        text: "Seldom",
        score: 3,
      },
      {
        text: "Never",
        score: 4,
      },
    ],
  };

  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    lineHeight: "1.6",
    margin: "20px 40px",
    color: "#333",
  };

  const inputGroupStyle = {
    display: "flex", // Align options horizontally
    alignItems: "center",
    gap: "20px", // Space between radio button options
    marginTop: "10px",
    marginLeft: "60px", // Align options with heading
  };

  const inputStyle = {
    marginRight: "8px", // Space between radio button and label text
    cursor: "pointer",
  };

  const labelStyle = {
    fontSize: "14px",
    display: "flex", // Align radio buttons and labels
    alignItems: "center",
    cursor: "pointer",
  };

  return (
    <div style={containerStyle}>
      <h1 style={{ fontSize: "16px", marginBottom: "16px", fontWeight: "bold" }}>
        {questionData.text}
      </h1>
      <form>
        <div style={inputGroupStyle}>
          {questionData.options.map((option, index) => (
            <label key={index} style={labelStyle}>
              <input
                type="radio"
                name="weedFrequency"
                value={option.text}
                checked={selectedOption === option.text}
                onChange={() => handleOptionChange(option.text)}
                style={inputStyle}
              />
              {option.text}
            </label>
          ))}
        </div>
      </form>
    </div>
  );
};

export default WeedsFrequencyQuestion;
