import React, { useEffect, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { apiJson } from "api";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Avatar, Button } from "@mui/joy";
import {
  Box,
  Modal,
  Tooltip,
  Typography,
  TextField,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import MaterialTable from "material-table";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Divider from "@mui/material/Divider";
import ClearIcon from "@mui/icons-material/Clear";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

const fetchData = async (filters) => {
  const today = new Date().toISOString().split("T")[0];

  const { startDate, endDate, validTeacher } = filters;

  const params = {};

  if (startDate && !endDate && !validTeacher) {
    params.startDate = startDate;
    params.endDate = today;
  } else if (startDate && endDate && validTeacher) {
    params.startDate = startDate;
    params.endDate = endDate;
    params.validTeacher = validTeacher;
  } else if (!startDate && !endDate && validTeacher) {
    params.validTeacher = validTeacher;
  }

  try {
    const response = await axios.get("https://api.example.com/data", {
      params,
    });
    console.log(response.data);
  } catch (error) {
    console.error("Error fetching data", error);
  }
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "60%", md: "33%" },
  borderRadius: "1rem",
  bgcolor: "background.default",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
  outline: "none",
  padding: "2.8rem",
  height: "39%",
};

const InstituteCsvDetails = () => {
  const [instituteCbseData, setInstituteCbseData] = useState([]);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [validTeacher, setValidTeacher] = useState("");
  const [instituteDetails, setInstituteDetails] = useState({
    instituteName: "",
    numberOfTeachers: "",
    numberOfStudents: "",
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEdit = async (value) => {
    console.log(value, "value value");
    handleOpen();

    let data = {
      instituteId: value.id,
    };

    // ==================================start api=========
    try {
      const response = await apiJson.post(
        "/api/v2/adminNew/intitute-csv-count",
        data
      );

      const result = response?.data;
      const teacherData = result?.roleCounts.find(
        (item) => item.role === "teacher"
      );
      const studentData = result?.roleCounts.find(
        (item) => item.role === "student"
      );

      setInstituteDetails({
        instituteName: value.institution_name,
        numberOfTeachers: teacherData?.roleCount || "NA",
        numberOfStudents: studentData?.roleCount || "NA",
      });
    } catch (error) {
      // Handle error
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Error response:", error.response.data);
        console.error("Error status:", error.response.status);
        console.error("Error headers:", error.response.headers);
      } else if (error.request) {
        // No response was received from the server
        console.error("No response:", error.request);
      } else {
        // Error occurred during request setup
        console.error("Request error:", error.message);
      }
      console.error("Error config:", error.config);
      throw error; // Rethrow the error if needed for further handling
    }
    // ================end api
  };

  const columns = [
    { title: "Institute Name", field: "institution_name" },
    { title: "Email", field: "email" },
    {
      title: "Action",
      field: "action",
      render: (rowData) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "200px",
          }}
        >
          <Tooltip title="View Details" sx={{ fontWeight: 600 }}>
            <VisibilityIcon
              variant="contained"
              sx={{
                cursor: "pointer",
                color: "#1b4ae4",
              }}
              onClick={() => handleEdit(rowData)}
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  const uploadCsv = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    try {
      await apiJson.post(`/api/v2/admin/cbse_data_upoad`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      getAllCbseDetails();
      toast.dismiss();
      toast.success("Data added successfully");
      e.target.value = null;
    } catch (err) {
      toast.dismiss();
      toast.error("Internal server error");
    }
  };

  const getAllCbseDetails = async () => {
    try {
      const res = await apiJson.get("/api/v2/adminNew/institute-list-csv");
      let temp = res.data.result.map((item) => ({
        id: item.id,
        institution_name: item.institution_name,
        email: item.email,
      }));
      setInstituteCbseData(temp);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllCbseDetails();
  }, []);

  const deleteCbseDetail = async (id) => {
    try {
      await apiJson.delete(`api/v2/admin/deleteCbseDetail/${id}`);
      getAllCbseDetails();
      toast.dismiss();
      toast.success("Deleted Successfully");
    } catch (err) {
      toast.dismiss();
      toast.error("Internal server error");
    }
  };

  const convertArrayToCSV = (array) => {
    let csv = "";
    array.forEach((item) => {
      csv += Object.values(item).join(",") + "\n";
    });
    return csv;
  };

  return (
    <>
      <div className="container">
        <Typography
          component={"h1"}
          sx={{
            fontWeight: "600",
            fontSize: "1.5rem",
            margin: "2rem 0rem",
          }}
        >
          Institute Upload Csv Details
        </Typography>

        {/* <Grid
          container
          spacing={2}
          justifyContent="center"
          sx={{ marginBottom: "30px" }}
        >
          <Grid item xs={12} sm={4}>
            <Box sx={{ width: "100%" }}>
              <Typography sx={{ marginLeft: "9px", fontWeight: "600" }}>
                Start Date
              </Typography>
              <Box
                sx={{
                  "& input": {
                    border: "1px solid #2356FE",
                    borderRadius: "0.5rem",
                    padding: "0.5rem",
                    ":focus-visible": {
                      border: "1px solid #2356FE !important",
                    },
                  },
                }}
              >
                <DatePicker
                  selected={startDate}
                  placeholderText="Select a date"
                  onChange={(date) => setStartDate(date)}
                  customInput={
                    <TextField
                      sx={{
                        width: "100%",
                      }}
                    />
                  }
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box sx={{ width: "100%" }}>
              <Typography sx={{ marginLeft: "9px", fontWeight: "600" }}>
                End Date
              </Typography>
              <Box
                sx={{
                  "& input": {
                    border: "1px solid #2356FE",
                    borderRadius: "0.5rem",
                    padding: "0.5rem",
                    ":focus-visible": {
                      border: "1px solid #2356FE",
                      outline: "none",
                    },
                  },
                }}
              >
                <DatePicker
                  selected={endDate}
                  placeholderText="Select a date"
                  onChange={(date) => setEndDate(date)}
                  customInput={
                    <TextField
                      sx={{
                        width: "100%", // Consistent width
                      }}
                    />
                  }
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box sx={{ width: "100%" }}>
              <Typography sx={{ marginLeft: "9px", fontWeight: "600" }}>
                Valid Teacher
              </Typography>
              <Select
                id="validTeacher"
                value={validTeacher}
                onChange={(e) => setValidTeacher(e.target.value)}
                displayEmpty
                sx={{
                  border: "1px solid #2356FE",
                  borderRadius: "0.5rem",
                  padding: "0.5rem",
                  width: "65%",
                  height: "42px",
                  "& .MuiSelect-select": {
                    padding: "8px 4px",
                    color: "grey",
                  },
                }}
              >
                <MenuItem disabled value="">
                  <em>Select roles</em>
                </MenuItem>
                <MenuItem value="validTeacher">Valid Teacher</MenuItem>
                <MenuItem value="validStudent">Valid Student</MenuItem>
                <MenuItem value="all">All Students & Teachers</MenuItem>
              </Select>
            </Box>
          </Grid>
        </Grid> */}

        <Box
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "1rem",
              border: "2px solid #2356FE",
            },
            "& .MuiTableCell-head": {
              backgroundColor: "#2356FE",
              zIndex: 0,
              color: "#fff",
              "& :hover": {
                color: "#fff",
              },
              fontWeight: 600,
              fontSize: "1.02rem",
            },
          }}
        >
          <MaterialTable
            className="custom-table-check"
            options={{
              search: true,
              toolbar: true,
              showTitle: true,
              pageSize: 10,
              pageSizeOptions: [100, 200, 500, 1000],
            }}
            columns={columns}
            data={instituteCbseData}
            title={false}
            direction="ltr"
          />
        </Box>

        <Divider className="my-3" />

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  component={"p"}
                  sx={{ fontWeight: 600, fontSize: "1.2rem" }}
                >
                  Institute Csv Details
                </Typography>
                <ClearIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClose()}
                />
              </Box>

              <Divider
                component={"hr"}
                sx={{
                  margin: "0.5rem 0rem",
                  border: "0.1rem solid #8f8d8d",
                }}
              />
              <div sx={{ paddingLeft: "40px" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Typography
                    component={"label"}
                    sx={{
                      fontWeight: "600",
                      fontSize: "1.2rem",
                    }}
                  >
                    Institute Name:{" "}
                  </Typography>
                  <Typography
                    component={"p"}
                    sx={{
                      fontWeight: "500",
                      fontSize: "1.05rem",
                    }}
                  >
                    {instituteDetails.instituteName}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Typography
                    component={"label"}
                    sx={{
                      fontWeight: "600",
                      fontSize: "1.2rem",
                    }}
                  >
                    No. Of Teacher:
                  </Typography>
                  <Typography
                    component={"p"}
                    sx={{
                      fontWeight: "500",
                      fontSize: "1.05rem",
                    }}
                  >
                    {instituteDetails.numberOfTeachers}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Typography
                    component={"label"}
                    sx={{
                      fontWeight: "600",
                      fontSize: "1.2rem",
                    }}
                  >
                    No. Of Student:
                  </Typography>
                  <Typography
                    component={"p"}
                    sx={{
                      fontWeight: "500",
                      fontSize: "1.05rem",
                    }}
                  >
                    {instituteDetails.numberOfStudents}
                  </Typography>
                </Box>
              </div>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default InstituteCsvDetails;
