import React, { useState } from "react";

const WasteAuditQuestion = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  const questionData = {
    id: 62,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    text: "Q11. Does your school advocate and provide information to reduce waste in school?",
    options: [
      { text: "Yes", score: "1", correct: true },
      { text: "No", score: "0", correct: false },
    ],
  };

  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      fontSize: "14px",
      lineHeight: "1.6",
      margin: "20px 40px",
      color: "#333",
    },
    questionTitle: {
      fontSize: "16px",
      marginBottom: "16px",
      fontWeight: "bold",
    },
    section: {
      marginBottom: "20px",
      marginLeft: "60px",
    },
    inputGroup: {
      display: "flex",
      gap: "20px", // Adds space between options for horizontal alignment
      alignItems: "center",
    },
    label: {
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
    },
    input: {
      marginRight: "6px",
    },
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.questionTitle}>{questionData.text}</h1>
      <div style={styles.section}>
        <div style={styles.inputGroup}>
          {questionData.options.map((option, index) => (
            <label key={index} style={styles.label}>
              <input
                type="radio"
                name="wasteReductionAdvocacy"
                value={option.text}
                checked={selectedOption === option.text}
                onChange={handleOptionChange}
                style={styles.input}
              />
              {option.text}
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WasteAuditQuestion;
