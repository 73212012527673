import React, { useState } from "react";

const WeedRemovalProcedure = () => {
  const questionData = {
    id: 23,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    text: "Q4.  What procedure is adopted to remove weeds?",
    options: [
      {
        risk: false,
        text: "Manual removal of weeds",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "Use of pesticides/herbicides",
        score: 2,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Any other (specify)",
        score: 3,
        correct: false,
        riskText: "",
      },
    ],
  };

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [otherDetails, setOtherDetails] = useState("");

  const handleOptionChange = (optionText) => {
    setSelectedOptions((prev) =>
      prev.includes(optionText)
        ? prev.filter((option) => option !== optionText)
        : [...prev, optionText]
    );
  };

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        fontSize: "14px", // Adjusted to match WeedsFrequencyQuestion font size
        lineHeight: "1.6",
        margin: "20px 40px", // Adjusted margin to match WeedsFrequencyQuestion component
        color: "#333",
      }}
    >
      <h1 style={{ fontSize: "16px", marginBottom: "16px", fontWeight: "bold" }}>
        {questionData.text} (Please tick)
      </h1>
      <form>
        {questionData.options.map((option, index) => (
          <div
            key={index}
            style={{
              marginBottom: "20px", // Adjusted margin to match WeedsFrequencyQuestion
              marginLeft: "60px", // Adjusted left margin to match WeedsFrequencyQuestion
              display: "flex",
              alignItems: "center",
            }}
          >
            <label
              style={{
                fontSize: "14px", // Consistent font size for labels
                cursor: "pointer", // Pointer cursor for better UX
                display: "flex", // Align radio buttons and labels properly
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <input
                type="radio"
                name="weedRemoval"
                value={option.text}
                checked={selectedOptions.includes(option.text)}
                onChange={() => handleOptionChange(option.text)}
                style={{
                  marginRight: "10px", // Space between radio button and label
                  cursor: "pointer", // Ensures radio buttons are clickable
                }}
              />
              {option.text}
            </label>
            {option.text === "Any other (specify)" && (
              <textarea
                value={otherDetails}
                onChange={(e) => setOtherDetails(e.target.value)}
                rows="3"
                placeholder="Specify other procedures..."
                style={{
                  marginLeft: "20px", // Adjust margin between label and textarea
                  width: "100%",
                  maxWidth: "400px",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  resize: "none",
                }}
              ></textarea>
            )}
          </div>
        ))}
      </form>
    </div>
  );
};

export default WeedRemovalProcedure;
