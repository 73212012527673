import React, { useState } from "react";

const RainwaterStorageQuestion = () => {
  const questionData = {
    id: 46,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    text: "Q16. What is the capacity of rain water storage tanks?",
  };

  const [capacity, setCapacity] = useState("");

  const handleCapacityChange = (e) => {
    setCapacity(e.target.value);
  };

  // Updated styles to match WaterSourceQuestion's style
  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      fontSize: "14px",
      lineHeight: "1.6",
      margin: "20px 40px", // Consistent margin as per WaterSourceQuestion
      color: "#333",
    },
    questionTitle: {
      fontSize: "16px",
      marginBottom: "16px",
      fontWeight: "bold",
    },
    section: {
      marginBottom: "20px",
      marginLeft: "60px", // Adjusted margin left for consistency
    },
    label: {
      fontSize: "14px",
      marginBottom: "8px",
      display: "block",
    },
    textInput: {
      fontSize: "14px",
      padding: "8px",
      marginTop: "8px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      width: "100%",
      boxSizing: "border-box",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.questionTitle}>{questionData.text}</h1>
      <form>
        <div style={styles.section}>
          <label style={styles.label} htmlFor="capacity">
            Enter the capacity in liters (L):
          </label>
          <input
            type="number"
            id="capacity"
            name="capacity"
            placeholder="Enter capacity in liters"
            value={capacity}
            onChange={handleCapacityChange}
            required
            style={styles.textInput}
          />
        </div>
      </form>
    </div>
  );
};

export default RainwaterStorageQuestion;
