import React, { useState } from "react";

const WaterAuditQuestion = () => {
  // Question data as per the updated array
  const questionData = {
    id: 49,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    text: "Is Ground water recharged through trenches?",
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
      },
      {
        risk: false,
        text: "No",
        score: "0",
        correct: false,
      },
    ],
    fields: [
      {
        fieldType: "radio",
        name: "groundWaterRecharge",
        label: "Is Ground water recharged through trenches?",
        options: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
      },
      {
        fieldType: "radio",
        name: "externalRecycling",
        label: "External recycling: It is recycled into the main supply",
        options: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
      },
    ],
  };

  // State for managing selected values
  const [selectedOptions, setSelectedOptions] = useState({
    groundWaterRecharge: "",
    externalRecycling: "",
  });

  const handleOptionChange = (field, value) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  // Updated consistent styles
  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      fontSize: "14px",
      lineHeight: "1.6",
      margin: "20px 40px",
      color: "#333",
    },
    questionTitle: {
      fontSize: "16px",
      marginBottom: "16px",
      fontWeight: "bold",
    },
    section: {
      marginBottom: "20px",
      marginLeft: "60px", // Consistent margin as per WaterSourceQuestion
    },
    label: {
      fontSize: "14px",
      marginBottom: "8px",
      display: "block",
    },
    boldLabel: {
      fontSize: "14px",
      marginBottom: "8px",
      display: "block",
      fontWeight: "bold", // Makes the label bold
    },
    inputGroup: {
      display: "flex",
      alignItems: "center",
      gap: "10px", // Added gap between radio buttons
      marginTop: "6px",
    },
    input: {
      marginRight: "6px", // Consistent margin for radio buttons
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.questionTitle}>Q19. {questionData.text}</h1>
      
      {questionData.fields.map((field, index) => (
        <div key={index} style={styles.section}>
          {/* Apply bold style to specific labels */}
          <label
            style={
              field.label === "Is Ground water recharged through trenches?" ||
              field.label === "External recycling: It is recycled into the main supply"
                ? styles.boldLabel
                : styles.label
            }
          >
            {field.label}
          </label>
          <div style={styles.inputGroup}>
            {field.options.map((option, idx) => (
              <label key={idx} style={styles.label}>
                <input
                  type="radio"
                  name={field.name}
                  value={option.value}
                  checked={selectedOptions[field.name] === option.value}
                  onChange={() => handleOptionChange(field.name, option.value)}
                  style={styles.input}
                />
                {option.label}
              </label>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default WaterAuditQuestion;
