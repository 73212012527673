import React, { useState } from "react";

const TransportSurvey = () => {
  const [data, setData] = useState({
    walkers: "",
    bicycle: "",
    schoolTransport: "",
    privateTransport: "",
    publicVehicle: "",
    other: "",
    otherPercentage: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  // CSS Styles (adjusted according to TransportModes styles)
  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    lineHeight: "1.4",
    margin: "20px 40px",
    color: "#333",
  };

  const sectionStyle = {
    display: "flex", // Flex layout for aligning labels and inputs
    alignItems: "center", // Vertically align items
    marginBottom: "12px", // Vertical spacing between fields
  };

  const inputStyle = {
    fontSize: "14px",
    padding: "6px", // Padding for input fields
    marginLeft: "10px", // Space between label and input
    width: "200px", // Fixed width for input fields
    borderRadius: "4px",
    border: "1px solid #ccc", // Input border styling
  };

  const questionTitleStyle = {
    fontSize: "16px",
    marginBottom: "12px",
    fontWeight: "bold",
  };

  const labelStyle = {
    fontSize: "14px",
    width: "220px", // Set a fixed width for label to align inputs
    textAlign: "left",
    marginLeft: "60px", // Margin for better alignment of labels
  };

  return (
    <div style={containerStyle}>
      <h1 style={questionTitleStyle}>
        Q3. Write the approximate percentage of the school teachers and other
        staff who travel to and from the school by various modes of
        transportation.
      </h1>
      <p>
        (You will need to do a survey in school by asking the teachers and
        staff. You can also use a Google form or SurveyMonkey to get estimates.)
      </p>

      <div style={sectionStyle}>
        <label style={labelStyle}>Walkers (%):</label>
        <input
          type="number"
          name="walkers"
          value={data.walkers}
          onChange={handleChange}
          style={inputStyle}
        />
      </div>

      <div style={sectionStyle}>
        <label style={labelStyle}>Bicycle (%):</label>
        <input
          type="number"
          name="bicycle"
          value={data.bicycle}
          onChange={handleChange}
          style={inputStyle}
        />
      </div>

      <div style={sectionStyle}>
        <label style={labelStyle}>School transport (%):</label>
        <input
          type="number"
          name="schoolTransport"
          value={data.schoolTransport}
          onChange={handleChange}
          style={inputStyle}
        />
      </div>

      <div style={sectionStyle}>
        <label style={labelStyle}>Private transport/personal vehicle (%):</label>
        <input
          type="number"
          name="privateTransport"
          value={data.privateTransport}
          onChange={handleChange}
          style={inputStyle}
        />
      </div>

      <div style={sectionStyle}>
        <label style={labelStyle}>Public vehicle (%):</label>
        <input
          type="number"
          name="publicVehicle"
          value={data.publicVehicle}
          onChange={handleChange}
          style={inputStyle}
        />
      </div>

      <div style={sectionStyle}>
        <label style={labelStyle}>Any other (specify):</label>
        <input
          type="text"
          name="other"
          value={data.other}
          onChange={handleChange}
          style={inputStyle}
          placeholder="Enter mode of transport"
        />
        <input
          type="number"
          name="otherPercentage"
          value={data.otherPercentage}
          onChange={handleChange}
          style={inputStyle}
          placeholder="Enter percentage for specified transport"
        />
      </div>
    </div>
  );
};

export default TransportSurvey;
