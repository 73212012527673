export const complianceQuestionArray = [
  //_________________________________________
  //_______________Energy Audit__________________
  //_____________________________________________

  {
    id: 5,
    complianceId: 3,
    categoryId: 4,
    category: "Energy Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "What are the various source(s) of energy used in school?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Demo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Electricity",
        score: 1,
        correct: false,
        riskText: "",
        sub_question: {
          sub_options: [
            {
              text: "Electricity (from Municipality/Electrical Company)",
            },
            {
              text: "Electricity from generator",
              sub_question: {
                sub_options: [
                  {
                    text: "If a generator is used, specify the fuel used",
                    details: [
                      {
                        text: "Amount of Fuel used in a month",
                      },
                      {
                        text: "Generator Capacity",
                      },
                      {
                        text: "Frequency of use of the generator",
                        sub_options: [
                          {
                            text: "Only in an emergency (in case of electricity supply failure)",
                          },
                          {
                            text: "Regularly (Specify purposes and duration)",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
        },
      },
      {
        risk: false,
        text: "Gas",
        score: 2,
        correct: false,
        riskText: "",
        sub_question: {
          sub_options: [
            {
              text: "Gas cylinders",
            },
            {
              text: "Piped National Gas (PNG)",
            },
          ],
        },
      },
    ],
    hint: "",
    createdAt: "2023-09-04T12:16:52.000Z",
    updatedAt: "2024-05-16T10:05:55.000Z",
  },
  {
    id: 6,
    complianceId: 3,
    categoryId: 4,
    category: "Energy Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Q2.  Does your school have any alternative sources of energy generation? If yes, indicate the alternate source.",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "Demo2",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "A. If Solar Energy is used",
        score: null,
        correct: false,
        riskText: "",
        sub_question: {
          sub_options: [
            {
              text: "a. Are Solar Energy panels used to generate electricity?",
              options: [
                { text: "Yes", value: "Yes", score: null },
                { text: "No", value: "No", score: null },
              ],
            },
            {
              text: "b. Do some devices run directly on solar energy? Specify...",
              options: [
                { text: "Yes", value: "Yes", score: null },
                { text: "No", value: "No", score: null },
              ],
            },
            {
              text: "c. What other purposes is solar energy used for? Specify...",
              details: [
                {
                  text: "Specify...",
                },
              ],
            },
          ],
        },
      },
      {
        risk: false,
        text: "B. Bio-fuel",
        score: null,
        correct: false,
        riskText: "",
        options: [
          { text: "Yes", value: "Yes", score: null },
          { text: "No", value: "No", score: null },
        ],
      },
      {
        risk: false,
        text: "C. Wind",
        score: null,
        correct: false,
        riskText: "",
        options: [
          { text: "Yes", value: "Yes", score: null },
          { text: "No", value: "No", score: null },
        ],
      },
      {
        risk: false,
        text: "D. Electric",
        score: null,
        correct: false,
        riskText: "",
        options: [
          { text: "Yes", value: "Yes", score: null },
          { text: "No", value: "No", score: null },
        ],
      },
    ],
    hint: "",
    createdAt: "2023-11-24T00:00:00.000Z",
    updatedAt: "2023-11-24T00:00:00.000Z",
  },
  // {
  //   id: 7,
  //   complianceId: 3,
  //   categoryId: 4,
  //   category: "Energy Audit",
  //   desc_modal: [
  //     {
  //       img: "",
  //       description: "",
  //     },
  //   ],
  //   text: "Does your school get bills for consumption of electricity and other energy sources?",
  //   sub_question: null,
  //   images_required: false,
  //   image_max: null,
  //   file_max: null,
  //   required: false,
  //   comment_required: false,
  //   files_required: false,
  //   description: "",
  //   question_type: "YesNo",
  //   media_type: "",
  //   images: null,
  //   options: [
  //     {
  //       risk: false,
  //       text: "Yes",
  //       score: "1",
  //       correct: true,
  //       riskText: "",
  //     },
  //     {
  //       risk: false,
  //       text: "No",
  //       score: 0,
  //       correct: false,
  //       riskText: "",
  //     },
  //   ],
  //   hint: "",
  //   createdAt: "2023-09-04T12:36:51.000Z",
  //   updatedAt: "2023-09-04T12:36:51.000Z",
  // },
  // {
  //   id: 8,
  //   complianceId: 3,
  //   categoryId: 4,
  //   category: "Energy Audit",
  //   desc_modal: [
  //     {
  //       img: "",
  //       description: "",
  //     },
  //   ],
  //   text: "Average consumption per person",
  //   sub_question: null,
  //   images_required: false,
  //   image_max: null,
  //   file_max: null,
  //   required: false,
  //   comment_required: false,
  //   files_required: false,
  //   description: "",
  //   question_type: "YesNo",
  //   media_type: "",
  //   images: null,
  //   options: [
  //     {
  //       risk: false,
  //       text: "Yes",
  //       score: "01",
  //       correct: true,
  //       riskText: "",
  //     },
  //     {
  //       risk: false,
  //       text: "No",
  //       score: 0,
  //       correct: false,
  //       riskText: "",
  //     },
  //   ],
  //   hint: "",
  //   createdAt: "2023-09-05T04:28:12.000Z",
  //   updatedAt: "2023-09-05T04:28:12.000Z",
  // },
  {
    id: 9,
    complianceId: 3,
    categoryId: 4,
    category: "Energy Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Air-Conditioned School",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Demo3",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 10,
    complianceId: 3,
    categoryId: 4,
    category: "Energy Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "For Non-Air Conditioned School",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Demo4",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  // {
  //   id: 12,
  //   complianceId: 3,
  //   categoryId: 4,
  //   category: "Energy Audit",
  //   desc_modal: [
  //     {
  //       img: "",
  //       description: "",
  //     },
  //   ],
  //   text: "Light Sources",
  //   sub_question: null,
  //   images_required: false,
  //   image_max: null,
  //   file_max: null,
  //   required: false,
  //   comment_required: false,
  //   files_required: false,
  //   description: "",
  //   question_type: "YesNo",
  //   media_type: "",
  //   images: null,
  //   options: [
  //     {
  //       risk: false,
  //       text: "Yes",
  //       score: "1",
  //       correct: true,
  //       riskText: "",
  //     },
  //     {
  //       risk: false,
  //       text: "No",
  //       score: 0,
  //       correct: false,
  //       riskText: "",
  //     },
  //   ],
  //   hint: "",
  //   createdAt: "2023-09-05T04:30:56.000Z",
  //   updatedAt: "2023-09-05T04:30:56.000Z",
  // },
  {
    id: 13,
    complianceId: 3,
    categoryId: 4,
    category: "Energy Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Energy Conservation Practices of school",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Demo5",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  // {
  //   id: 14,
  //   complianceId: 3,
  //   categoryId: 4,
  //   category: "Energy Audit",
  //   desc_modal: [
  //     {
  //       img: "",
  //       description: "",
  //     },
  //   ],
  //   text: "Green practices",
  //   sub_question: null,
  //   images_required: false,
  //   image_max: null,
  //   file_max: null,
  //   required: false,
  //   comment_required: false,
  //   files_required: false,
  //   description: "",
  //   question_type: "YesNo",
  //   media_type: "",
  //   images: null,
  //   options: [
  //     {
  //       risk: false,
  //       text: "Yes",
  //       score: "1",
  //       correct: true,
  //       riskText: "",
  //     },
  //     {
  //       risk: false,
  //       text: "No",
  //       score: 0,
  //       correct: false,
  //       riskText: "",
  //     },
  //   ],
  //   hint: "",
  //   createdAt: "2023-09-05T04:30:56.000Z",
  //   updatedAt: "2023-09-05T04:30:56.000Z",
  // },

  //______________________________________________________
  //__________________Biodiversity Audit__________________
  //______________________________________________________

  {
    id: 15,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Does your school have a natural green ambience? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Demo6",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  // {
  //   id: 16,
  //   complianceId: 3,
  //   categoryId: 5,
  //   category: "Biodiversity Audit",
  //   desc_modal: [
  //     {
  //       img: "",
  //       description: "",
  //     },
  //   ],
  //   text: "Each plant, animal and micro organism contributes to the diversity of an area. Which one of the following productive areas are present in your school?",
  //   sub_question: null,
  //   images_required: false,
  //   image_max: null,
  //   file_max: null,
  //   required: false,
  //   comment_required: false,
  //   files_required: false,
  //   description: "",
  //   question_type: "YesNo",
  //   media_type: "",
  //   images: null,
  //   options: [
  //     {
  //       risk: false,
  //       text: "Yes",
  //       score: "1",
  //       correct: true,
  //       riskText: "",
  //     },
  //     {
  //       risk: false,
  //       text: "No",
  //       score: 0,
  //       correct: false,
  //       riskText: "",
  //     },
  //   ],
  //   hint: "",
  //   createdAt: "2023-09-05T04:30:56.000Z",
  //   updatedAt: "2023-09-05T04:30:56.000Z",
  // },
  // {
  //   id: 17,
  //   complianceId: 3,
  //   categoryId: 5,
  //   category: "Biodiversity Audit",
  //   desc_modal: [
  //     {
  //       img: "",
  //       description: "",
  //     },
  //   ],
  //   text: "Are there adequate spaces with plants and trees besides lawns in sufficient numbers in the school campus? (For eg. Rows of trees etc.)",
  //   sub_question: null,
  //   images_required: false,
  //   image_max: null,
  //   file_max: null,
  //   required: false,
  //   comment_required: false,
  //   files_required: false,
  //   description: "",
  //   question_type: "YesNo",
  //   media_type: "",
  //   images: null,
  //   options: [
  //     {
  //       risk: false,
  //       text: "Yes",
  //       score: "1",
  //       correct: true,
  //       riskText: "",
  //     },
  //     {
  //       risk: false,
  //       text: "No",
  //       score: 0,
  //       correct: false,
  //       riskText: "",
  //     },
  //   ],
  //   hint: "",
  //   createdAt: "2023-09-05T04:30:56.000Z",
  //   updatedAt: "2023-09-05T04:30:56.000Z",
  // },
  // {
  //   id: 18,
  //   complianceId: 3,
  //   categoryId: 5,
  //   category: "Biodiversity Audit",
  //   desc_modal: [
  //     {
  //       img: "",
  //       description: "",
  //     },
  //   ],
  //   text: "Do you find a greater number of native and indigenous plants/ trees non-native plants/ trees in the school campus? ",
  //   sub_question: null,
  //   images_required: false,
  //   image_max: null,
  //   file_max: null,
  //   required: false,
  //   comment_required: false,
  //   files_required: false,
  //   description: "",
  //   question_type: "YesNo",
  //   media_type: "",
  //   images: null,
  //   options: [
  //     {
  //       risk: false,
  //       text: "Yes",
  //       score: "1",
  //       correct: true,
  //       riskText: "",
  //     },
  //     {
  //       risk: false,
  //       text: "No",
  //       score: 0,
  //       correct: false,
  //       riskText: "",
  //     },
  //   ],
  //   hint: "",
  //   createdAt: "2023-09-05T04:30:56.000Z",
  //   updatedAt: "2023-09-05T04:30:56.000Z",
  // },
  // {
  //   id: 19,
  //   complianceId: 3,
  //   categoryId: 5,
  //   category: "Biodiversity Audit",
  //   desc_modal: [
  //     {
  //       img: "",
  //       description: "",
  //     },
  //   ],
  //   text: "How many native trees are there in the school campus? (Native trees are indigenous to a given region or ecosystem). Specify number.",
  //   sub_question: null,
  //   images_required: false,
  //   image_max: null,
  //   file_max: null,
  //   required: false,
  //   comment_required: false,
  //   files_required: false,
  //   description: "",
  //   question_type: "YesNo",
  //   media_type: "",
  //   images: null,
  //   options: [
  //     {
  //       risk: false,
  //       text: "None (no trees on the campus)",
  //       score: "1",
  //       correct: true,
  //       riskText: "",
  //     },
  //     {
  //       risk: false,
  //       text: "Less than 5-10 trees per acre of area",
  //       score: 2,
  //       correct: false,
  //       riskText: "",
  //     },
  //     {
  //       risk: false,
  //       text: "About 5-10 trees per acre of area ",
  //       score: 3,
  //       correct: false,
  //       riskText: "",
  //     },
  //     {
  //       risk: false,
  //       text: "More than 10 trees per acre of area ",
  //       score: 4,
  //       correct: false,
  //       riskText: "",
  //     },
  //   ],
  //   hint: "",
  //   createdAt: "2023-09-05T04:30:56.000Z",
  //   updatedAt: "2023-09-05T04:30:56.000Z",
  // },
  {
    id: 20,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "How many non-native trees are in the school campus?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "None (no trees on the campus)",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No non-native tress, only indigenous tress",
        score: 2,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Less than 5-10 trees per acre of area",
        score: 3,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "About 5-10 trees per acre of area",
        score: 4,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "More than 10 trees per acre of area (Discuss)",
        score: 5,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  
  {
    id: 21,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Approximately what percentage of the passages, paths and pavements in the school are made of concrete?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Demo7",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "25%",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "50%-75%",
        score: 2,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "75-100% ",
        score: 3,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Nearly All",
        score: 4,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 22,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Do you see weeds in your school campus? If yes, how frequently?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Demo8",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Very frequently",
        score: 1,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Rarely",
        score: 2,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Seldom",
        score: 3,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Never",
        score: 4,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 23,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "What procedure is adopted to remove weeds?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Demo9",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Manual removal of weeds",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "Use of pesticides/herbicides",
        score: 2,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Any other (specify)",
        score: 3,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 24,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "What type of manure/fertilizes are used for plants?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Bio10",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Only Compost (prepared at school)",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "Mostly Chemical fertilizers",
        score: "2",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "Organic compost purchased",
        score: 3,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 25,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Does the school make of use indigenous methods (washing/ neem cake/ oil/ bio enzymes) to tackle pests?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Bio11",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 26,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Is green waste composted in your school?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Bio12",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 27,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Is there a possibility/ scope for converting rooftops and other concrete/barren spaces into green areas?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Bio13",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 28,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Is there a possibility to replace open concrete surfaces with permeable pavements for percolation of rain water into the ground?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Bio14",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 29,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Does your schools organize any talk/interactive session to emphasize interdependence of living creatures and encourage values and virtues of cooperation and cohabitation? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Bio15",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 30,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: ". Do the school authorities/ personnel prefer to gift potted plants/ saplings/ cuttings to guests, visitor and awardees? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Bio16",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  //__________________________________________________________________
  //_________________________Water Audit______________________________
  //__________________________________________________________________

  {
    id: 31,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Does your school have one source of water? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Water1",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 32,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "What source/s does your school draw water from?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Water2",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Municipal water",
        score: "1",
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Ground water (Bore well)",
        score: "1",
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Packed water",
        score: "1",
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Tanker",
        score: "1",
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Any other (please specify)",
        score: "1",
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  {
    id: 33,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Does your school use the same source of water for drinking and sanitation?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Water3",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 34,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "What process/procedure has your school adopted to make water potable (fit for drinking)?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: true, // As it requires a description
    comment_required: true,
    files_required: false,
    description: "",
    question_type: "Water4", // Since it's an open-ended question
    media_type: "",
    images: null,
    options: [], // No options as it is an open-ended question
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 35,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Is drinking water tested for potability in accredited labs?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Water5",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
        sub_options: [
          {
            text: "Once a year",
            score: "1",
            correct: false,
          },
          {
            text: "Twice a year",
            score: "1",
            correct: false,
          },
          {
            text: "Quarterly",
            score: "1",
            correct: false,
          },
          {
            text: "Any other periodicity, Please specify",
            score: "1",
            correct: false,
            input: true, // Input field for custom periodicity
          },
        ],
      },
      {
        risk: false,
        text: "No",
        score: "0",
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 36,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "What is the total water consumption of the school?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: true,
    comment_required: true,
    files_required: false,
    description: "",
    question_type: "Water6",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Municipal water (Cubic meters)",
        score: "1",
        correct: false,
        riskText: "",
        description:
          "Calculate using annual water bills from municipal authorities. Average annual water bills to find out mean consumption per month.",
      },
      {
        risk: false,
        text: "Ground water - Bore well",
        score: "1",
        correct: false,
        riskText: "",
        description:
          "Calculate hours the pump runs and the volume of bore well outlet. Borewells are banned in most states in India.",
      },
      {
        risk: false,
        text: "Tankers",
        score: "1",
        correct: false,
        riskText: "",
        description:
          "Calculate the capacity of tankers and multiply by the number of tankers.",
      },
      {
        risk: false,
        text: "Packaged drinking water",
        score: "1",
        correct: false,
        riskText: "",
        description: "Quantity purchased per month/quarter.",
      },
      {
        risk: false,
        text: "Calculate average consumption per person of the school",
        score: "1",
        correct: false,
        riskText: "",
        description:
          "Divide total volume by the number of people in school (students, teachers, and other employees) to calculate per capita water consumption and cost.",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 37,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Does your school have a water conservation policy? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Water7",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 38,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "If YES, then what water conservation practices does your school adopt?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Water8",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Do majority of taps in your school have fixtures that minimize loss of water (low flow taps, self-closing taps, use of sensors, timer shut-off devices, use of low regulators, etc.)?",
        score: 1,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Do you have a dual flush system in your school toilets to minimize loss of water?",
        score: 2,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Are float-valves fixed in various water tanks to avoid water wastage?",
        score: 3,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Is there a mechanism for grey water recycling (reuse of water used for washing, AC water) in your school?",
        score: 4,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Is rain-water harvesting done in the school?",
        score: 5,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-11-26T00:00:00.000Z", // Current Date
    updatedAt: "2023-11-26T00:00:00.000Z", // Current Date
  },
  {
    id: 39,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "How quickly are leaking taps of toilets/overhead tanks etc. repaired?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Water9",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Promptly",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "In a day or So",
        score: 2,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 40,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Are the lids of the water tanks secure? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Water10",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 41,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "How often are storage tanks cleaned?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Water11",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Once a year",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "Twice a year",
        score: 2,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Quarterly",
        score: 3,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Any other periodicity?",
        score: 4,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 42,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "What is the approximate area of lawns with green grass in your school that requires regular watering? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Water12",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 43,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: " Which of the following methods of irrigation is used for watering of grass and plants in the school?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Water13",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Manual watering using a garden hose pipe",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "Sprinkler system with long range sprinklers",
        score: "2",
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Sprinkler system with short range sprinklers",
        score: "3",
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Drip irrigation system",
        score: "4",
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Any other type of system",
        score: 5,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 44,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "What system does the school use to harvest rainwater?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Water14",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Direct storage",
        score: "1",
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Rain-water harvesting tanks",
        score: "2",
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Direct storage to raw water storage tanks",
        score: "3",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "Recharge into the ground aquifer",
        score: 4,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 45,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Are unpaved/green surfaces available in school for percolation of rain water? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Water15",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 46,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "What is the capacity of rain water storage tanks? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Water16",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 47,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    text: "How many tanks/structures and their locations?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: true,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Water17",
    media_type: "",
    images: null,
    options: [],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
    fields: [
      {
        fieldType: "input",
        id: "tanksCount",
        name: "tanksCount",
        label: "Enter the number of rain water storage tanks/structures:",
        placeholder: "Enter number of tanks/structures",
        required: true,
        type: "number",
        style: {
          padding: "8px",
          margin: "8px 0",
          width: "200px",
          borderRadius: "4px",
          border: "1px solid #ccc",
        },
      },
      {
        fieldType: "textarea",
        id: "tankLocations",
        name: "tankLocations",
        label: "Indicate the location of rain water storage tanks:",
        placeholder: "Describe the location of tanks",
        required: true,
        rows: 4,
        style: {
          padding: "8px",
          margin: "8px 0",
          width: "300px",
          borderRadius: "4px",
          border: "1px solid #ccc",
        },
      },
    ],
  },
  {
    id: 48,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    text: "How is the water harvested utilized?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Water18",
    media_type: "",
    images: null,
    options: [
      {
        text: "Gardening",
        score: "1",
        correct: false,
      },
      {
        text: "Toilets",
        score: "2",
        correct: false,
      },
      {
        text: "Cleaning of school",
        score: "3",
        correct: false,
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
    fields: [
      {
        fieldType: "checkbox",
        name: "waterUsage",
        label: "Internal recycling in school campus applications such as:",
        options: [
          {
            label: "Gardening",
            value: "Gardening",
          },
          {
            label: "Toilets",
            value: "Toilets",
          },
          {
            label: "Cleaning of school",
            value: "Cleaning of school",
          },
        ],
      },
    ],
  },
  {
    id: 49,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Is Ground water recharged through trenches?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Water19",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
    fields: [
      {
        fieldType: "radio",
        name: "groundWaterRecharge",
        label: "Is Ground water recharged through trenches?",
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
        ],
      },
      {
        fieldType: "radio",
        name: "externalRecycling",
        label: "External recycling: It is recycled into the main supply",
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
        ],
      },
    ],
  },
  {
    id: 50,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Are awareness campaigns conducted for conservation of water in your school? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Water20",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 51,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Are gardeners,cooks,safaisevaks and others in your school aware of the need and ways to conserve water?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Water21",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 52,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Are water conservation stickers/signs/postersfixed in bathrooms, canteens, classrooms and other places to remind students of good practices?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Water22",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  //______________________________________________________________________________
  //______________________________Waste Audit_____________________________________
  //______________________________________________________________________________

  {
    id: 53,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Campus Survey: Is your school campus litter free? (Litter: Used items such as paper, cans, andbottles left lying in an open or public place)",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Waste1",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
  "id": 54,
  "complianceId": 3,
  "categoryId": 7,
  "category": "Waste Audit",
  "desc_modal": [
    {
      "img": "",
      "description": ""
    }
  ],
  "text": "As you walk in the school campus, do you find dustbins at all places/regular intervals?",
  "images_required": false,
  "image_max": null,
  "file_max": null,
  "required": false,
  "comment_required": false,
  "files_required": false,
  "description": "",
  "question_type": "Waste2",
  "media_type": "",
  "images": null,
  "options": [
    {
      "text": "Yes",
      "score": "1",
      "correct": true,
      "risk": false,
      "subOptions": [
        {
          "id": "a",
          "text": "If yes, is their number adequate?",
          "options": [
            {
              "text": "Yes",
              "score": "1",
              "correct": true,
              "risk": false
            },
            {
              "text": "No",
              "score": "0",
              "correct": false,
              "risk": false
            }
          ]
        },
        {
          "id": "b",
          "text": "How often do you see dustbins overflowing or students overcrowding dustbin areas?",
          "options": [
            {
              "text": "NEVER",
              "score": "3",
              "correct": true,
              "risk": false
            },
            {
              "text": "RARELY",
              "score": "2",
              "correct": false,
              "risk": false
            },
            {
              "text": "FREQUENTLY",
              "score": "0",
              "correct": false,
              "risk": false
            }
          ]
        }
      ]
    },
    {
      "text": "No",
      "score": "0",
      "correct": false,
      "risk": false
    }
  ],
  "hint": "",
  "createdAt": "2023-09-05T04:30:56.000Z",
  "updatedAt": "2023-09-05T04:30:56.000Z"
}

,  
{
  "id": 55,
  "complianceId": 3,
  "categoryId": 7,
  "category": "Waste Audit",
  "text": "Are there different dustbins for different types of waste?",
  "images_required": false,
  "image_max": null,
  "file_max": null,
  "required": false,
  "comment_required": false,
  "files_required": false,
  "description": "",
  "question_type": "Waste3",
  "media_type": "",
  "images": null,
  "options": [
    {
      "text": "Yes",
      "score": "1",
      "correct": true,
      "risk": false,
      "subOptions": [
        {
          "id": "a",
          "text": "If yes, specify how are they identified?",
          "options": [
            {
              "text": "Color Coded",
              "score": "1",
              "correct": true,
              "risk": false
            },
            {
              "text": "Labeled",
              "score": "1",
              "correct": true,
              "risk": false
            },
            {
              "text": "Other",
              "score": "1",
              "correct": true,
              "risk": false
            }
          ]
        }
      ]
    },
    {
      "text": "No",
      "score": "0",
      "correct": false,
      "risk": false
    }
  ],
  "additionalQuestion": {
    "id": "b",
    "text": "Do the dustbins have proper covers/lids?",
    "options": [
      {
        "text": "Yes",
        "score": "1",
        "correct": true,
        "risk": false
      },
      {
        "text": "No",
        "score": "0",
        "correct": false,
        "risk": false
      }
    ]
  },
  "hint": "",
  "createdAt": "2023-09-05T04:30:56.000Z",
  "updatedAt": "2023-09-05T04:30:56.000Z"
}
,
{
  "id": 56,
  "complianceId": 3,
  "categoryId": 7,
  "category": "Waste Audit",
  "text": "Tick the categories into which waste is segregated into your school:",
  "options": [
    {
      "text": "Dry waste",
      "selected": false
    },
    {
      "text": "Wet waste (Biodegradable)",
      "selected": false
    },
    {
      "text": "E-waste",
      "selected": false
    },
    {
      "text": "Hazardous waste from the labs",
      "selected": false
    },
    {
      "text": "Chemical waste",
      "selected": false
    },
    {
      "text": "Paints",
      "selected": false
    },
    {
      "text": "Sanitary waste",
      "selected": false
    },
    {
      "text": "Garden waste",
      "selected": false
    },
    {
      "text": "Miscellaneous",
      "selected": false
    }
  ],
  "images_required": false,
  "image_max": null,
  "file_max": null,
  "required": false,
  "comment_required": false,
  "files_required": false,
  "description": "",
  "question_type": "Waste5",
  "media_type": "",
  "hint": "",
  "createdAt": "2023-09-05T04:30:56.000Z",
  "updatedAt": "2023-09-05T04:30:56.000Z"
}
,
{
  id: 57,
  complianceId: 3,
  categoryId: 7,
  category: "Waste Audit",
  desc_modal: [
    {
      img: "",
      description: "",
    },
  ],
  text: "Is dry waste segregated in your school?",
  sub_question: null,
  images_required: false,
  image_max: null,
  file_max: null,
  required: false,
  comment_required: false,
  files_required: false,
  description: "",
  question_type: "Waste6",
  media_type: "",
  images: null,
  options: [
    {
      risk: false,
      text: "Yes",
      score: "1",
      correct: true,
      riskText: "",
    },
    {
      risk: false,
      text: "No",
      score: "0",
      correct: false,
      riskText: "",
    },
  ],
  hint: "",
  createdAt: "2023-09-05T04:30:56.000Z",
  updatedAt: "2023-09-05T04:30:56.000Z",
},
{
  "id": 58,
  "complianceId": 3,
  "categoryId": 7,
  "category": "Waste Audit",
  "desc_modal": [
    {
      "img": "",
      "description": ""
    }
  ],
  "text": "Kitchen/Canteen waste",
  "sub_question": null,
  "images_required": false,
  "image_max": null,
  "file_max": null,
  "required": false,
  "comment_required": false,
  "files_required": false,
  "description": "",
  "question_type": "Waste7",
  "media_type": "",
  "images": null,
  "options": [
    {
      "text": "Fresh food only",
      "score": "1",
      "risk": false,
      "correct": true,
      "riskText": ""
    },
    {
      "text": "Packaged food only",
      "score": "0",
      "risk": false,
      "correct": false,
      "riskText": ""
    },
    {
      "text": "Both fresh and packaged food",
      "score": "0",
      "risk": false,
      "correct": false,
      "riskText": ""
    },
    {
      "text": "Steel/utensils",
      "score": "1",
      "risk": false,
      "correct": true,
      "riskText": ""
    },
    {
      "text": "Plastic plates & disposables",
      "score": "0",
      "risk": false,
      "correct": false,
      "riskText": ""
    },
    {
      "text": "Wooden cutlery",
      "score": "0",
      "risk": false,
      "correct": false,
      "riskText": ""
    },
    {
      "text": "Bring your own cutlery",
      "score": "0",
      "risk": false,
      "correct": false,
      "riskText": ""
    },
    {
      "text": "Any other",
      "score": "0",
      "risk": false,
      "correct": false,
      "riskText": ""
    }
  ],
  "hint": "",
  "createdAt": "2023-09-05T04:30:56.000Z",
  "updatedAt": "2023-09-05T04:30:56.000Z"
}
,
{
  "id": 59,
  "complianceId": 3,
  "categoryId": 7,
  "category": "Waste Audit",
  "desc_modal": [
    {
      "img": "",
      "description": ""
    }
  ],
  "text": "How frequently are dust/waste bins emptied?",
  "sub_question": null,
  "images_required": false,
  "image_max": null,
  "file_max": null,
  "required": false,
  "comment_required": false,
  "files_required": false,
  "description": "",
  "question_type": "Waste8",
  "media_type": "",
  "images": null,
  "options": [
    {
      "risk": false,
      "text": "Daily",
      "score": "1",
      "correct": true,
      "riskText": ""
    },
    {
      "risk": false,
      "text": "Two times a day",
      "score": "2",
      "correct": false,
      "riskText": ""
    },
    {
      "risk": false,
      "text": "Weekly",
      "score": "3",
      "correct": false,
      "riskText": ""
    },
    {
      "risk": false,
      "text": "Any other",
      "score": "4",
      "correct": false,
      "riskText": ""
    }
  ],
  "hint": "",
  "createdAt": "2023-09-05T04:30:56.000Z",
  "updatedAt": "2023-09-05T04:30:56.000Z"
}
,

  {
    id: 60,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "How does your school dispose-off dry waste :-",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  {
    id: 60,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Is composting an organized activity and encouraged for students and staff in your school?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Waste10",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: "0",
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Is the compost prepared used for gardening in the school?",
        score: null,
        correct: null,
        riskText: "",
        sub_question: [
          {
            risk: false,
            text: "Yes",
            score: "1",
            correct: true,
            riskText: "",
          },
          {
            risk: false,
            text: "No",
            score: "0",
            correct: false,
            riskText: "",
          },
        ],
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  {
    id: 62,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Does your school advocate and provide information to reduce waste in school?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Waste11",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  {
    id: 63,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Are posters/slogans placed at various points in school to remind students of proper ways of waste disposal?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Waste12",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  {
    id: 64,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Paper waste",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "Please tick mark the practices which your school encourages for encouraging the reduction in use of paper:",
    question_type: "Waste13",
    media_type: "",
    images: null,
    options: [
      {
        text: "Does your school encourage use of single side paper for printing and other purposes?",
        sub_options: [
          {
            text: "Yes",
            score: "1",
            correct: true,
            riskText: "",
          },
          {
            text: "No",
            score: "0",
            correct: false,
            riskText: "",
          },
        ],
      },
      {
        text: "Does your school administration practice double side printing and photocopy?",
        sub_options: [
          {
            text: "Yes",
            score: "1",
            correct: true,
            riskText: "",
          },
          {
            text: "No",
            score: "0",
            correct: false,
            riskText: "",
          },
        ],
      },
      {
        text: "Does your school discourage the use of paper decorations and other promotional materials?",
        sub_options: [
          {
            text: "Yes",
            score: "1",
            correct: true,
            riskText: "",
          },
          {
            text: "No",
            score: "0",
            correct: false,
            riskText: "",
          },
        ],
      },
      {
        text: "Does your school encourage use of digital/electronic version of assignments, reports, newsletters, etc.?",
        sub_options: [
          {
            text: "Yes",
            score: "1",
            correct: true,
            riskText: "",
          },
          {
            text: "No",
            score: "0",
            correct: false,
            riskText: "",
          },
        ],
      },
      {
        text: "Has your school encouraged/tried making Paper-Mache items?",
        sub_options: [
          {
            text: "Yes",
            score: "1",
            correct: true,
            riskText: "",
          },
          {
            text: "No",
            score: "0",
            correct: false,
            riskText: "",
          },
        ],
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  {
    id: 65,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Is there a student appreciation/recognition reward system for observing efficient waste management practices?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: true, // because there is a section to specify the nature of the reward system
    files_required: false,
    description: "",
    question_type: "Waste14", // Radio button for Yes or No
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: "0",
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
    sub_questions: [
      {
        text: "If yes, specify its nature...",
        question_type: "TextArea",
        required: false,
        files_required: false,
        comment_required: true, // since it's an open text field to specify nature
        description: "",
      },
    ],
  }
,  

  //________________________________________________________________________________
  //________________________Transport_______________________________________________
  //________________________________________________________________________________

  {
    "id": 66,
    "complianceId": 3,
    "categoryId": 8,
    "category": "Transport",
    "desc_modal": [
      {
        "img": "",
        "description": ""
      }
    ],
    "text": "What is the total strength of the school?",
    "sub_question": null,
    "images_required": false,
    "image_max": null,
    "file_max": null,
    "required": false,
    "comment_required": false,
    "files_required": false,
    "description": "",
    "question_type": "Transport1",
    "media_type": "",
    "images": null,
    "options": [
      {
        "risk": false,
        "text": "Number of students",
        "field_type": "number",
        "placeholder": "Enter the number of students",
        "score": null,
        "correct": null,
        "riskText": ""
      },
      {
        "risk": false,
        "text": "Number of teachers",
        "field_type": "number",
        "placeholder": "Enter the number of teachers",
        "score": null,
        "correct": null,
        "riskText": ""
      },
      {
        "risk": false,
        "text": "Administrative staff",
        "field_type": "number",
        "placeholder": "Enter the number of administrative staff",
        "score": null,
        "correct": null,
        "riskText": ""
      },
      {
        "risk": false,
        "text": "Support staff (in the Labs, Library, Sports, Security, Maintenance, etc.)",
        "field_type": "number",
        "placeholder": "Enter the number of support staff",
        "score": null,
        "correct": null,
        "riskText": ""
      }
    ],
    "hint": "",
    "createdAt": "2023-09-05T04:30:56.000Z",
    "updatedAt": "2023-09-05T04:30:56.000Z"
  }
  ,
{
    id: 67,
    complianceId: 3,
    categoryId: 8,
    category: "Transport",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Write the approximate percentage of the school students who travel to and from school by various modes of transportation.",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "(You will need to do a survey in school by asking the students. You can as well use a Google form or Survey Monkey to get estimates. For junior students, please visit the classrooms and talk to the students regarding the same.)",
    question_type: "Transport2",
    media_type: "",
    images: null,
    options: [
      {
        number: "01",
        risk: false,
        text: "Walkers",
        inputType: "number",
        placeholder: "Enter percentage for walkers",
      },
      {
        number: "02",
        risk: false,
        text: "Bicycle",
        inputType: "number",
        placeholder: "Enter percentage for bicycles",
      },
      {
        number: "03",
        risk: false,
        text: "School transport",
        inputType: "number",
        placeholder: "Enter percentage for school transport",
      },
      {
        number: "04",
        risk: false,
        text: "Private transport/personal vehicle",
        inputType: "number",
        placeholder: "Enter percentage for private transport",
      },
      {
        number: "05",
        risk: false,
        text: "Public vehicle",
        inputType: "number",
        placeholder: "Enter percentage for public vehicle",
      },
      {
        number: "06",
        risk: false,
        text: "Any other",
        inputType: "text",
        placeholder: "Enter mode of transport",
        additionalInput: {
          inputType: "number",
          placeholder: "Enter percentage for specified transport",
        },
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  }
  ,
  {
    "id": 68,
    "complianceId": 3,
    "categoryId": 8,
    "category": "Transport",
    "desc_modal": [
      {
        "img": "",
        "description": ""
      }
    ],
    "text": "Write the approximate percentage of the school teachers and other staff who travel to and from the school by various modes of transportation.",
    "sub_question": null,
    "images_required": false,
    "image_max": null,
    "file_max": null,
    "required": false,
    "comment_required": false,
    "files_required": false,
    "description": "",
    "question_type": "Transport3",
    "media_type": "",
    "images": null,
    "options": [
      {
        "risk": false,
        "text": "01. Walkers",
        "score": null,
        "correct": null,
        "riskText": ""
      },
      {
        "risk": false,
        "text": "02. Bicycle",
        "score": null,
        "correct": null,
        "riskText": ""
      },
      {
        "risk": false,
        "text": "03. School transport",
        "score": null,
        "correct": null,
        "riskText": ""
      },
      {
        "risk": false,
        "text": "04. Private transport/personal vehicle",
        "score": null,
        "correct": null,
        "riskText": ""
      },
      {
        "risk": false,
        "text": "05. Public vehicle",
        "score": null,
        "correct": null,
        "riskText": ""
      },
      {
        "risk": false,
        "text": "06. Any other (specify)",
        "score": null,
        "correct": null,
        "riskText": ""
      }
    ],
    "hint": "Please provide the percentage for each category of transport used by the teachers and other staff.",
    "createdAt": "2023-09-05T04:30:56.000Z",
    "updatedAt": "2023-09-05T04:30:56.000Z"
  }
  ,
  {
    id: 69,
    complianceId: 3,
    categoryId: 8,
    category: "Transport",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Does your school have transport facility (buses/ cars/ vans/ matadors etc.) available for students and staff? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 70,
    complianceId: 3,
    categoryId: 8,
    category: "Transport",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "If the school has its own transport vehicles:",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "Transport5",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "01. How would you rate the condition of buses/school transport?",
        score: null,
        correct: null,
        riskText: "",
        sub_options: [
          {
            text: "Very good",
            score: null,
            correct: null,
          },
          {
            text: "Good",
            score: null,
            correct: null,
          },
          {
            text: "Average",
            score: null,
            correct: null,
          },
          {
            text: "Bad",
            score: null,
            correct: null,
          },
          {
            text: "Very Bad",
            score: null,
            correct: null,
          },
        ],
      },
      {
        risk: false,
        text: "02. Are buses and other vehicles provided with horns, lights, reflectors, reversing lights and other safety features?",
        score: null,
        correct: null,
        riskText: "",
        sub_options: [
          {
            text: "Yes",
            score: null,
            correct: null,
          },
          {
            text: "No",
            score: null,
            correct: null,
          },
        ],
      },
      {
        risk: false,
        text: "03. Is the preventive maintenance of school transport done regularly?",
        score: null,
        correct: null,
        riskText: "",
        sub_options: [
          {
            text: "Yes",
            score: null,
            correct: null,
          },
          {
            text: "No",
            score: null,
            correct: null,
          },
        ],
      },
      {
        risk: false,
        text: "04. Are pollution checks regularly done for school buses and other vehicles? (You can verify by looking at the records)",
        score: null,
        correct: null,
        riskText: "",
        sub_options: [
          {
            text: "Yes",
            score: null,
            correct: null,
          },
          {
            text: "No",
            score: null,
            correct: null,
          },
        ],
      },
      {
        risk: false,
        text: "05. Is there adequate parking space for vehicles in the school campus?",
        score: null,
        correct: null,
        riskText: "",
        sub_options: [
          {
            text: "Yes",
            score: null,
            correct: null,
          },
          {
            text: "No",
            score: null,
            correct: null,
          },
        ],
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  }
  ,
  {
    id: 71,
    complianceId: 3,
    categoryId: 8,
    category: "Transport",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "What is the number of buses and other vehicles that school owns and the fuels used by them? (You would get thisinformation through the school transport in-charge/school office records)",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  {
    id: 72,
    complianceId: 3,
    categoryId: 8,
    category: "Transport",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Calculate the quantity of fuel consumed and the cost of different fuels used by yourschool transport permonth.",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description: "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  {
    "id": 73,
    "complianceId": 3,
    "categoryId": 8,
    "category": "Transport",
    "desc_modal": [
      {
        "img": "",
        "description": ""
      }
    ],
    "text": "Calculate the per person consumption of fuel and cost by dividing the liters consumed/amount spent by the number of persons in the school.",
    "sub_question": null,
    "images_required": false,
    "image_max": null,
    "file_max": null,
    "required": false,
    "comment_required": false,
    "files_required": false,
    "description": "",
    "question_type": "Transport8",
    "media_type": "",
    "images": null,
    "options": [
      {
        "risk": false,
        "text": "01. Fuel consumed per person (Total fuel consumed / Number of persons in the school)",
        "score": null,
        "correct": null,
        "riskText": ""
      },
      {
        "risk": false,
        "text": "02. Cost of transport per person (Excludes the use of personal car / public and other private transport)",
        "score": null,
        "correct": null,
        "riskText": ""
      }
    ],
    "hint": "Please input the total fuel consumed and the number of persons in the school to calculate the fuel consumed per person. Also, input the total transport cost to calculate the cost per person.",
    "createdAt": "2023-12-02T04:30:56.000Z",
    "updatedAt": "2023-12-02T04:30:56.000Z"
  }
  ,

  {
    "id": 74,
    "complianceId": 3,
    "categoryId": 8,
    "category": "Transport",
    "desc_modal": [
      {
        "img": "",
        "description": ""
      }
    ],
    "text": "Does the school organize any programs to encourage the use of public transport, carpooling, etc. and to sensitize regarding the minimum use of non-renewable fuels?",
    "sub_question": [
      {
        "question": "02. If yes, what is the nature of these programs and how frequently are they organized?",
        "table": {
          "columns": [
            { "header": "Program", "name": "program" },
            { "header": "Target Group", "name": "targetGroup" },
            { "header": "Frequency", "name": "frequency", "options": ["Regularly", "Frequently", "Occasionally", "Rarely"] }
          ],
          "rows": [
            {
              "program": "",
              "targetGroup": "",
              "frequency": ""
            },
            {
              "program": "",
              "targetGroup": "",
              "frequency": ""
            },
            {
              "program": "",
              "targetGroup": "",
              "frequency": ""
            }
          ]
        }
      }
    ],
    "images_required": false,
    "image_max": null,
    "file_max": null,
    "required": false,
    "comment_required": false,
    "files_required": false,
    "description": "",
    "question_type": "Transpot9",
    "media_type": "",
    "images": null,
    "hint": "If the school organizes any such programs, please provide the details of each program including the target group and the frequency of organization.",
    "createdAt": "2023-09-05T04:30:56.000Z",
    "updatedAt": "2023-09-05T04:30:56.000Z",
    "options": [
      {
        "risk": false,
        "text": "Yes",
        "score": null,
        "correct": null,
        "riskText": ""
      },
      {
        "risk": false,
        "text": "No",
        "score": null,
        "correct": null,
        "riskText": ""
      }
    ]
  }
  ,
];
