import React from "react";

import {
  Box,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
  FormControl,
  Radio,
  RadioGroup,
} from "@mui/material";

function Notpassed() {
  return (
    <Box
      sx={{
        border: {
          xs: "1.5px solid #2356fe",
          md: "2px solid #2356fe",
          lg: "3px solid #2356fe",
        },
        margin: {
          xs: "2rem auto",
          md: "3rem auto",
          lg: "5rem auto",
        },
        width: {
          xs: "95%",
          md: "90%",
          lg: "70%",
        },
        padding: {
          xs: "0.4rem",
          md: "1rem",
          lg: "2rem",
        },
        borderRadius: "1rem",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          textAlign: { xs: "start", sm: "start", md: "start", lg: "center" },
          marginBottom: "2rem",
        }}
      >
        Eat Right School Matrix minimum marks required!
      </Typography>
      <Typography variant="p" sx={{ marginBottom: "2rem" }}>
        This is to inform you that, in order to maintain the academic integrity
        and standards of the certification process, it is required that
        institute must achieve a minimum of 75% marks in all the questions
        provided. This benchmark is set to ensure that institute have
        demonstrated a strong grasp of the material, showcasing their competence
        and readiness. Achieving 75% marks across all questions is essential to
        certify that the institute has adequately understood the subject matter
        and is fully prepared for future challenges. This requirement is crucial
        for maintaining the value and credibility of the certification process.
        We kindly request your cooperation in adhering to this policy. It is our
        goal to ensure that all institute earn their certification by meeting
        the necessary standards of knowledge and proficiency. Thank you for your
        attention and support.
      </Typography>
    </Box>
  );
}

export default Notpassed;
