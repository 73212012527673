import React, { useState } from "react";

const WaterTestedQuestion = () => {
  const questionData = {
    id: 35,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    text: "Is drinking water tested for potability in accredited labs?",
    options: [
      {
        text: "Yes",
        score: "1",
        correct: true,
        sub_options: [
          { text: "Once a year", score: "1", correct: false },
          { text: "Twice a year", score: "1", correct: false },
          { text: "Quarterly", score: "1", correct: false },
          {
            text: "Any other periodicity, Please specify",
            score: "1",
            correct: false,
            input: true,
          },
        ],
      },
      { text: "No", score: "0", correct: false },
    ],
  };

  const [selectedOption, setSelectedOption] = useState("");
  const [testFrequency, setTestFrequency] = useState("");
  const [otherFrequency, setOtherFrequency] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "No") {
      setTestFrequency(""); // Reset test frequency when No is selected
    }
  };

  const handleFrequencyChange = (event) => {
    setTestFrequency(event.target.value);
  };

  const handleOtherFrequencyChange = (event) => {
    setOtherFrequency(event.target.value);
  };

  // CSS Styles
  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      fontSize: "14px",
      lineHeight: "1.6",
      margin: "20px 40px",
      color: "#333",
    },
    questionTitle: {
      fontSize: "16px",
      marginBottom: "16px",
      fontWeight: "bold",
    },
    inputGroup: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      marginTop: "10px",
      marginLeft: "60px", // Added marginLeft for Yes/No options
    },
    label: {
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    subOptionsGroup: {
      marginTop: "10px",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      marginLeft: "60px", // Added marginLeft for sub-options
    },
    input: {
      marginRight: "8px",
    },
    inputText: {
      marginLeft: "10px",
      fontSize: "14px",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.questionTitle}>Q5. {questionData.text}</h1>
      {/* Horizontal Yes/No Options */}
      <div style={styles.inputGroup}>
        {questionData.options.map((option, index) => (
          <label key={index} style={styles.label}>
            <input
              type="radio"
              name="waterTested"
              value={option.text}
              checked={selectedOption === option.text}
              onChange={handleOptionChange}
              style={styles.input}
            />
            {option.text}
          </label>
        ))}
      </div>

      {/* Show sub-options if Yes is selected */}
      {selectedOption === "Yes" && (
        <div style={styles.subOptionsGroup}>
          <h2 style={{ fontSize: "16px", marginBottom: "10px" }}>If yes, how often?</h2>
          {questionData.options[0].sub_options.map((subOption, index) => (
            <label key={index} style={styles.label}>
              <input
                type="radio"
                name="testFrequency"
                value={subOption.text}
                checked={testFrequency === subOption.text}
                onChange={handleFrequencyChange}
                style={styles.input}
              />
              {subOption.text}
              {subOption.input && testFrequency === subOption.text && (
                <input
                  type="text"
                  value={otherFrequency}
                  onChange={handleOtherFrequencyChange}
                  placeholder="Specify here..."
                  style={styles.inputText}
                />
              )}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default WaterTestedQuestion;
