import React, { useState } from "react";

const GreenWasteCompostQuestion = () => {
  const questionData = {
    id: 26,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    text: "Is green waste composted in your school?",
    options: [
      { text: "Yes", score: "1", correct: true },
      { text: "No", score: 0, correct: false },
    ],
  };

  const [selectedOption, setSelectedOption] = useState("");

  // CSS Styles
  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      fontSize: "14px",
      lineHeight: "1.6",
      margin: "20px 40px",
      color: "#333",
    },
    heading: {
      fontSize: "16px",
      marginBottom: "16px",
      fontWeight: "bold",
    },
    section: {
      marginBottom: "20px",
      marginLeft: "40px",
    },
    label: {
      display: "block",
      marginBottom: "8px",
      fontSize: "14px",
      cursor: "pointer",
    },
    input: {
      marginRight: "10px",
    },
    inputGroup: {
      display: "flex",  // Aligning Yes and No horizontally
      alignItems: "center",
      gap: "10px", // Space between Yes and No
      marginTop: "6px",
    },
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Q7. {questionData.text}</h1>
      <ol type="A" style={{ paddingLeft: "20px" }}>
        <li style={styles.section}>
          <div style={styles.inputGroup}>
            {questionData.options.map((option, index) => (
              <label key={index} style={styles.label}>
                <input
                  type="radio"
                  name="greenWasteCompost"
                  value={option.text}
                  checked={selectedOption === option.text}
                  onChange={() => handleOptionChange(option.text)}
                  style={styles.input}
                />
                {option.text}
              </label>
            ))}
          </div>
        </li>
      </ol>
    </div>
  );
};

export default GreenWasteCompostQuestion;
