import React, { useState } from "react";

const DustbinSurveyQuestion = () => {
  const questionData = {
    id: 54,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    text: "As you walk in the school campus, do you find dustbins at all places/regular intervals?",
    options: [
      {
        text: "Yes",
        score: "1",
        correct: true,
        risk: false,
        subOptions: [
          {
            id: "a",
            text: "If yes, is their number adequate?",
            options: [
              { text: "Yes", score: "1", correct: true, risk: false },
              { text: "No", score: "0", correct: false, risk: false },
            ],
          },
          {
            id: "b",
            text: "How often do you see dustbins overflowing or students overcrowding dustbin areas?",
            options: [
              { text: "NEVER", score: "3", correct: true, risk: false },
              { text: "RARELY", score: "2", correct: false, risk: false },
              { text: "FREQUENTLY", score: "0", correct: false, risk: false },
            ],
          },
        ],
      },
      {
        text: "No",
        score: "0",
        correct: false,
        risk: false,
      },
    ],
  };

  const [responses, setResponses] = useState({
    mainQuestion: "",
    subResponses: {},
  });

  const handleOptionChange = (questionKey, value) => {
    setResponses((prev) => ({
      ...prev,
      [questionKey]: value,
    }));
  };

  const handleSubOptionChange = (subKey, value) => {
    setResponses((prev) => ({
      ...prev,
      subResponses: {
        ...prev.subResponses,
        [subKey]: value,
      },
    }));
  };

  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      fontSize: "14px",
      lineHeight: "1.6",
      margin: "20px 40px",
      color: "#333",
    },
    questionTitle: {
      fontSize: "16px",
      marginBottom: "16px",
      fontWeight: "bold",
    },
    inputGroup: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      marginTop: "10px",
      marginLeft: "60px",
    },
    label: {
      fontSize: "14px",
    },
    input: {
      marginRight: "6px",
    },
    subQuestion: {
      fontSize: "14px",
      fontWeight: "normal",
      marginBottom: "12px",
      marginLeft: "20px",
    },
    boldSubQuestion: {
      fontSize: "14px",
      fontWeight: "bold", // Make the sub-question bold
      marginBottom: "12px",
      marginLeft: "20px",
    },
    subLabel: {
      marginLeft: "60px",
      marginBottom: "8px",
      fontSize: "14px",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.questionTitle}>Q2. {questionData.text}</h1>
      <div style={styles.inputGroup}>
        {questionData.options.map((option, index) => (
          <label key={index} style={styles.label}>
            <input
              type="radio"
              name="dustbinsAvailable"
              value={option.text}
              checked={responses.mainQuestion === option.text}
              onChange={() => handleOptionChange("mainQuestion", option.text)}
              style={styles.input}
            />
            {option.text}
          </label>
        ))}
      </div>

      {responses.mainQuestion === "Yes" &&
        questionData.options
          .find((option) => option.text === "Yes")
          ?.subOptions.map((subOption, subIndex) => (
            <div key={subIndex} style={styles.container}>
              {/* Apply bold style to specific sub-questions */}
              <p
                style={
                  subOption.id === "a" || subOption.id === "b"
                    ? styles.boldSubQuestion
                    : styles.subQuestion
                }
              >
                {subOption.id}) {subOption.text}
              </p>
              <div style={styles.inputGroup}>
                {subOption.options.map((subOptionChoice, choiceIndex) => (
                  <label key={choiceIndex} style={styles.label}>
                    <input
                      type="radio"
                      name={subOption.id}
                      value={subOptionChoice.text}
                      checked={
                        responses.subResponses[subOption.id] ===
                        subOptionChoice.text
                      }
                      onChange={() =>
                        handleSubOptionChange(subOption.id, subOptionChoice.text)
                      }
                      style={styles.input}
                    />
                    {subOptionChoice.text}
                  </label>
                ))}
              </div>
            </div>
          ))}
    </div>
  );
};

export default DustbinSurveyQuestion;
