import React, { useEffect, useState } from "react";
import Comp_MCQ from "./formelements/Comp_MCQ";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "global/context";
import SubQuestion4option from "./formelements/SubQuestion4option";
import Comp_Num from "./formelements/Comp_Num";
import ComplianceProgress from "./components/ComplianceProgress";
import { apiAuth, apiJson } from "api";
import { complianceAnswerArray } from "./TempCompliance_answer";
import { complianceIdArray } from "./Temp_Institute_Id";
import { complianceArray } from "./TempCompliance";
import { complianceQuestionArray } from "./TempCompliance_Question_Compliance";
import { complianceCatagloryArray } from "./TempCompliance_Category_Compliance";
import RadioAndFillInBlank from "./components/RadioAndFillInBlank";
import CheckAndFillInBlank from "./components/CheckAndFillInBlank";
import Question4 from "./components/Question4";
import Question4ii from "./components/Question4ii";
import Question5 from "./components/Question5";
import Question6 from "./components/Question6";
import Question7 from "./components/Question7";
import Question8 from "./components/Question8";
import Question9 from "./components/Question9";
import BioDivercity10 from "./components/BioDivercity10";
import BioDivercity11 from "./components/BioDivercity11";
import BioDivercity12 from "./components/BioDivercity12";
import BioDivercity13 from "./components/BioDivercity13";
import BioDivercity14 from "./components/BioDivercity14";
import BioDivercity15 from "./components/BioDivercity15";
import BioDivercity16 from "./components/BioDivercity16";
import WaterAudit1 from "./components/WaterAudit1";
import WaterAudit2 from "./components/WaterAudit2";
import WaterAudit3 from "./components/WaterAudit3";
import WaterAudit4 from "./components/WaterAudit4";
import WaterAudit5 from "./components/WaterAudit5";
import WaterAudit6 from "./components/WaterAudit6";
import WaterAudit7 from "./components/WaterAudit7";
import WaterAudit8 from "./components/WaterAudit8";
import WaterAudit9 from "./components/WaterAudit9";
import WaterAudit10 from "./components/WaterAudit10";
import WaterAudit11 from "./components/WaterAudit11";
import WaterAudit12 from "./components/WaterAudit12";
import WaterAudit13 from "./components/WaterAudit13";
import WaterAudit14 from "./components/WaterAudit14";
import WaterAudit15 from "./components/WaterAudit15";
import WaterAudit16 from "./components/WaterAudit16";
import WaterAudit17 from "./components/WaterAudit17";
import WaterAudit18 from "./components/WaterAudit18";
import WaterAudit19 from "./components/WaterAudit19";
import WaterAudit20 from "./components/WaterAudit20";
import WaterAudit21 from "./components/WaterAudit21";
import WaterAudit22 from "./components/WaterAudit22";
import Waste1 from "./components/WasteAudit/Waste1";
import Waste2 from "./components/WasteAudit/Waste2";
import Waste3 from "./components/WasteAudit/Waste3";
import Waste5 from "./components/WasteAudit/Waste5";
import Waste6 from "./components/WasteAudit/Waste6";
import Waste7 from "./components/WasteAudit/Waste7";
import Waste8 from "./components/WasteAudit/Waste8";
import Waste10 from "./components/WasteAudit/Waste10";
import Waste11 from "./components/WasteAudit/Waste11";
import Waste12 from "./components/WasteAudit/Waste12";
import Waste13 from "./components/WasteAudit/Waste13";
import Waste14 from "./components/WasteAudit/Waste14";
import Transport1 from "./components/Transport/Transport1"
import Transport2 from "./components/Transport/Transport2"
import Transport3 from "./components/Transport/Transport3"
import Transport5 from "./components/Transport/Transport5"
import Transport8 from "./components/Transport/Transport8"
import Transport9 from "./components/Transport/Transport9"


import { OKIcon } from "react-share";
import { BorderHorizontalTwoTone } from "@mui/icons-material";
// import { LastPage } from "@mui/icons-material";

const ComplianceForm = ({ complianceId, reportId }) => {
  const location = useLocation();
  const { page } = useParams();

  const navigate = useNavigate();
  const { userData } = useGlobalContext();
  const [compliance, setCompliance] = useState({});
  const [questions, setQuestions] = useState([]);
  const [answerReport, setAnswerReport] = useState([]);
  const [category, setCategory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showArray, setshowArray] = useState([]);
  const [prev, setprev] = useState(0);
  const [lastPrev, setLastprev] = useState(0);
  const [textArray, setTextArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [requiredQuestions, setRequiredQuestions] = useState([]);
  const [requiredArray, setRequireArray] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [instituteData, setInstituteData] = useState({});
  const [continueAnswer, setContinueAnswer] = useState([]);
  const [attemptQuestion, setAttemptQuestion] = useState([]);
  const [progress, setProgress] = useState(0);
  // const [isFormSaved, setisFormSaved] = useState(false);
  const [answerLoading, setAnswerLoading] = useState(false);
  const [editUrl, setEditUrl] = useState(false);
  const [getQuestionId, setQuestionId] = useState(); 
  // const [pageWiseData, setPageWiseData] = useState([]);
  const [answerText, setAnswerText] = useState(''); // [answerText]
  
  

  const getPageWiseData = () => {
    apiJson.get("categoryWiseData/compliance/" + complianceId)
      .then((res) => {
        // setPageWiseData(res.data.pageWiseData);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  useEffect(() => {
    getPageWiseData();
  }, []);

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      setEditUrl(true);
    }
  }, [location]);

  const calculateProgress = () => {
    let pro = 0;
    let totalQuestion = questions?.length;
    let doneQuestion = attemptQuestion.filter((i) => i === true).length;
    pro = (doneQuestion / totalQuestion) * 100;
    if (!pro) {
      pro = 0;
    }
    setProgress(pro);
  };

  const prefillAttemptQuestion = () => {
    if (continueAnswer.length > 0) {
      let answer = continueAnswer;
      let allQuestion = changeQuestionOrder();
      let booleanArray = new Array(allQuestion.length).fill(false);

      for (let index = 0; index < answer.length; index++) {
        const { questionId } = answer[index];

        const questionIndex = allQuestion.findIndex((question) => question.id === questionId);

        if (questionIndex !== -1) {
          booleanArray[questionIndex] = true;
        }
      }
      setAttemptQuestion(booleanArray);
    }
  };

  const fetchAnswerArray = () => {
    apiJson.get("https://www.api.safeinschool.in/admin/compliance_answer/1026")
    // apiJson.get("admin/compliance_answer/" + reportId)
      .then((res) => {
        // setContinueAnswer(res.data.result.answer);
        // setAnswerReport(res.data.result.answer);
        console.log(complianceAnswerArray, "complianceAnswerArray value compliance form")
        setContinueAnswer(complianceAnswerArray);
        setAnswerReport(complianceAnswerArray);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    if (reportId !== null || reportId !== undefined) {
      fetchAnswerArray();
    }
  }, [reportId]);


  const fetchingDetail = () => {
    const data = complianceIdArray.find((item) => item.instituteId === 4984); 
    if (data) {
      setInstituteData(data);
    } else {
      console.log("No data found for the provided instituteId");
    }
  };


  useEffect(() => {
    fetchingDetail();
  }, []);
  // console.log("showing again", prev);

  const changeQuestionOrder = () => {
    // console.log("inside new ");
    let newQuesArray = [];

    if (questions.length > 0) {
      const allQuestion = questions;

      if (category.length > 0) {
        let n = category.length;
        for (let index = 0; index < n; index++) {
          let ques = allQuestion.filter((q) => q.categoryId === category[index]?.id);
          newQuesArray = newQuesArray.concat(ques);
        }
      }
    }

    // console.log("newques", newQuesArray);
    return newQuesArray;
  };

  const missingQuestionId = () => {
    let indexArray = [];
    const newQuesArray = changeQuestionOrder();
    const requiredids = requiredQuestions.map((q) => q.id);
    // console.log("required ids in missing question", requiredids)
    const answerIds = answerReport.map((q) => q.questionId);
    const missingId = requiredids.filter((id) => !answerIds.includes(id));
    // console.log("answer idsand missing aids",answerIds, missingId)
    if (missingId.length > 0) {
      let temp = [...requiredArray];
      // console.log("this is temp of missing ", temp)
      indexArray = missingId.map((id) => {
        const index = newQuesArray.findIndex((q) => q.id === id);
        if (index !== -1) {
          temp[index] = true;
          return { no: index + 1, id: id };
        }
      });
      setRequireArray(temp);
      // console.log("inside the missingQuestion",temp)
      // setIndexArrays(indexArray)
    }

    return indexArray;
  };

  const handleSubmit = () => {
    // setIsLoading(true);
    // setisFormSaved(true)
    setIsSubmit(true);
    const missingQuestion = missingQuestionId();

    // console.log("these are missing question", missingQuestion);
    if (missingQuestion.length > 0) {
      toast.dismiss();
      const allMissingQuestionNos = missingQuestion.map((item) => item.no).join(", ");

      const missingQuestionMessage = "Question no. " + allMissingQuestionNos + " is Required";

      toast.error(missingQuestionMessage);
      return;
    }
    setIsLoading(true);
    let tempScore = 0;
    let tempTotalScore = 0;
    let tempTotalScore2 = 0;
    // console.log("this is institute id", userData.id);
    answerReport.forEach((ans) => {
      tempScore += Number(ans.score);
    });
    questions?.forEach((q) => {
      let tempMax = 0;
      q.options.forEach((i) => {
        if (tempMax < i.score) {
          tempMax = Number(i.score);
        }
      });
      tempTotalScore += Number(tempMax);
    });
    answerReport.forEach((i) => {
      let tempMax = 0;
      if (tempMax < i.max_score) {
        tempMax = Number(i.max_score);
      }
      tempTotalScore2 += Number(tempMax);
    });

    // setTotalScore(tempTotalScore >= tempTotalScore2 ? tempTotalScore : tempTotalScore2);
    // setTotalAnswered(answerReport.length);
    if (!reportId) {
      apiAuth.post("admin/compliance_answer", {
        complianceId,
        answer: answerReport,
        total_answered: answerReport.length,
        total_score: tempTotalScore > tempTotalScore2 ? tempTotalScore : tempTotalScore2,
        score: tempScore,
        name: instituteData.first_name + " " + instituteData.last_name,
        instituteId: userData.id,
        status: "Done",
        // responseId: responseId,
      })
        .then((response) => {
          setIsLoading(false);
          navigate("/new-dashboard/school-compliance/report/" + response.data.result.id);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      apiAuth.put("admin/compliance_answer/" + reportId, {
        complianceId,
        answer: answerReport,
        total_answered: answerReport.length,
        total_score: tempTotalScore > tempTotalScore2 ? tempTotalScore : tempTotalScore2,
        score: tempScore,
        name: instituteData.first_name + " " + instituteData.last_name,
        instituteId: userData.id,
        status: "Done",
        // responseId: responseId,
      })
        .then((response) => {
          setIsLoading(false);
          navigate("/new-dashboard/school-compliance/report/" + response.data.result.id);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };
  const handleSaveProgress = () => {
    // setIsSubmit(true);
    setAnswerLoading(true);

    let tempScore = 0;
    let tempTotalScore = 0;
    let tempTotalScore2 = 0;
    answerReport.forEach((ans) => {
      tempScore += Number(ans.score);
    });
    questions.forEach((q) => {
      let tempMax = 0;
      q.options.forEach((i) => {
        if (tempMax < i.score) {
          tempMax = Number(i.score);
        }
      });
      tempTotalScore += Number(tempMax);
    });
    answerReport.forEach((i) => {
      let tempMax = 0;
      if (tempMax < i.max_score) {
        tempMax = Number(i.max_score);
      }
      tempTotalScore2 += Number(tempMax);
    });

    // setTotalScore(tempTotalScore >= tempTotalScore2 ? tempTotalScore : tempTotalScore2);
    // setTotalAnswered(answerReport.length);
    if (!reportId || editUrl) {

      apiJson.post("admin/compliance_answer", {
        complianceId,
        answer: answerReport,
        total_answered: answerReport.length,
        total_score: tempTotalScore > tempTotalScore2 ? tempTotalScore : tempTotalScore2,
        score: tempScore,
        instituteId: userData.id,
        status: "Pending",
        // responseId: responseId,
      })
        .then((response) => {
          setEditUrl(false);
          setAnswerLoading(false);
          // setisFormSaved(true);
          navigate("/new-dashboard/school-compliance/" + complianceId + "/continue/" + response.data.result.id);
          // toast.success("Successfully Save");
          // navigate("/dashboard");
        })
        .catch((err) => {
          setAnswerLoading(false);
          // toast.error('internal server error')
          console.log(err);
        });
    } else {
      apiJson.put("admin/compliance_answer/" + reportId, {
        complianceId,
        answer: answerReport,
        total_answered: answerReport.length,
        total_score: tempTotalScore > tempTotalScore2 ? tempTotalScore : tempTotalScore2,
        score: tempScore,
        instituteId: userData.id,
        status: "Pending",
        // responseId: responseId,
      })
        .then((response) => {
          setAnswerLoading(false);
          // setisFormSaved(true);
          // navigate("/dashboard/school-compliance/report/" + response.data.result.id);
          // toast.success("Successfully Save");
          // navigate("/dashboard");
        })
        .catch((err) => {
          setAnswerLoading(false);
          console.log(err);
        });
    }
  };

  const isRequired = (questionNo, id) => {
    let required = false;

    required = id.includes(questionNo);
    return required;
  };

  useEffect(() => {
    missingQuestionId();
  }, [requiredQuestions]);

  useEffect(() => {
    prefillAttemptQuestion();
  }, [questions]);

  useEffect(() => {
    if (answerReport.length > 0) {
      handleSaveProgress();
    }
  }, [answerReport]);

  useEffect(() => {
    calculateProgress();
  }, attemptQuestion);

  useEffect(() => {
    if (questions.length > 0 && !reportId) {
      setRequiredQuestions(
        questions.filter((q) => {
          return q.required === true;
        })
      );
    } else {
      let requiredQ = questions.filter((q) => {
        return q.required === true;
      });

      if (questions.length > 0 && reportId && requiredQ.length > 0) {
        // requiredQ = continueAnswer.filter((anwer))
        requiredQ = requiredQ.filter((q) => {
          return continueAnswer.some((answer) => answer.questionId !== q.id);
        });
      }
      setRequiredQuestions(requiredQ);
    }
  }, [questions, reportId]);


  const getAllCategoriesByComplianceId = () => {
    apiJson.get("https://www.api.safeinschool.in/admin/compliance_category/compliance/3").then((response) => {
    // apiJson.get("admin/compliance_category/compliance/" + complianceId).then((response) => {
      // setCategory(response.data.result);

      console.log(complianceCatagloryArray, "complianceCatagloryArray value complianceForm")
      setCategory(complianceCatagloryArray);
    });
  };


  const updateScoreSubQuestion = (questionId, complianceId, subQuestionId, score, maxScore, comment, image, showSubQuestion) => {
    let updatedAnswerReport = [...answerReport];

    const questionIndex = updatedAnswerReport.findIndex((question) => question.subQuestionId === subQuestionId);
    // console.log(questionIndex,"questionIndex");
    if (!score) {
      score = 0;
    }
    if (!maxScore) {
      maxScore = 1;
    }
    if (questionIndex !== -1) {
      updatedAnswerReport[questionIndex].score = score;
      updatedAnswerReport[questionIndex].comment = comment;
      updatedAnswerReport[questionIndex].image = image;
    } else {
      updatedAnswerReport.push({
        subQuestionId: subQuestionId,
        questionId: questionId,
        complianceId: complianceId,
        score: score,
        max_score: maxScore,
        comment: comment,
        image: image,
      });
    }
    // setshowSubQuestion(showSubQuestion);
    setAnswerReport(updatedAnswerReport);
  };


  const updateScore = (categoryId, questionId, score, maxScore, comment, image, index, subQuestion, text, indexOfOption) => {
    setAnswerText(text)
    setQuestionId(questionId)
    // making require array false if question have some changes
    let tempRequireArray = [...requiredArray];

    tempRequireArray[index] = false;

    setRequireArray(tempRequireArray);
    // console.log("tempRequireArray,requiredArray, "index",index)
    let updatedAnswerReport = [...answerReport];
    const questionIndex = updatedAnswerReport.findIndex((question) => question.questionId === questionId);
    if (!score) {
      score = 0;
    }
    if (!maxScore) {
      maxScore = 1;
    }
    if (questionIndex !== -1) {
      // updatedAnswerReport[questionIndex].score = score;
      updatedAnswerReport[questionIndex].comment = comment;
      updatedAnswerReport[questionIndex].image = image;
      updatedAnswerReport[questionIndex].text = text;
    } else {
      updatedAnswerReport.push({
        categoryId: categoryId,
        complianceId: complianceId,
        questionId: questionId,
        score: score,
        max_score: maxScore,
        comment: comment,
        image: image,
        text: text,
      });
    }

    if (index >= 0 && subQuestion) {
      const indexArray = [...showArray];
      indexArray[index] = true;
      setshowArray(indexArray);
    } else if (subQuestion === false) {
      const indexArray = [...showArray];
      indexArray[index] = false;
      setshowArray(indexArray);
    }
    if (text && indexOfOption >= 0 && subQuestion) {
      const temp = [...textArray];
      temp[index] = { text, ind: indexOfOption };
      setTextArray(temp);
    } else {
      const temp = [...textArray];
      temp[index] = { text: "", ind: -1 };
      setTextArray(temp);
    }
    let attemptss = [...attemptQuestion];
    attemptss[index] = true;
    setAttemptQuestion(attemptss);
    // console.log(showArray);
    calculateProgress();
    setAnswerReport(updatedAnswerReport);
  };
  const updateScoreNumber = (categoryId, questionId, score, maxScore, comment, image, index, signature, text) => {
    // console.log("updateScoreNumber", questionId, score, maxScore, comment, image)
    let tempRequireArray = [...requiredArray];
    tempRequireArray[index] = false;

    setRequireArray(tempRequireArray);
    let updatedAnswerReport = [...answerReport];
    const questionIndex = updatedAnswerReport.findIndex((question) => question.questionId === questionId);

    if (!score) {
      score = 0;
    }
    if (!maxScore) {
      maxScore = 1;
    }
    if (questionIndex !== -1) {
      updatedAnswerReport[questionIndex].score = score;
      updatedAnswerReport[questionIndex].comment = comment;
      updatedAnswerReport[questionIndex].signature = signature;
      updatedAnswerReport[questionIndex].text = text;
    } else {
      updatedAnswerReport.push({
        categoryId: categoryId,
        complianceId: complianceId,
        questionId: questionId,
        score: score,
        max_score: maxScore,
        comment: comment,
        image: image,
        signature: signature,
        text: text
      });
    }
    let attemptss = [...attemptQuestion];
    attemptss[index] = true;
    setAttemptQuestion(attemptss);
    calculateProgress();
    setAnswerReport(updatedAnswerReport);
  };


  const getCompliance = () => {
    apiJson.get("https://www.api.safeinschool.in/admin/compliance/3")
    // apiJson.get("admin/compliance/" + complianceId)
      .then((response) => {
        // setCompliance(response.data.result);
        console.log(complianceArray, "complianceArray value complianceForm")
        setCompliance(complianceArray);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const getQuestions = () => {
    apiJson.get("https://www.api.safeinschool.in/admin/compliance_question/compliance/3")
    // apiJson.get("admin/compliance_question/compliance/" + complianceId)
      .then((response) => {
        // setQuestions(response.data.result);
        // setshowArray(Array(response.data.result.length).fill(false));
        // setAttemptQuestion(Array(response.data.result.length).fill(false));
        // setTextArray(Array(response.data.result.length).fill({}));
        // setRequireArray(Array(response.data.result.length).fill(false));

        setQuestions(complianceQuestionArray);
        setshowArray(Array(complianceQuestionArray.length).fill(false));
        setAttemptQuestion(Array(complianceQuestionArray.length).fill(false));
        setTextArray(Array(complianceQuestionArray.length).fill({}));
        setRequireArray(Array(complianceQuestionArray.length).fill(false));
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    getCompliance();
    getQuestions();
    getAllCategoriesByComplianceId(); 
  }, []);

  useEffect(() => {
    getQuestions();
  }, [complianceId]);


  const handleNextPage = () => {
    debugger
    setIsSubmit(true);

    const missingQuestion = missingQuestionId();
    // console.log("this is missing question",missingQuestion);
    const categoryQuestion = questions.filter((q) => q.categoryId === category[currentPage - 1]?.id);
    const thisPageId = categoryQuestion.filter((q) => {
      // id required
      if (q.required) {
        return q.id;
      }
    });
    const tempMissing = missingQuestion.filter((i) => thisPageId.some((q) => q.id === i.id));
    // console.log("this is tempMissing", tempMissing, thisPageId, categoryQuestion, missingQuestion);
    if (tempMissing.length > 0) {
      toast.dismiss();
      const allMissingQuestionNos = tempMissing.map((item) => item.no).join(", ");

      const missingQuestionMessage = "Question no. " + allMissingQuestionNos + " is Required";

      toast.error(missingQuestionMessage);
      return;
    }
    // console.log(questionSoFar)
    setCurrentPage(currentPage + 1);
    let questionLastPage = questions.filter((q) => q.categoryId === category[currentPage + 1 - 2]?.id).length;
    setLastprev(prev);
    setprev(questionLastPage + prev);

    setIsSubmit(false);

  };

  const questionSoFar = () => {
    let totalQuestion = 0
    console.log('=>> current page ', currentPage - 2)
    for (let i = 0; i <= currentPage - 2; i++) {
      let question = questions.filter((q) => q.categoryId === category[i - 1]?.id).length;
      totalQuestion += question
      console.log('total question', totalQuestion, '>>>>>>', i)
    }
    return totalQuestion;
  }
  const handlePrevPage = () => {
    if (currentPage === 1) {
      toast.dismiss();
      toast.error("There is no Previous page");
      return;
    }
    let questionLastPage = questions.filter((q) => q.categoryId === category[currentPage - 2]?.id).length;

    // console.log("question last page", questionLastPage,'current page', currentPage-1) //currentpage 2 // questionlastpage -4 // 
    // console.log('questionLastPage-prev',questionLastPage,prev, questionLastPage-prev)
    // setprev(lastPrev);
    console.log("ajflkasfjlasdkfjdk", questionSoFar())
    setprev(questionSoFar())
    setCurrentPage(currentPage - 1);
  };
  useEffect(() => {
    if (page) {
      setCurrentPage(Number(page));
    }
  }, [page]);

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === category.length;
  // console.log(answerReport);

  return (
    <>
      {/* Header  */}
      <div className="py-3 bg-light border-bottom border-top">
        <div className="container">
          <div className="d-flex justify-content-between align-items-end">
            {/* Name */}
            <div>
              <span>
                Compliance
              </span>
              <h4 className="text-darkprime fw-bold">{compliance.title}</h4>
            </div>
            {/* Next,Previous Buttons  */}
            <div className="d-flex justify-content-between">
              {/* Save Progress Button */}
              {/* {(!isFormSaved || reportId) && (
                <button onClick={handleSaveProgress} className="btn btn-outline-primary btn-lg rounded-0 px-3 me-1">
                  Save Progress
                </button>
              )} */}
              {
                <div>
                  {!isFirstPage && <button onClick={handlePrevPage} className="btn btn-outline-primary rounded-0 px-3 me-1">
                    <i className="bi bi-arrow-left"></i> Prev
                  </button>}
                  {!isLastPage && <button onClick={handleNextPage} className="btn btn-outline-primary  rounded-0 px-3 me-1">
                    Next <i className="bi bi-arrow-right"></i>
                  </button>}
                  {isLastPage && <button onClick={handleSubmit} className="btn btn-outline-primary  rounded-0 px-3">
                    Submit
                  </button>}
                </div>
              }

              {isLastPage ? (
                <div>
                  <div className="footer mt-4"></div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* Content  */}
      <div className="container py-2">
        <div className="row g-1">
          <div className="col-12 col-lg-9">
            <div className="overflow-scroller">
              <div className="complianceForm">
                <div className="">
                  <h5 className="text-capitalize bg-darkprime p-3 fw-semibold text-white mb-2">{category[currentPage - 1]?.title}</h5>
                  {questions
                    // .filter((q) => q.categoryId === category[currentPage - 1]?.id && !q.sub_question)
                    .filter((q) => q.categoryId === q.categoryId && !q.sub_question)
                    .map((q, index) => {
                      let questionNO = index + prev;
                      return (
                        <div key={q.id} id={"question-" + (questionNO + 1)}>
                          {console.log(q.question_type, "questions value  713")}

                          {
                            q.question_type === "Demo" && <><RadioAndFillInBlank data={q} /></>
                          }
                          {
                            q.question_type === "Demo2" && <><CheckAndFillInBlank data={q} /></>
                          }
                          {
                            q.question_type === "Demo3" && <><Question4 data={q} /></>
                          }
                          {
                            q.question_type === "Demo4" && <><Question4ii data={q} /></>
                          }
                          {
                            q.question_type === "Demo5" && <><Question5 data={q} /></>
                          }
                          {
                            q.question_type === "Demo6" && <><Question6 data={q} /></>
                          }
                          {
                            q.question_type === "Demo7" && <><Question7 data={q} /></>
                          }
                          {
                            q.question_type === "Demo8" && <><Question8 data={q} /></>
                          }
                          {
                            q.question_type === "Demo9" && <><Question9 data={q} /></>
                          }
                          {
                            q.question_type === "Bio10" && <><BioDivercity10 data={q} /></>
                          }
                          {
                            q.question_type === "Bio11" && <><BioDivercity11 data={q} /></>
                          }
                          {
                            q.question_type === "Bio12" && <><BioDivercity12 data={q} /></>
                          }
                          {
                            q.question_type === "Bio13" && <><BioDivercity13 data={q} /></>
                          }
                          {
                            q.question_type === "Bio14" && <><BioDivercity14 data={q} /></>
                          }
                          {
                            q.question_type === "Bio15" && <><BioDivercity15 data={q} /></>
                          }
                          {
                            q.question_type === "Bio16" && <><BioDivercity16 data={q} /></>
                          }
                          {
                            q.question_type === "Water1" && <><WaterAudit1 data={q} /></>
                          }
                          {
                            q.question_type === "Water2" && <><WaterAudit2 data={q} /></>
                          }
                          {
                            q.question_type === "Water3" && <><WaterAudit3 data={q} /></>
                          }
                          {
                            q.question_type === "Water4" && <><WaterAudit4 data={q} /></>
                          }
                          {
                            q.question_type === "Water5" && <><WaterAudit5 data={q} /></>
                          }
                          {
                            q.question_type === "Water6" && <><WaterAudit6 data={q} /></>
                          }
                          {
                            q.question_type === "Water7" && <><WaterAudit7 data={q} /></>
                          }
                          {
                            q.question_type === "Water8" && <><WaterAudit8 data={q} /></>
                          }
                          {
                            q.question_type === "Water9" && <><WaterAudit9 data={q} /></>
                          }
                          {
                            q.question_type === "Water10" && <><WaterAudit10 data={q} /></>
                          }
                          {
                            q.question_type === "Water11" && <><WaterAudit11 data={q} /></>
                          }
                          {
                            q.question_type === "Water12" && <><WaterAudit12 data={q} /></>
                          }
                          {
                            q.question_type === "Water13" && <><WaterAudit13 data={q} /></>
                          }
                          {
                            q.question_type === "Water14" && <><WaterAudit14 data={q} /></>
                          }
                          {
                            q.question_type === "Water15" && <><WaterAudit15 data={q} /></>
                          }
                          {
                            q.question_type === "Water16" && <><WaterAudit16 data={q} /></>
                          }
                          {
                            q.question_type === "Water17" && <><WaterAudit17 data={q} /></>
                          }
                          {
                            q.question_type === "Water18" && <><WaterAudit18 data={q} /></>
                          }
                          {
                            q.question_type === "Water19" && <><WaterAudit19 data={q} /></>
                          }
                          {
                            q.question_type === "Water20" && <><WaterAudit20 data={q} /></>
                          }
                          {
                            q.question_type === "Water21" && <><WaterAudit21 data={q} /></>
                          }
                          {
                            q.question_type === "Water22" && <><WaterAudit22 data={q} /></>
                          }
                          {
                            q.question_type === "Waste1" && <><Waste1 data={q} /></>
                          }
                          {
                            q.question_type === "Waste2" && <><Waste2 data={q} /></>
                          }
                          {
                            q.question_type === "Waste3" && <><Waste3 data={q} /></>
                          }
                          {
                            q.question_type === "Waste5" && <><Waste5 data={q} /></>
                          }
                          {
                            q.question_type === "Waste6" && <><Waste6 data={q} /></>
                          }
                          {
                            q.question_type === "Waste7" && <><Waste7 data={q} /></>
                          }
                          {
                            q.question_type === "Waste8" && <><Waste8 data={q} /></>
                          }
                          {
                            q.question_type === "Waste10" && <><Waste10 data={q} /></>
                          }
                          {
                            q.question_type === "Waste11" && <><Waste11 data={q} /></>
                          }
                          {
                            q.question_type === "Waste12" && <><Waste12 data={q} /></>
                          }
                          {
                            q.question_type === "Waste13" && <><Waste13 data={q} /></>
                          }
                          {
                            q.question_type === "Waste14" && <><Waste14 data={q} /></>
                          }
                          {
                            q.question_type === "Transport1" && <><Transport1 data={q} /></>
                          }
                          {
                            q.question_type === "Transport2" && <><Transport2 data={q} /></>
                          }
                          {
                            q.question_type === "Transport3" && <><Transport3 data={q} /></>
                          }
                          {
                            q.question_type === "Transport5" && <><Transport5 data={q} /></>
                          }
                          {
                            q.question_type === "Transport8" && <><Transport8 data={q} /></>
                          }
                          {
                            q.question_type === "Transport9" && <><Transport9 data={q} /></>
                          }













                          {/* {["YesNo", "YesNONa", "mcq", "CheckBox","Dropdown"].includes(q.question_type)
                            &&
                            <Comp_MCQ loading={answerLoading} answerReport={answerReport} reportId={reportId} key={q.id} index={questionNO} question={q} changeAnswer={updateScore} totalQuestion={questions.length} required={requiredArray[questionNO]} submit={isSubmit} />} */}

                          {/* {
                            ["Number", "Long", "Short", "Signature"].includes(q.question_type)
                            &&
                            <Comp_Num loading={answerLoading} answerReport={answerReport} key={q.id} index={questionNO} reportId={reportId} question={q} changeAnswer={updateScoreNumber} totalQuestion={questions.length} required={requiredArray[questionNO]} submit={isSubmit} />} */}

                          {/* {
                            questions.filter((subQ) => answerReport.some((item)=>item?.questionId ===q?.id && item?.text === subQ?.option_text && item?.categoryId === subQ?.categoryId)).map((i, subIndex) => {
                              let count = 0;

                              return (
                                <div>
                                  <SubQuestion4option key={i.sub_question.subQuestion4optionId} questions={questions} index={questionNO} q={q} answerReport={answerReport} indexA={count++} question={i} reportId={reportId} changeAnswer={["YesNo", "YesNONa", "mcq", "CheckBox"].includes(i.question_type) ? updateScore : updateScoreNumber} totalQuestion={questions.length} />
                                </div>
                              )
                            })}  */}
                             
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <div className="overflow-scroller">
              <div className="card p-3 border rounded-0 w-100 border-purple-2">
                <div className="body">
                  <div className="progress-show p-2">
                    <ComplianceProgress value={progress} />
                  </div>
                  <div className="mt-3">
                    <p className="text-capitalize text-dark lh-sm fw-semibold">{category[currentPage - 1]?.title}</p>
                    <small className="fw-bold">Questions</small>
                    <div className="row row-cols-6 g-1 ">
                      {questions
                        .filter((q) => q.categoryId === category[currentPage - 1]?.id)
                        .map((ques, index) => {
                          let questionNO = index + prev;
                          return (
                            <div className="col" key={index}>
                              <ButtonNavQuestion ques={ques} index={questionNO} answerReport={answerReport} />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
          className="loader-overlay">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </>
  );
};

export default ComplianceForm;

const ButtonNavQuestion = ({ ques, index, answerReport }) => {
  const filter = answerReport.filter((data) => data.questionId == ques.id)[0];
  return (
    <a className={`btn border rounded-0 m-0 w-100 ${filter ? (filter?.max_score > filter?.score ? "btn-danger text-white" : "btn-success text-white") : ""}`} href={"#question-" + (index + 1)}>
      <small className="text-nowrap" style={{ textWrap: "nowrap !important" }}>
        {index + 1}
      </small>
    </a>
  );
};
