export const complianceAnswerArray = [
  {
    id: 1026,
    instituteId: 4984,
    complianceId: 3,
    name: "ARNAB BANERJEE",
    total_answered: 0,
    total_score: 99,
    status: "Done",
    score: 0,
    answer: [],
    comment: null,
    image: null,
    file: null,
    createdAt: "2024-09-16T11:10:46.000Z",
    updatedAt: "2024-09-16T11:10:46.000Z",
  },
];
