import React, { useState } from "react";

const WaterSourceQuestion = () => {
  const questionData = {
    id: 33,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    text: "Does your school use the same source of water for drinking and sanitation?",
    options: [
      { text: "Yes", score: "1", correct: true },
      { text: "No", score: "0", correct: false },
    ],
  };

  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  // CSS Styles
  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      fontSize: "14px",
      lineHeight: "1.6",
      margin: "20px 40px",
      color: "#333",
    },
    questionTitle: {
      fontSize: "16px",
      marginBottom: "16px",
      fontWeight: "bold",
    },
    inputGroup: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      marginTop: "10px",
      marginLeft: "60px",
    },
    label: {
      fontSize: "14px",
    },
    input: {
      marginRight: "8px",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.questionTitle}>Q3. {questionData.text}</h1>
      <div style={styles.inputGroup}>
        {questionData.options.map((option, index) => (
          <label key={index} style={styles.label}>
            <input
              type="radio"
              name="waterSource"
              value={option.text}
              checked={selectedOption === option.text}
              onChange={() => handleOptionChange(option.text)}
              style={styles.input}
            />
            {option.text}
          </label>
        ))}
      </div>
    </div>
  );
};

export default WaterSourceQuestion;
