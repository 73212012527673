/* eslint-disable no-sparse-arrays */
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import "./ClubPage.css";
import EatRightIndiaChallenge from "../../component/EatRightIndia/EatRightIndiaChallenge";

const TwentyOneClub = () => {
  return (
    <section>
      <EatRightIndiaChallenge padding={"3rem"} />
    </section>
  );
};

export default TwentyOneClub;
