import { Box, TextField, FormControl, Grid, InputLabel, MenuItem, Select, Typography, Button } from "@mui/material";
import ModeIcon from '@mui/icons-material/Edit';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import React, { useState } from "react";


const bloodGroups = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'];
const years = Array.from({ length: 50 }, (_, i) => 2024 - i);
const heightsFeet = Array.from({ length: 8 }, (_, i) => i + 1);
const heightsInch = Array.from({ length: 12 }, (_, i) => i);
const weights = Array.from({ length: 150 }, (_, i) => i + 1);

const HealthSecondPage = ({onClick}) => {

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
        padding: "1rem",
        overflow: "auto",
        "@media (max-width: 900px)": {
          padding: "0.5rem",
        },
      }}
    >
    <Box
        sx={{
          fontSize: "1.25rem",
          padding: "2rem",
          "@media (max-width: 900px)": {
            padding: "1rem",
          },
        }}
    >
        <Box
          sx={{
            borderRadius: "1rem",
            backgroundColor: "#f0f0f0",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "2rem",
            boxSizing: "border-box",
            "@media (max-width: 900px)": {
              width: "100%",
              padding: "1rem",
            },
          }}
        >
          <div className="lets-begin-your-first-day-cha-parent">
            <Typography
              component="b"
              sx={{
                fontSize: "1.25rem",
                fontWeight: 700,
                // fontFamily: "cursive",
              }}
              className="preview"
            >
              CBSE circular no.Acad-I012018
            </Typography>
            <img className="tick-icon" alt="" src="/tick.svg" />
          </div>
          <div className="click-here-to-container">
            <Typography
              component="span"
              sx={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              className="click-here"
            >
              Click here
            </Typography>
            <Typography component="span" className="to-know-more">
              {" "}
              to know more.
            </Typography>
          </div>
        </Box>

      <form action="">
        <Box
          sx={{
            marginTop: "2rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "2rem 2rem 1rem 1.5rem",
            borderRadius: "1rem",
            backgroundColor: "#FFFFFF",
            border: "5px solid #2356fe",
            boxSizing: "border-box",
            width: "100%",
            overflow: "hidden",
            "@media (max-width: 900px)": {
              padding: "1rem",
            },
          }}
        >
            <Box
                sx={{marginLeft: "2rem"}}
            >
                <Typography
                    sx={{
                        fontSize: "1.875rem",
                        fontWeight: 600,
                        color: "blue",
                        // fontFamily: "cursive",
                    }}
                        className="hello_name"
                >  
                    General Information
                </Typography>
            </Box>

            <Box
                sx={{
                    padding: "2rem",
                }}
            >
                <Typography
                    sx={{
                        fontSize: "1.875rem",
                        fontWeight: 600,
                        // fontFamily: "cursive",
                    }}
                    className="mo_of"
                >  
                    Student's Info
                </Typography>
                <Box sx={{padding:"2rem 0rem 2rem 0rem "}}>
                    <Grid container spacing={2} justifyContent="flex-start">
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Box
                          sx={{
                            borderRadius: "0.5rem",
                            background: "#c8d3f454",
                            wordBreak: "break-word",
                            padding: "0.8rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: 1.5,
                          }}
                        >
                          <Typography
                            component={"h1"}
                            sx={{ color: "#2356fe", fontWeight: 600 }}
                          >
                            Admission no :
                          </Typography>
                          <Typography component={"p"}>
                            8987654546465465
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Box
                          sx={{
                            padding: "0.8rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: 1.5,
                            borderRadius: "0.5rem",
                            background: "#c8d3f454",
                            wordBreak: "break-word",
                          }}
                        >
                          <Typography
                            component={"h1"}
                            sx={{ color: "#2356fe", fontWeight: 600 }}
                          >
                            Name :
                          </Typography>
                          <Typography component={"p"}>Aakash Thakur</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Box
                          sx={{
                            padding: "0.8rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: 1.5,
                            borderRadius: "0.5rem",
                            background: "#c8d3f454",
                            wordBreak: "break-word",
                          }}
                        >
                          <Typography
                            component={"h1"}
                            sx={{ color: "#2356fe", fontWeight: 600 }}
                          >
                            Date of Birth :
                          </Typography>
                          <Typography component={"p"}>01 May,2024</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Box
                          sx={{
                            padding: "0.8rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: 1.5,
                            borderRadius: "0.5rem",
                            background: "#c8d3f454",
                            wordBreak: "break-word",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              component={"h1"}
                              sx={{ color: "#2356fe", fontWeight: 600 }}
                            >
                              Phone no :
                            </Typography>

                            <ModeIcon sx={{ fontSize: "1.2rem" }} />
                          </Box>
                          <Typography component={"p"}>
                            +91 9876543213
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Box
                          sx={{
                            padding: "0.8rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: 1.5,
                            borderRadius: "0.5rem",
                            background: "#c8d3f454",
                            wordBreak: "break-word",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              component={"h1"}
                              sx={{ color: "#2356fe", fontWeight: 600 }}
                            >
                              Height :
                            </Typography>
                            <ModeIcon sx={{ fontSize: "1.2rem" }} />
                          </Box>

                          <Typography component={"p"}>165 CM</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Box
                          sx={{
                            padding: "0.8rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: 1.5,
                            borderRadius: "0.5rem",
                            background: "#c8d3f454",
                            wordBreak: "break-word",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              component={"h1"}
                              sx={{ color: "#2356fe", fontWeight: 600 }}
                            >
                              Weight :
                            </Typography>
                            <ModeIcon sx={{ fontSize: "1.2rem" }} />
                          </Box>

                          <Typography component={"p"}>70 KG</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Box
                          sx={{
                            padding: "0.8rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: 1.5,
                            borderRadius: "0.5rem",
                            background: "#c8d3f454",
                            wordBreak: "break-word",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              component={"h1"}
                              sx={{ color: "#2356fe", fontWeight: 600 }}
                            >
                              Aadhar Card no :
                            </Typography>
                            <ModeIcon sx={{ fontSize: "1.2rem" }} />
                          </Box>

                          <Typography component={"p"}>878786554352</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={1.2}>
                        <Box
                          sx={{
                            padding: "0.8rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: 1.5,
                            borderRadius: "0.5rem",
                            background: "#c8d3f454",
                            wordBreak: "break-word",
                          }}
                        >
                            <Typography
                              component={"h1"}
                              sx={{ color: "#2356fe", fontWeight: 600 }}
                            >
                              Gender :
                            </Typography>

                          <Typography component={"p"}>Male</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={1.5}>
                        <Box
                          sx={{
                            padding: "0.8rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            borderRadius: "0.5rem",
                            background: "#c8d3f454",
                            wordBreak: "break-word",
                          }}
                        >
                            <Typography
                              component={"h1"}
                              sx={{ color: "#2356fe", fontWeight: 600 }}
                            >
                              Blood Group :
                            </Typography>

                          <Typography component={"p"}>O <Typography component={'sup'} sx={{fontWeight:600}}>-</Typography></Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3.8}>
                        <Box
                          sx={{
                            padding: "0.8rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: 1.5,
                            borderRadius: "0.5rem",
                            background: "#c8d3f454",
                            wordBreak: "break-word",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              component={"h1"}
                              sx={{ color: "#2356fe", fontWeight: 600 }}
                            >
                              Address :
                            </Typography>
                            <ModeIcon sx={{ fontSize: "1.2rem" }} />
                          </Box>

                          <Typography component={"p"}>Z-600, Great Alpa Nagar, South Delhi</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2.5}>
                        <Box
                          sx={{
                            padding: "0.8rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: 1.5,
                            borderRadius: "0.5rem",
                            background: "#c8d3f454",
                            wordBreak: "break-word",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              component={"h1"}
                              sx={{ color: "#2356fe", fontWeight: 600 }}
                            >
                              CSWN :
                            </Typography>
                            <ModeIcon sx={{ fontSize: "1.2rem" }} />
                          </Box>

                          <Typography component={"p"}>Hearing Implementation (HI) </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Box
                          sx={{
                            padding: "0.8rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: 1.5,
                            borderRadius: "0.5rem",
                            background: "#c8d3f454",
                            wordBreak: "break-word",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              component={"h1"}
                              sx={{ color: "#2356fe", fontWeight: 600 }}
                            >
                              Family Income :
                            </Typography>
                            <ModeIcon sx={{ fontSize: "1.2rem" }} />
                          </Box>

                          <Typography component={"p"}>5 - 7 LPA</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
            </Box>
            <Box
                sx={{
                    padding: "0rem 2rem 2rem 2rem",
                }}
            >
                <Typography
                    sx={{
                        fontSize: "1.875rem",
                        fontWeight: 600,
                        // fontFamily: "cursive",
                        paddingBottom: "2rem",
                    }}
                    className="mot_of"
                >  
                    Mother's Info
                </Typography>
                <Grid container spacing={2} justifyContent="flex-start">
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Box
                          sx={{
                            padding: "0.8rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: 1.5,
                            borderRadius: "0.5rem",
                            background: "#c8d3f454",
                            wordBreak: "break-word",
                          }}
                        >
                          {/* <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <ModeIcon sx={{ fontSize: "1.2rem" }} />
                          </Box> */}
                            <Typography
                              component={"h1"}
                              sx={{ color: "#2356fe", fontWeight: 600,}}
                            >
                              Mother's Name :
                            </Typography>
                          <Typography component={"p"}>Minakshi Pandey</Typography>
                        </Box>
                      </Grid>
                </Grid>
              <Box sx={{paddingTop: "2rem", }}>
                <Grid container spacing={2}>
                {/* Aadhar Card Number */}
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Aadhar Card No. </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem',}}>
                      <TextField variant="outlined" placeholder="Enter Number here.." fullWidth />
                      <HelpOutlineIcon />
                    </Box>
                    {/* <Typography variant="body2">Example: 3188 0066 5508</Typography> */}
                  </Grid>

                  {/* Year of Birth */}
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Year of Birth </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                      <FormControl fullWidth>
                        <InputLabel>Year</InputLabel>
                        <Select>
                          {years.map(year => (
                            <MenuItem key={year} value={year}>{year}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <HelpOutlineIcon />
                    </Box>
                  </Grid>

                  {/* Blood Group */}
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Blood Group <span style={{ color: 'red' }}>*</span></Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
                      {bloodGroups.map(group => (
                        <Button sx={{padding: "0.5rem 0rem", border: "1px solid #1976d2"}} key={group}>{group}</Button>
                      ))}
                    </Box>
                  </Grid>

                  {/* Height */}
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="h6">Height </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                      <FormControl fullWidth>
                        <InputLabel>Ft</InputLabel>
                        <Select>
                          {heightsFeet.map(ft => (
                            <MenuItem key={ft} value={ft}>{ft}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel>Inch</InputLabel>
                        <Select>
                          {heightsInch.map(inch => (
                            <MenuItem key={inch} value={inch}>{inch}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <HelpOutlineIcon />
                    </Box>
                  </Grid>

                  {/* Weight */}
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Typography variant="h6">Weight </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                      <FormControl fullWidth>
                        <InputLabel>Kg</InputLabel>
                        <Select>
                          {weights.map(weight => (
                            <MenuItem key={weight} value={weight}>{weight}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <HelpOutlineIcon />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box
                sx={{
                    padding: "0rem 2rem 2rem 2rem",
                }}
            >
                <Typography
                    sx={{
                        fontSize: "1.875rem",
                        fontWeight: 600,
                        // fontFamily: "cursive",
                        paddingBottom: "2rem",
                    }}
                    className="mot_of"
                >  
                    Father's Info
                </Typography>
                <Grid container spacing={2} justifyContent="flex-start">
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Box
                          sx={{
                            padding: "0.8rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: 1.5,
                            borderRadius: "0.5rem",
                            background: "#c8d3f454",
                            wordBreak: "break-word",
                          }}
                        >
                          {/* <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <ModeIcon sx={{ fontSize: "1.2rem" }} />
                          </Box> */}
                            <Typography
                              component={"h1"}
                              sx={{ color: "#2356fe", fontWeight: 600,}}
                            >
                              Father's Name :
                            </Typography>
                          <Typography component={"p"}>Rakesh Pandey</Typography>
                        </Box>
                      </Grid>
                </Grid>
              <Box sx={{paddingTop: "2rem", }}>
                <Grid container spacing={2}>
                {/* Aadhar Card Number */}
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Aadhar Card No. </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem',}}>
                      <TextField variant="outlined" placeholder="Enter Number here.." fullWidth />
                      <HelpOutlineIcon />
                    </Box>
                    {/* <Typography variant="body2">Example: 3188 0066 5508</Typography> */}
                  </Grid>

                  {/* Year of Birth */}
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Year of Birth </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                      <FormControl fullWidth>
                        <InputLabel>Year</InputLabel>
                        <Select>
                          {years.map(year => (
                            <MenuItem key={year} value={year}>{year}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <HelpOutlineIcon />
                    </Box>
                  </Grid>

                  {/* Blood Group */}
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Blood Group <span style={{ color: 'red' }}>*</span></Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
                      {bloodGroups.map(group => (
                        <Button sx={{padding: "0.5rem 0rem", border: "1px solid #1976d2"}} key={group}>{group}</Button>
                      ))}
                    </Box>
                  </Grid>

                  {/* Height */}
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="h6">Height </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                      <FormControl fullWidth>
                        <InputLabel>Ft</InputLabel>
                        <Select>
                          {heightsFeet.map(ft => (
                            <MenuItem key={ft} value={ft}>{ft}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel>Inch</InputLabel>
                        <Select>
                          {heightsInch.map(inch => (
                            <MenuItem key={inch} value={inch}>{inch}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <HelpOutlineIcon />
                    </Box>
                  </Grid>

                  {/* Weight */}
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Typography variant="h6">Weight </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                      <FormControl fullWidth>
                        <InputLabel>Kg</InputLabel>
                        <Select>
                          {weights.map(weight => (
                            <MenuItem key={weight} value={weight}>{weight}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <HelpOutlineIcon />
                    </Box>
                  </Grid>
                  {/* Next Button */}
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick = {() => onClick()} type="submit" variant="contained" color="primary" sx={{ marginTop: '2rem', padding: "1rem 3.5rem", }}>
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default HealthSecondPage;
