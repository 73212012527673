import React, { useState } from "react";

const CompostingQuestion = () => {
  const [compostingActivity, setCompostingActivity] = useState("");
  const [compostUsage, setCompostUsage] = useState("");

  const compostingOptions = [
    { text: "Yes", value: "Yes", score: 1 },
    { text: "No", value: "No", score: 0 },
  ];

  const compostUsageOptions = [
    { text: "Yes", value: "Yes", score: 1 },
    { text: "No", value: "No", score: 0 },
  ];

  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      fontSize: "14px",
      lineHeight: "1.6",
      margin: "20px 40px",
      color: "#333",
    },
    questionTitle: {
      fontSize: "16px",
      marginBottom: "16px",
      fontWeight: "bold",
    },
    section: {
      marginBottom: "20px",
      marginLeft: "60px",
    },
    inputGroup: {
      display: "flex",
      gap: "20px",
      marginTop: "10px",
    },
    input: {
      marginRight: "6px",
    },
    questionTitleB: {
      fontSize: "16px",
      marginBottom: "16px",
      fontWeight: "bold",
      marginLeft: "40px",
    },
  };

  const handleCompostingActivityChange = (e) => {
    setCompostingActivity(e.target.value);
  };

  const handleCompostUsageChange = (e) => {
    setCompostUsage(e.target.value);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.questionTitle}>
        Q10. a) Is composting an organized activity and encouraged for students and staff in your school?
      </h1>
      <div style={styles.section}>
        <div style={styles.inputGroup}>
          {compostingOptions.map((option) => (
            <label key={option.value}>
              <input
                type="radio"
                name="compostingActivity"
                value={option.value}
                checked={compostingActivity === option.value}
                onChange={handleCompostingActivityChange}
                style={styles.input}
              />
              {option.text}
            </label>
          ))}
        </div>
      </div>

      <h1 style={styles.questionTitleB}>
        b) Is the compost prepared used for gardening in the school?
      </h1>
      <div style={styles.section}>
        <div style={styles.inputGroup}>
          {compostUsageOptions.map((option) => (
            <label key={option.value}>
              <input
                type="radio"
                name="compostUsage"
                value={option.value}
                checked={compostUsage === option.value}
                onChange={handleCompostUsageChange}
                style={styles.input}
              />
              {option.text}
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CompostingQuestion;
